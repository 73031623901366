// instructions for neet application

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../util/ScrollToTop.jsx";

export class InstructionsFormN extends Component {
  constructor(props){
    super(props);

    this.state = {
      even:false,
      is_logged_in: this.props.auth.isAuthenticated,
    }
  }
  componentDidMount() {
    const documentList = [
      ["Common Documents", "1", "Birth Certificate", "Refer prospectus"],
      ["", "2", "Statement of marks / Certificate / Provisional Certificate of passing HSSC"],
      ["", "3", "School/college Leaving Certificate / Transfer Certificate"],
      ["", "4", "Aadhar Card"],
      ["", "5", "NEET Admit Card (if Registered)"],
      ["", "6", "GCET Admit Card (if Registered)"],
      [
        "",
        "7",
        "Photograph : 3.5 cm (width) x 4.5 cm (height) in JPEG/PNG format with file size < 200 kB",
      ],
      ["General Category", "", "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)"],
      [
        "SC Category",
        "1",
        "Caste Certificate from Deputy Collector, Govt. of Goa",
      ],
      [
        "",
        "2",
        "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)",
      ],
      [
        "ST Category",
        "1",
        "Caste Certificate from Deputy Collector, Govt. of Goa",
      ],
      [
        "",
        "2",
        "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)",
      ],
      [
        "OBC Category",
        "1",
        "Caste Certificate from Deputy Collector, Govt. of Goa",
      ],
      [
        "",
        "2",
        "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)",
      ],
      [
        "FF - Freedom Fighter",
        "1",
        "Freedom Fighter Certificate from Secretary (Home)",
      ],
      [
        "",
        "2",
        "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)",
      ],
      [
        "PwD - Person with Disability",
        "1",
        "Medical Certificate from Medical Board",
      ],
      [
        "",
        "2",
        "Residence Certificate from Mamlatdar / Bonafide Certificate/s (Multiple certificates need to be scanned as a single pdf document for uploading)",
      ],
      ["CSP - Children of Central/State Employees", "1", "Service Certificate of either parent"],
      ["", "2", "Residence Certificate of either parent from Mamlatdar"],
      ["ESM - Ex Service Men", "", "Certificate from Rajya Sainik Board"],
      ["LA - Local Applicant", "", "Only common documents as mentioned above"],
      ["GN - Goan Native", "1", "Birth Certificate of parents / grand parents"],
      ["", "2", "Caste certificate (OBC / ST)"],
      ["NRI", "1", "Applicant's and parents passport"],
      ["", "2", "NRI Certificate from Embassy / Consulate"],
      ["", "3", "OCI / PIO Card / document issued by the Competent Authority"],
      ["", "4", "For GO (Goan Origin) - Parents / Grandparents Birth Ceritiface"],
      ["OGA - Outside Goa Applicant", "", "Only common documents as mentioned above"],
      [
        "Additional Documents",
        "1",
        "Eligibility Certificate from Goa University (for applicants passing qualifying examination from Boards other than Goa Board)",
      ],
      ["", "2", "Income Certificate from Panchayat / Municipality (for fee concession)"],
      ["", "3", 'Income and Assets Certificate" issued by Mamlatdar (for EWS seats)'],
      ["", "4", 'Acknowledgment cum Admission Card in respect of applicants who have been allotted seat earlier in BE, B.Pharm. or B.Arch.'],
    ];
    let tbody = document.getElementById("table-body");
    let arrayLength = documentList.length;

    let pdfLinksArr = [
      ['General','26'],
      ['SC','26'],
      ['ST','27'],
      ['OBC','27'],
      ['FF','28'],
      ['PwD','27'],
      ['CSP','28'],
      ['ESM','28'],
      ['LA','29'],
      ['GN','29'],
      ['NRI','29'],
      ['OGA','30']   
    ]

    for (let i = 0, j=0; i < arrayLength; i++) {
      let tr = document.createElement("tr");
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      let td3 = document.createElement("td");
      let td4 = document.createElement("td");

      let even = false;
      let prospectus = false;

      if(documentList[i][1] === "1" || documentList[i][1] === ""){

        even = true;

        if(i > 6 && i < 29){
          prospectus = true;
        }
        
        // if(even){
        //   even = false
        // }
        // else{
        //   even = true
        // }
      }
      tr.setAttribute("class", `application__table__row ${even?'even':''}`);
      td1.setAttribute("class", "application__table__item");
      td2.setAttribute("class", "application__table__item");
      td3.setAttribute("class", "application__table__item");
      td4.setAttribute("class", `application__table__item`);
      

      td1.innerText = documentList[i][0];
      td2.innerText = documentList[i][1];
      td3.innerText = documentList[i][2];

      if(prospectus){
        td4.innerHTML = `<a target="_blank"  href="static/media/degprosp2023.pdf#page=${pdfLinksArr[j][1]}">Go to prospectus</a>`
        td4.classList.add('prospectus')
        j++;
      }

      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);

      tbody.appendChild(tr);

      if(documentList[i][0] !== ""){
        td1.classList.add('heading')
      }
    }
  }
  render() {
    return (
      <div className="application content-wrapper">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-12">
              <h1 className="application__title">Instructions</h1>

              <p style={{marginBottom: "20px", fontWeight: "500", }}>
                Applicants are advised to familiarize themselves with the
                “<span style={{fontWeight: "700"}}>Common Prospectus for Admission to the First Year of
                Professional Degree Courses 2023-2024</span>”.{" "}
                <Link to="static/media/degprosp2023.pdf" target="_blank">(click to read)</Link>
              </p>
              <ol className="application__list-container">
                <li className="application__list-item">
                  <span>
                    All applicants must submit application form, <b>Form A</b>, which is common for admission to both GCET-based Courses (Engineering and Pharmacy) and NEET-based courses (MBBS, BDS, BAMS, BHMS, BSc Nursing, BVSc. &amp; AH, and Allied Health Sciences)
                  </span>
                </li>
                <li className="application__list-item">
                  <span>
                    In order to be considered for admissions to NEET based courses, applicant <text style={{color: 'red'}}><b> must clearly state their NEET roll number.</b></text>
                  </span>
                </li>
                <li className="application__list-item">
                  <span>
                    Read rules of admission and eligibility criteria as given
                    in the prospectus before filling the form. Enter all the details in the form carefully. (Name should be
                    as per your SSCE certificate)
                  </span>
                </li>
                
                <li className="application__list-item">
                  <span>
                    Applicants interested in admission to <b>B.V.Sc. </b>
                    and <b>A.H.</b> courses need to <b> submit/upload 15
                    years</b> Residence Certificate and <text style={{color: 'red'}}>register online on MAFSU website </text> and submit Acknowledgement copy to Directorate of Animal Husbandry and Veterniary Services(AHVS), Govt. of Goa, Patto, Panaji.
                  </span>
                </li>
                <li className="application__list-item">
                  <span>
                    Applicants who have not registered for GCET 2023 
                    earlier need to pay an application fee of Rs. 1700/- online.
                  </span>
                </li>
                <li className="application__list-item">
                  <span style={{color: 'red'}}>
                  NB: <i><b>Last date</b> for submission of application form is <b> Friday, 2nd June, 2023</b></i>
                  </span>
                </li>
                <li className="application__list-item">
                  <span>
                   Applicants who have submitted online form and have obtained/downloaded Acknowledgement Card, need not submit offline/hard copy applications.
                  </span>
                </li>
                <li className="application__list-item">
                  <span>Self attested scanned copies of the following documents are required (<b>PDF</b> scanned using Microsoft Office Lens or any similar app):</span><br />
                  <ul>
                    <li>Take a photocopy (xerox) of the document</li>
                    <li>Sign/self - attest the above</li>
                    <li>Scan the self-attested document (pdf format)</li>
                    <li>Upload it in step 3 of the form</li>
                    <li style={{textAlign: 'justify'}}><b>Application forms submitted after specified last date shall not be accepted. Therefore, applicants are instructed to ensure that the application form is submitted by above specified date, <span style={{color: 'red', display:'inline', padding:  0}}>irrespective of availability of the required documents and NEET, CBSE results</span>.</b></li>
                  </ul>                      
                </li>
              </ol>

              <table className="application__table">
                <thead>
                  <tr className="application__table__row">
                    <th className="application__table__item heading">
                      Category
                    </th>
                    <th className="application__table__item heading">
                      Sr. No.
                    </th>
                    <th className="application__table__item heading">
                      Scanned Documents to be submitted
                    </th>
                    <th className="application__table__item heading">
                      Prospectus Links
                    </th>
                  </tr>
                </thead>

                <tbody id="table-body"></tbody>
              </table>

              <p style={{fontSize:'14px'}}>N.B. <i>The above list is indicative and is intended to serve as a guideline for applicants.</i></p> <br />
              <p style={{fontSize:'14px', color: 'red'}}>** <i>NEET results must be submitted within 3 days of their declaration, either at the Directorate of Technical Education, Porvorim, or online at <a href="mailto:dtegoaadm-deg@gov.in">dtegoaadm-deg@gov.in</a> clearly indicating the registration number in the subject line of the mail.</i></p>

              <p className="application__sub-heading" style={{display: `${this.state.is_logged_in? 'none': 'block'}`}}>
                Applicants may proceed with the registration process.<br />
                <Link to="/register" className="button">Register</Link> <span style={{padding:'0 20px'}}> or </span> <span style={{display:'inline-block'}}><Link to="/login" className="button">Login</Link> <span style={{fontSize: '14px', color: '#485474'}}>If already registered</span></span>
              </p>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
