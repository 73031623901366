import React, { Component } from "react";

// components
import { Input } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop.jsx";

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export class ResendVerificationEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backendError: "",
      loading_button: "",
      showErrorBox: "",
      email: "",
      isAuthenticated: "",
      successful_submission: "",

      formErrors: {
        email: "",
      },
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "email") {
      formErrors.email =
        emailRegex.test(value) && value.length > 0
          ? ""
          : "invalid email address";
    }
  };

  submit_email = (event) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    this.setState({
      loading_button: true,
    });

    var raw = JSON.stringify({ email: this.state.email });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/accounts/activation-link`,
      requestOptions
    )
      .then((response) => {
        this.setState({
          showErrorBox: true,
        });
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            successful_submission: true,
          });
        } else {
          this.setState({
            successful_submission: false,
          });
        }
        return response.text();
      })
      .then((result) => {
        console.log(result);
        this.setState({
          backendError: JSON.parse(result).message,
          loading_button: false,
        });
      })
      .catch((error) => {
        this.setState({
          backendError: `${error}. REFRESH THE PAGE. Check your internet connection`,
          loading_button: false,
          showErrorBox: true,
        });
        console.log("error", error);
      });
  };

  render() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell large-7">
            <div className="content-wrapper">
              <div className="forgot">
                <h1 className="forgot__title">Resend Verification Email</h1>
                <p className="forgot__text">
                  A verification link will be sent to you once you submit your
                  email. Make sure you enter the same email which was used to
                  register earlier. <br />
                  <br />
                  <b>
                    Note: It might take 5-10 minutes for the email to be sent.
                    Always open the latest email sent by DTE.
                  </b>
                </p>
                <form onSubmit={this.submit_email} className="register__form">
                  <Input
                    label="Email Address"
                    type="email"
                    name="email"
                    value={this.state.email}
                    id="email"
                    onChange={this.handleChange}
                    required
                    err={this.state.formErrors.email}
                  />
                  {(this.state.backendError ||
                    this.state.successful_submission) && (
                    <p
                      id="backend-error"
                      className={`${
                        this.state.successful_submission
                          ? "success show"
                          : "failure show"
                      }`}
                    >
                      {this.state.backendError}
                    </p>
                  )}

                  <button
                    type="submit"
                    className={`button ${
                      this.state.loading_button ? "loading" : ""
                    }`}
                  >
                    Resend Verification Link
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
