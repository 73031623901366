import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";
import swal from "@sweetalert/with-react";
import Cookies from "universal-cookie";

export class NProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      submitted_successfully: "",
      final_submission_done: false,
      last_step: "",
      pdf_fetched: false,
      step_one_finished: "",
      step_two_finished: "",
      step_three_finished: "",
      step_four_finished: "",

      full_name: "",
      photo_preview: false,
      date_of_birth: "",
      gender: "",
      email: "",
      postal_address: "",
      mobile: "",
      alternate_mobile_number: "",
      aadhar: "",
      photo: "",
      nationality: "",
      form_a_reg_no: '',

      hssc_board: "",
      hssc_board_year: "",
      hssc_percentage: "",

      hssc_total_marks: "",
      hssc_total_maximum_marks: "",
      hssc_english_marks: "",
      hssc_english_maximum_marks: "",
      hssc_physics_marks: "",
      hssc_physics_maximum_marks: "",
      hssc_maths_marks: "",
      hssc_maths_maximum_marks: "",
      hssc_chemistry_marks: "",
      hssc_chemistry_maximum_marks: "",
      hssc_biology_marks: "",
      hssc_biology_maximum_marks: "",
      hssc_others_marks: "",
      hssc_others_maximum_marks: "",

      general_category_selected: false,
      sc_category_selected: false,
      st_category_selected: false,
      obc_category_selected: false,
      freedom_fighter: false,
      person_with_disability: false,
      children_of_central_state_employees: false,
      ex_service_man: false,
      local_applicant: false,
      goan_native: false,
      goan_native_st: false,
      goan_native_obc: false,
      nri_goan_origin: false,
      nri_non_goan_origin: false,
      pio_goan_origin: false,
      pio_non_goan_origin: false,
      outside_goa_applicant: false,
      kashmiri_migrant: false,
      foreign_national: false,
      gulf: false,
      is_gcet_roll_no_verified: false,

      applying_for_bvsc_ah: false,
      choiceArr: [],
      tfw_preference: '',
      tfw_ews_consideration: '',

      categoryArr: [],
      showErrorBox: false,

      order_id_created: false,

      loading_button: "",
      backendError: "",

      message: "loading...",
    };
  }

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  async componentDidMount() {
    try {
      let myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/`,
        requestOptions
      );
      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("isLoggedInToGcet", "false");

        this.setState({
          isAuthenticated: false,
        });

        const authProps = {
          isAuth: this.state.isAuthenticated,
        };
        this.props.checkLoginStatus(authProps);
      }

      let app = data.applicationData;

      this.setState({
        last_step: app.last_step,
        step_one_finished: app.step_one_finished,
        step_two_finished: app.step_two_finished,
        step_three_finished: app.step_three_finished,
        step_four_finished: app.step_four_finished,
        final_submission_done: app.is_application_submitted,
        order_id_created: app.is_order_generated,
        is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
      });

      if (app.step_one_finished) {
        this.setState({
          photo_preview: true,
          full_name: app.name,
          date_of_birth: app.date_of_birth,
          gender: app.gender,
          mobile: app.mobile_number,
          alternate_mobile_number: app.alternate_mobile_number,
          aadhar: app.aadhar_card_number,
          email: app.email_id,
          postal_address: app.postal_address,
          componentDidMount: true,
          nationality: app.nationality,
          form_a_reg_no: app.form_a_reg_no,
        });
      }

      if (app.step_two_finished) {
        this.setState({
          hssc_board: app.hssc_board === null ? "" : app.hssc_board,
          hssc_board_year:
            app.hssc_board_year === null ? "" : app.hssc_board_year,
          hssc_board_seat_number: app.hssc_board_seat_number,

          hssc_total_marks:
            app.hssc_total_marks === null ? "" : app.hssc_total_marks,
          hssc_total_maximum_marks:
            app.hssc_total_maximum_marks === null
              ? ""
              : app.hssc_total_maximum_marks,
          hssc_english_marks:
            app.hssc_english_marks === null ? "" : app.hssc_english_marks,
          hssc_english_maximum_marks:
            app.hssc_english_maximum_marks === null
              ? ""
              : app.hssc_english_maximum_marks,
          hssc_physics_marks:
            app.hssc_physics_marks === null ? "" : app.hssc_physics_marks,
          hssc_physics_maximum_marks:
            app.hssc_physics_maximum_marks === null
              ? ""
              : app.hssc_physics_maximum_marks,
          hssc_maths_marks:
            app.hssc_maths_marks === null ? "" : app.hssc_maths_marks,
          hssc_maths_maximum_marks:
            app.hssc_maths_maximum_marks === null
              ? ""
              : app.hssc_maths_maximum_marks,
          hssc_chemistry_marks:
            app.hssc_chemistry_marks === null ? "" : app.hssc_chemistry_marks,
          hssc_chemistry_maximum_marks:
            app.hssc_chemistry_maximum_marks === null
              ? ""
              : app.hssc_chemistry_maximum_marks,
          hssc_biology_marks:
            app.hssc_biology_marks === null ? "" : app.hssc_biology_marks,
          hssc_biology_maximum_marks:
            app.hssc_biology_maximum_marks === null
              ? ""
              : app.hssc_biology_maximum_marks,
          hssc_others_marks:
            app.hssc_others_marks === null ? "" : app.hssc_others_marks,
          hssc_others_maximum_marks:
            app.hssc_others_maximum_marks === null
              ? ""
              : app.hssc_others_maximum_marks,

          neet_roll_no: app.neet_roll_no,
          neet_percentage: app.neet_percentage,
          neet_rank: app.neet_rank,
          gcet_roll_no: app.gcet_roll_no,
          gcet_physics_marks: app.gcet_physics_marks,
          gcet_chemistry_marks: app.gcet_chemistry_marks,
          gcet_maths_marks: app.gcet_maths_marks,

          applying_for_bvsc_ah: app.applying_for_bvsc_ah
        });
      }

      if (app.step_three_finished) {
        this.setState({
          general_category_selected: app.general_category_selected,
          sc_category_selected: app.sc_category_selected,
          st_category_selected: app.st_category_selected,
          obc_category_selected: app.obc_category_selected,
          freedom_fighter: app.freedom_fighter,
          person_with_disability: app.person_with_disability,
          children_of_central_state_employees:
            app.children_of_central_state_employees,
          ex_service_man: app.ex_service_man,
          local_applicant: app.local_applicant,
          goan_native: app.goan_native,
          goan_native_st: app.goan_native_st,
          goan_native_obc: app.goan_native_obc,
          nri_goan_origin: app.nri_goan_origin,
          nri_non_goan_origin: app.nri_non_goan_origin,
          pio_goan_origin: app.pio_goan_origin,
          pio_non_goan_origin: app.pio_non_goan_origin,
          outside_goa_applicant: app.outside_goa_applicant,
          kashmiri_migrant: app.kashmiri_migrant,
          foreign_national: app.foreign_national,
          gulf: app.gulf,
          documents_uploaded:
            app.documents_uploaded === null ? "" : app.documents_uploaded,
        });

        let categoryArr = [];

        let categoryList = [
          [this.state.general_category_selected, "GEN"],
          [this.state.sc_category_selected, "SC"],
          [this.state.st_category_selected, "ST"],
          [this.state.obc_category_selected, "OBC"],
          [this.state.freedom_fighter, "Freedom Fighter"],
          [this.state.person_with_disability, "PwD"],
          [this.state.children_of_central_state_employees, "CSP"],
          [this.state.ex_service_man, "ESM"],
          [this.state.local_applicant, "LA"],
          [this.state.goan_native, "Goan Native"],
          [this.state.goan_native_st, "Goan Native - ST"],
          [this.state.goan_native_obc, "Goan Native - OBC"],
          [this.state.nri_goan_origin, "NRI - Goan Origin"],
          [this.state.nri_non_goan_origin, "NRI - Non Goan Origin"],
          [this.state.pio_goan_origin, "PIO/OCI - Goan Origin"],
          [this.state.pio_non_goan_origin, "PIO/OCI - Non Goan Origin"],
          [this.state.outside_goa_applicant, "OGA"],
          [this.state.kashmiri_migrant, "KM"],
          [this.state.foreign_national, "FN/PIO"],
          [this.state.gulf, "Gulf"],
        ];

        for (let i = 0, j = 0; i < categoryList.length; i++) {
          if (categoryList[i][0]) {
            categoryArr[j] = categoryList[i][1];
            j++;
          }
        }

        let mappedArr = categoryArr.map((x, index) => (
          <span key={index}>{x} &nbsp;&nbsp;</span>
        ));

        this.setState({
          categoryArr: mappedArr,
        });
      }

      if(app.step_four_finished){
        this.setState({
          choiceArr: app.choice_of_colleges,
          tfw_preference: app.has_applied_for_fee_waiver
        })

        if(app.tfw_ews_consideration === "TFW, EWS"){
          this.setState({
            tfw_ews_consideration: "TFW, EWS",
          });
        } else if(app.tfw_ews_consideration === "TFW"){
          this.setState({
            tfw_ews_consideration: "TFW",
          });
        } else if(app.tfw_ews_consideration === "EWS"){
          this.setState({
            tfw_ews_consideration: "EWS",
          });
        } else if(app.tfw_ews_consideration === "NONE"){
          this.setState({
            tfw_ews_consideration: "NONE",
          });
        }
      }


      const finalStatus = {
        final_submission_done: app.is_application_submitted,
      };
      // this.props.checkApplicationStatus(finalStatus);

      if (!app.step_one_finished) {
        this.setState({
          message: `<h3>Not applied</h3><br />Please go to <a href="/form-n/basic-details" style="font-weight: 500">Step 1</a> and fill the application form`,
        });
      }

      if (this.state.final_submission_done) {
        this.setState({
          message: `<h3>Application submitted successfully.</h3><br />${
            this.props.location.data !== undefined
              ? this.props.location.data.message
              : 'You may click on the button below to download the <span style="font-weight: 500">Acknowledgment Card</span>'
          }`,
        });
      } else if (app.step_three_finished) {
        this.setState({
          message: `<h3>Application not completed</h3><br />Please go to <a href="/form-n/preview" style="font-weight: 500">Step 4</a> and complete the application`,
        
          general_category_selected: app.general_category_selected,
          sc_category_selected: app.sc_category_selected,
          st_category_selected: app.st_category_selected,
          obc_category_selected: app.obc_category_selected,
          freedom_fighter: app.freedom_fighter,
          person_with_disability: app.person_with_disability,
          children_of_central_state_employees:
            app.children_of_central_state_employees,
          ex_service_man: app.ex_service_man,
          local_applicant: app.local_applicant,
          goan_native: app.goan_native,
          goan_native_st: app.goan_native_st,
          goan_native_obc: app.goan_native_obc,
          nri_goan_origin: app.nri_goan_origin,
          nri_non_goan_origin: app.nri_non_goan_origin,
          pio_goan_origin: app.pio_oci_goan_origin,
          pio_non_goan_origin: app.pio_oci_non_goan_origin,
          outside_goa_applicant: app.outside_goa_applicant,
          kashmiri_migrant: app.kashmiri_migrant,
          foreign_national: app.foreign_national,
          gulf: app.gulf,
          documents_uploaded: app.documents_uploaded === null? '': app.documents_uploaded,
        });

        let categoryArr = [];

        let categoryList = [
          [this.state.general_category_selected, "GEN"],
          [this.state.sc_category_selected, "SC"],
          [this.state.st_category_selected, "ST"],
          [this.state.obc_category_selected, "OBC"],
          [this.state.freedom_fighter, "Freedom Fighter"],
          [this.state.person_with_disability, "PwD"],
          [this.state.children_of_central_state_employees, "CSP"],
          [this.state.ex_service_man, "ESM"],
          [this.state.local_applicant, "LA"],
          [this.state.goan_native, "Goan Native"],
          [this.state.goan_native_st, "Goan Native - ST"],
          [this.state.goan_native_obc, "Goan Native - OBC"],
          [this.state.nri_goan_origin, "NRI - Goan Origin"],
          [this.state.nri_non_goan_origin, "NRI - Non Goan Origin"],
          [this.state.pio_goan_origin, "PIO/OCI - Goan Origin"],
          [this.state.pio_non_goan_origin, "PIO/OCI - Non Goan Origin"],
          [this.state.outside_goa_applicant, "OGA"],
          [this.state.kashmiri_migrant, "KM"],
          [this.state.foreign_national, "FN/PIO"],
          [this.state.gulf, "Gulf"],
        ];

        for (let i = 0, j = 0; i < categoryList.length; i++) {
          if (categoryList[i][0]) {
            categoryArr[j] = categoryList[i][1];
            j++;
          }
        }

        let mappedArr = categoryArr.map((x, index) => <span key={index}>{x} &nbsp;&nbsp;</span>);

        this.setState({
          categoryArr: mappedArr,
        });
      } else if (app.step_two_finished) {
        this.setState({
          message: `<h3>Application not completed</h3><br />Please go to <a href="/form-n/category" style="font-weight: 500">Step 3</a> and complete the application`,

          hssc_board: app.hssc_board === null ? "" : app.hssc_board,
          hssc_board_year: app.hssc_year === null ? "" : app.hssc_year,
          hssc_percentage:
            app.hssc_percentage === null ? "" : app.hssc_percentage,

          hssc_total_marks:
            app.hssc_total_marks === null ? "" : app.hssc_total_marks,
          hssc_total_maximum_marks:
            app.hssc_total_maximum_marks === null
              ? ""
              : app.hssc_total_maximum_marks,
          hssc_english_marks:
            app.hssc_english_marks === null ? "" : app.hssc_english_marks,
          hssc_english_maximum_marks:
            app.hssc_english_maximum_marks === null
              ? ""
              : app.hssc_english_maximum_marks,
          hssc_physics_marks:
            app.hssc_physics_marks === null ? "" : app.hssc_physics_marks,
          hssc_physics_maximum_marks:
            app.hssc_physics_maximum_marks === null
              ? ""
              : app.hssc_physics_maximum_marks,
          hssc_maths_marks:
            app.hssc_maths_marks === null ? "" : app.hssc_maths_marks,
          hssc_maths_maximum_marks:
            app.hssc_maths_maximum_marks === null
              ? ""
              : app.hssc_maths_maximum_marks,
          hssc_chemistry_marks:
            app.hssc_chemistry_marks === null ? "" : app.hssc_chemistry_marks,
          hssc_chemistry_maximum_marks:
            app.hssc_chemistry_maximum_marks === null
              ? ""
              : app.hssc_chemistry_maximum_marks,
          hssc_biology_marks:
            app.hssc_biology_marks === null ? "" : app.hssc_biology_marks,
          hssc_biology_maximum_marks:
            app.hssc_biology_maximum_marks === null
              ? ""
              : app.hssc_biology_maximum_marks,
          hssc_others_marks:
            app.hssc_others_marks === null ? "" : app.hssc_others_marks,
          hssc_others_maximum_marks:
            app.hssc_others_maximum_marks === null
              ? ""
              : app.hssc_others_maximum_marks,
        });

        let courseArr = [];

        let courseList = [
          [this.state.engineering_course, "Engineering"],
          [this.state.mop_course, "MOP"],
          [this.state.pharmacy_course, "Pharmacy"],
        ];

        for (let i = 0, j = 0; i < courseList.length; i++) {
          if (courseList[i][0]) {
            courseArr[j] = courseList[i][1];
            j++;
          }
        }

        let mappedArr = courseArr.map((x, index) => <span key={index}>{x} &nbsp;&nbsp;</span>);

        this.setState({
          courseArr: mappedArr,
        });
      } else if (app.step_one_finished) {
        this.setState({
          full_name: app.name,
          date_of_birth: app.date_of_birth,
          gender: app.gender,
          mobile: app.mobile_number,
          alternate_mobile_number: app.alternate_mobile_number,
          aadhar: app.aadhar_card_number,
          email: app.email_id,
          postal_address: app.postal_address,
          nationality: app.nationality,
          componentDidMount: true,
          message: `<h3>Application not completed</h3><br />Please go to <a href="/form-n/academic-details" style="font-weight: 500">Step 2</a> and complete the application`,
        });

      }
      
      document.getElementById("message").innerHTML = this.state.message;
    } catch (error) {
      console.log("error", error);
    }
  }

  downloadPdf = () => {
    var myHeaders = new Headers();
    const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/download-acknowledgement`,
      requestOptions
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            pdf_fetched: true,
            loading_button: true,
          });
        } else if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");
          const authProps = {
            isAuth: false,
          };
          this.props.checkLoginStatus(authProps);
        } else {
          this.setState({
            pdf_fetched: false,
          });
        }
        return response.text();
      })
      .then((result) => {
        if (!this.state.pdf_fetched) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title" style={{ color: "red" }}>
                Error
              </h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );

          this.setState({
            loading_button: false,
          });
        } else {
          var bin = atob(JSON.parse(result).pdf);
          var len = bin.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
              bytes[i] = bin.charCodeAt(i);
          }
          const file = new Blob([bytes], {
            type: "application/pdf",
          });
          console.log(JSON.parse(result));
          var a = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          a.href = fileURL;
          a.download = `${JSON.parse(result).applicantDetails.rollNo}_${
            JSON.parse(result).applicantDetails.name.split(' ').join('_')
          }`;
          document.body.appendChild(a);
          a.click();
          this.setState({
            loading_button: false,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    if (this.state.isAuthenticated) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Form A Status
                </h1>
                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                    this.state.is_gcet_roll_no_verified,
                  }}
                />
                <p
                  style={{
                    marginBottom: "20px",
                  }}
                  className={`message__box ${
                    this.state.final_submission_done ? "success" : "warn"
                  }`}
                  id="message"
                >
                  <span>
                    {this.props.location.data !== undefined
                      ? this.props.location.data.message
                      : this.state.message}
                  </span>
                </p>
                <div
                  className={`button ${
                    this.state.loading_button ? "loading" : ""
                  }`}
                  onClick={this.downloadPdf}
                  style={{
                    display: `${
                      this.state.final_submission_done ? "inline-block" : "none"
                    }`,
                  }}
                >
                  Download Acknowledgment Card
                </div>
              </div>
              
              <div
                className="cell large-6"
                style={{
                  display: `${
                    this.state.final_submission_done ? "block" : "none"
                  }`,
                }}
              >
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Basic Details</b>
                        </td>
                      </tr>

                      <tr>
                        <td>Name</td>
                        <td>{this.state.full_name}</td>
                      </tr>

                      <tr>
                        <td>Date of Birth</td>
                        <td>{this.state.date_of_birth}</td>
                      </tr>

                      <tr>
                        <td>Gender</td>
                        <td>{this.state.gender}</td>
                      </tr>

                      <tr>
                        <td>Mobile No.</td>
                        <td>{this.state.mobile}</td>
                      </tr>

                      <tr>
                        <td>Alternate Mobile No.</td>
                        <td>{this.state.alternate_mobile_number}</td>
                      </tr>

                      <tr>
                        <td>Aadhar No. </td>
                        <td>{this.state.aadhar}</td>
                      </tr>

                      <tr>
                        <td>Postal Address</td>
                        <td>{this.state.postal_address}</td>
                      </tr>

                      <tr>
                        <td>Nationality</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {this.state.nationality}
                        </td>
                      </tr>

                      <tr style={{display: this.state.order_id_created? 'none': 'grid'}}>
                        <td>Form A Registration Number</td>
                        <td>{this.state.form_a_reg_no}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Categories selected</b>
                        </td>
                        <td>{this.state.categoryArr}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Applying for B.V.Sc or A.H Course?</b>
                        </td>
                        <td>{this.state.applying_for_bvsc_ah? 'Yes': 'No'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>12th Standard Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Board</td>
                        <td>{this.state.hssc_board}</td>
                      </tr>

                      <tr>
                        <td>Month &amp; Year of passing</td>
                        <td>{this.state.hssc_board_year}</td>
                      </tr>

                      <tr>
                        <td>Seat Number</td>
                        <td>{this.state.hssc_board_seat_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="cell large-6"
                style={{
                  display: `${
                    this.state.final_submission_done ? "block" : "none"
                  }`,
                }}
              >
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>GCET Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>GCET Roll Number</td>
                        <td>{this.state.gcet_roll_no}</td>
                      </tr>

                      <tr>
                        <td>Physics</td>
                        <td>{this.state.gcet_physics_marks}</td>
                      </tr>

                      <tr>
                        <td>Chemistry</td>
                        <td>{this.state.gcet_chemistry_marks}</td>
                      </tr>
                      <tr>
                        <td>Maths</td>
                        <td>{this.state.gcet_maths_marks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>NEET Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Roll Number</td>
                        <td>{this.state.neet_roll_no}</td>
                      </tr>

                      <tr>
                        <td>All India Rank</td>
                        <td>{this.state.neet_rank}</td>
                      </tr>

                      <tr>
                        <td>Percentile Score</td>
                        <td>{this.state.neet_percentage}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr className="three-sections">
                        <td>
                          <b>12th Standard Marks</b>
                        </td>
                        <td style={{ fontWeight: "600", fontSize: "14px" }}>
                          Marks
                        </td>
                        <td style={{ fontWeight: "600", fontSize: "14px" }}>
                          Max Marks
                        </td>
                      </tr>

                      <tr className="three-sections">
                        <td>Total Marks</td>
                        <td>{this.state.hssc_total_marks}</td>
                        <td>{this.state.hssc_total_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>English</td>
                        <td>{this.state.hssc_english_marks}</td>
                        <td>{this.state.hssc_english_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>Physics</td>
                        <td>{this.state.hssc_physics_marks}</td>
                        <td>{this.state.hssc_physics_maximum_marks}</td>
                      </tr>

                      { <tr className="three-sections">
                        <td>Maths</td>
                        <td>{this.state.hssc_maths_marks}</td>
                        <td>{this.state.hssc_maths_maximum_marks}</td>
                      </tr> }

                      <tr className="three-sections">
                        <td>Chemistry</td>
                        <td>{this.state.hssc_chemistry_marks}</td>
                        <td>{this.state.hssc_chemistry_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>Biology</td>
                        <td>{this.state.hssc_biology_marks}</td>
                        <td>{this.state.hssc_biology_maximum_marks}</td>
                      </tr>

                      { <tr className="three-sections">
                        <td>Other</td>
                        <td>{this.state.hssc_others_marks}</td>
                        <td>{this.state.hssc_others_maximum_marks}</td>
                      </tr> }
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="cell large-12">
                <div className="pre-footer" style={{ marginTop: "30px" }}>
                  <p
                    id="backend-error"
                    className={`${
                      this.state.submitted_successfully ? "success" : "failure"
                    } ${this.state.showErrorBox ? "show" : ""}`}
                    dangerouslySetInnerHTML={this.addBackendError()}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}