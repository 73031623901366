import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import swal from "@sweetalert/with-react";

// components
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";
import { DocumentUpload } from "../../components/DocumentUpload/index";
import { CategoryBox } from "../../components/Input/index";

// style
import inputStyle from "../../components/Input/input.module.scss";
import Cookies from "universal-cookie";

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class NCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      submitted_successfully: "",
      final_submission_done: false,
      last_step: "",
      componentDidMount: "",
      step_one_finished: true,
      step_two_finished: true,
      step_three_finished: false,
      step_four_finished: "",
      step_three_submitted_before: false,
      steps: "",
      showErrorBox: false,

      full_name: "",
      nationality: "",
      postal_address: "",
      guardians_name: "",

      general_category_selected: false,
      sc_category_selected: false,
      st_category_selected: false,
      obc_category_selected: false,
      freedom_fighter: false,
      person_with_disability: false,
      children_of_central_state_employees: false,
      ex_service_man: false,
      local_applicant: false,
      goan_native: false,
      goan_native_st: false,
      goan_native_obc: false,
      nri_goan_origin: false,
      nri_non_goan_origin: false,
      pio_oci_goan_origin: false,
      pio_oci_non_goan_origin: false,
      outside_goa_applicant: false,
      kashmiri_migrant: false,
      foreign_national: false,
      gulf: false, 
      documents_uploaded: [],
      documents_uploaded_urls: [],
      documentNamesArr: [],
      docsFetched: false,

      // category
      categoryListOne: [],
      categoryListTwo: [],

      upload_residence: false,
      upload_reserved_category_certificate: false,
      parents_birth_certificate: false,
      pio_oci_proof: false,
      upload_passport: false,
      upload_nri_certificate: false,

      loading_button: "",
      backendError: "",

      formErrors: {
        la_oga_not_selected: "",
      },
    };

    this.previewContainerRef = React.createRef();
    this.previewWindowRef = React.createRef();
    this.transactionReceiptRef = React.createRef();
    this.applicantsDeclarationRef = React.createRef();
    this.guardiansDeclarationRef = React.createRef();

    // category refs
    this.genCatRef = React.createRef();
    this.scCatRef = React.createRef();
    this.stCatRef = React.createRef();
    this.obcCatRef = React.createRef();
    this.ffCatRef = React.createRef();
    this.pwdCatRef = React.createRef();
    this.cspCatRef = React.createRef();
    this.esmCatRef = React.createRef();
    this.laCatRef = React.createRef();
    this.gnCatRef = React.createRef();
    this.gnStCatRef = React.createRef();
    this.gnObcCatRef = React.createRef();
    this.nriGoCatRef = React.createRef();
    this.nriNgoCatRef = React.createRef();
    this.pioGoCatRef = React.createRef();
    this.pioNgoCatRef = React.createRef();
    this.ogaCatRef = React.createRef();
    this.gnClearRef = React.createRef();
    this.kmCatRef = React.createRef();
    this.fnCatRef = React.createRef();
    this.gulfCatRef = React.createRef();

    this.genCatLabelRef = React.createRef();
    this.scCatLabelRef = React.createRef();
    this.stCatLabelRef = React.createRef();
    this.obcCatLabelRef = React.createRef();
    this.ffCatLabelRef = React.createRef();
    this.pwdCatLabelRef = React.createRef();
    this.cspCatLabelRef = React.createRef();
    this.esmCatLabelRef = React.createRef();
    this.laCatLabelRef = React.createRef();
    this.gnCatLabelRef = React.createRef();
    this.gnStCatLabelRef = React.createRef();
    this.gnObcCatLabelRef = React.createRef();
    this.nriGoCatLabelRef = React.createRef();
    this.nriNgoCatLabelRef = React.createRef();
    this.pioGoCatLabelRef = React.createRef();
    this.pioNgoCatLabelRef = React.createRef();
    this.ogaCatLabelRef = React.createRef();
    this.kmCatLabelRef = React.createRef();
    this.fnCatLabelRef = React.createRef();
    this.gulfCatLabelRef = React.createRef();
  }

  checkValidation = () => {
    if (formValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        showErrorBox: true,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        showErrorBox: true,
      });
    }
  };

  submit_application = (event) => {
    event.preventDefault();
    this.setState({
      loading_button: true,
    });

    if (formValid(this.state.formErrors)) {
      var myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("authorization", "token " + cookies.get("token"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        general_category_selected: this.state.general_category_selected,
        sc_category_selected: this.state.sc_category_selected,
        st_category_selected: this.state.st_category_selected,
        obc_category_selected: this.state.obc_category_selected,
        freedom_fighter: this.state.freedom_fighter,
        person_with_disability: this.state.person_with_disability,
        children_of_central_state_employees:
          this.state.children_of_central_state_employees,
        ex_service_man: this.state.ex_service_man,
        local_applicant: this.state.local_applicant,
        goan_native: this.state.goan_native,
        goan_native_st: this.state.goan_native_st,
        goan_native_obc: this.state.goan_native_obc,
        nri_goan_origin: this.state.nri_goan_origin,
        nri_non_goan_origin: this.state.nri_non_goan_origin,
        pio_oci_goan_origin: this.state.pio_oci_goan_origin,
        pio_oci_non_goan_origin: this.state.pio_oci_non_goan_origin,
        outside_goa_applicant: this.state.outside_goa_applicant,
        kashmiri_migrant: this.state.kashmiri_migrant,
        foreign_national: this.state.foreign_national,
        gulf: this.state.gulf,
        guardians_name: this.state.guardians_name,
        
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/step3`,
        requestOptions
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              showErrorBox: true,
              step_three_finished: true,
              submitted_successfully: true,
            });
          } else if (response.status === 401) {
            localStorage.setItem("isLoggedInToGcet", "false");

            this.setState({
              isAuthenticated: false,
            });
            const authProps = {
              isAuth: this.state.isAuthenticated,
            };
            this.props.checkLoginStatus(authProps);
          } else {
            this.setState({
              submitted_successfully: false,
              showErrorBox: true,
            });
          }
          return response.text();
        })
        .then((result) => {
          if (JSON.parse(result).error === true) {
            let errorArray = JSON.parse(result).message.split(".");
            let message = errorArray.map((el) => `${el} <br />`);

            this.setState({
              showErrorBox: true,
              backendError: message,
              loading_button: false,
            });
          } else {
            this.setState({
              backendError: JSON.parse(result).message,
              loading_button: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showErrorBox: true,
            submitted_successfully: false,
            backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
            loading_button: false,
          });
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        showErrorBox: true,
        backendError: `There are errors in the form. <br /> <span>${error}</span>`,
        loading_button: false,
        submitted_successfully: false,
      });
    }
  };

  // this function sets the document names
  changeStates = () => {
    this.setState({
      documentNamesArr: [
        {
          name: "Birth Certificate",
          id: "date_of_birth_certificate",
          show: true,
        },
        {
          name: "12th Std. Marksheet",
          id: "hssc_marksheet_certificate",
          show: true,
        },
        {
          name: "Leaving Certificate",
          id: "college_leaving_certificate",
          show: true,
        },
        { name: "Aadhar Card", id: "aadhar_card", show: true },
        { name: "NEET Admit Card", id: "neet_admit_card", show: true },
        { name: "Copy of NEET Result", id: "neet_result", show: true },
        {
          name: "Residence / Bonafide Certificate",
          id: "resident_proof",
          show: this.state.upload_residence,
        },
        {
          name: "Caste Certificate",
          id: "reserved_category_certificate",
          show: this.state.upload_reserved_category_certificate,
        },
        {
          name: "Freedom Fighter Certificate",
          id: "freedom_fighter_proof",
          show: this.state.freedom_fighter,
        },
        {
          name: "Medical Certificate for PwD",
          id: "person_with_disability_proof",
          show: this.state.person_with_disability,
        },
        {
          name: "Certificate from Rajya Sainik  Board",
          id: "ex_service_man_proof",
          show: this.state.ex_service_man,
        },
        {
          name: "Parents/Grandparents Birth Certificate",
          id: "parents_birth_certificate",
          show: this.state.parents_birth_certificate,
        },
        {
          name: "PIO / OCI Card",
          id: "pio_oci_proof",
          show: this.state.pio_oci_proof,
        },
        {
          name: "Parents and Applicant's Passport",
          id: "passport",
          show: this.state.upload_passport,
        },
        {
          name: "NRI Certificate",
          id: "non_resident_indian_proof",
          show: this.state.upload_nri_certificate,
        },
        {
          name: "Parents Service Certificate",
          id: "parents_service_certificate",
          show: this.state.children_of_central_state_employees,
        },
        {
          name: "Parents Residence Certificate",
          id: "parents_residence_certificate",
          show: this.state.children_of_central_state_employees,
        },
        {
          name: "Migrant Certificate",
          id: "kashmiri_migrant_proof",
          show: this.state.kashmiri_migrant,
        },
        {
          name: "Additional Document",
          id: "additional_document_one",
          show: true,
        },
        {
          name: "Additional Document",
          id: "additional_document_two",
          show: true,
        },
        {
          name: "Additional Document",
          id: "additional_document_three",
          show: true,
        },
      ],
    });
  };

  showDocumentInputElements = () => {
    /* for Residence Certificate */
    if (
      this.state.general_category_selected ||
      this.state.sc_category_selected ||
      this.state.st_category_selected ||
      this.state.obc_category_selected ||
      this.state.freedom_fighter ||
      this.state.person_with_disability ||
      this.state.local_applicant ||
      this.state.outside_goa_applicant
    ) {
      this.setState({
        upload_residence: true,
      });
    } else {
      this.setState({
        upload_residence: false,
      });
    }

    /* Reserved Category Certificate */
    if (
      this.state.obc_category_selected ||
      this.state.sc_category_selected ||
      this.state.st_category_selected ||
      this.state.goan_native_obc ||
      this.state.goan_native_st
    ) {
      this.setState({
        upload_reserved_category_certificate: true,
      });
    } else {
      this.setState({
        upload_reserved_category_certificate: false,
      });
    }

    /* Parents and Grandparents Birth Certificate*/
    if (
      this.state.goan_native ||
      this.state.goan_native_obc ||
      this.state.goan_native_st ||
      this.state.nri_goan_origin ||
      this.state.pio_oci_goan_origin
    ) {
      this.setState({
        parents_birth_certificate: true,
      });
    } else {
      this.setState({
        parents_birth_certificate: false,
      });
    }

    /* PIO/OCI Proof */
    if (this.state.pio_oci_goan_origin || this.state.pio_oci_non_goan_origin) {
      this.setState({
        pio_oci_proof: true,
      });
    } else {
      this.setState({
        pio_oci_proof: false,
      });
    }

    /* Applicant's and parents passport */
    if (
      this.state.nri_goan_origin ||
      this.state.nri_non_goan_origin ||
      this.state.pio_oci_goan_origin ||
      this.state.pio_oci_non_goan_origin
    ) {
      this.setState({
        upload_passport: true,
      });
    } else {
      this.setState({
        upload_passport: false,
      });
    }

    /* for nri certificate */
    if (this.state.nri_goan_origin || this.state.nri_non_goan_origin) {
      this.setState({
        upload_nri_certificate: true,
      });
    } else {
      this.setState({
        upload_nri_certificate: false,
      });
    }

    this.changeStates();
  };

  // this fucntion is used when the user unchecks the category and the document is already uploaded.
  // this function won't be executed when the user clicks on the delete icon. That function is in the DocumentUpload.jsx file
  removeDocument = async (id) => {
    /* to check whether the file is already in database*/
    let data = id;
    let selectedElementExists = false;
    let documentList = this.state.documents_uploaded;

    documentList.length > 0 &&
      documentList.forEach((document_name) => {
        if (document_name === data) {
          selectedElementExists = true;
        }
      });

    if (selectedElementExists) {
      var axios = require("axios");
      axios.defaults.withCredentials = true;
      const cookies = new Cookies();
      var config = {
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/admission/application/uploads/${data}`,
        headers: {
          authorization: "token " + cookies.get("token"),
        },
      };

      let updatedDocumentList = [];
      let documentList = this.state.documents_uploaded;

      await axios(config)
        .then(function (response) {
          if (
            JSON.stringify(response.status) >= 200 &&
            JSON.stringify(response.status) < 300
          ) {
            /* to remove the element id from the array */
            updatedDocumentList = documentList.filter(
              (document_name) => document_name !== data
            );

            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title">Successful</h1>
                <p className="counselling__para">
                  File has been deleted successfully.
                </p>
              </div>
            );
          } else {
            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title">Error</h1>
                <p className="counselling__para">
                  File could not be deleted. Please try again.
                </p>
              </div>
            );
          }
        })
        .catch(function (error) {
          console.log(error);
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Error</h1>
              <p className="counselling__para">
                The file could not be deleted. Please try again.
              </p>
            </div>
          );
        });
      this.setState({
        documents_uploaded: updatedDocumentList,
      });
    }
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value, id } = event.target;
    /* uncomment to use*/
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    let genCat = this.genCatRef.current;
    let scCat = this.scCatRef.current;
    let stCat = this.stCatRef.current;
    let obcCat = this.obcCatRef.current;
    let ffCat = this.ffCatRef.current;
    let pwdCat = this.pwdCatRef.current;
    let cspCat = this.cspCatRef.current;
    let esmCat = this.esmCatRef.current;
    let laCat = this.laCatRef.current;
    let gnCat = this.gnCatRef.current;
    let gnStCat = this.gnStCatRef.current;
    let gnObcCat = this.gnObcCatRef.current;
    let nriGoCat = this.nriGoCatRef.current;
    let nriNgoCat = this.nriNgoCatRef.current;
    let pioGoCat = this.pioGoCatRef.current;
    let pioNgoCat = this.pioNgoCatRef.current;
    let ogaCat = this.ogaCatRef.current;
    let kmCat = this.kmCatRef.current;
    let fnCat = this.fnCatRef.current;
    let gulfCat = this.gulfCatRef.current;

    let genLabel = this.genCatLabelRef.current;
    let scLabel = this.scCatLabelRef.current;
    let stLabel = this.stCatLabelRef.current;
    let obcLabel = this.obcCatLabelRef.current;
    let ffLabel = this.ffCatLabelRef.current;
    let pwdLabel = this.pwdCatLabelRef.current;
    let cspLabel = this.cspCatLabelRef.current;
    let esmLabel = this.esmCatLabelRef.current;
    let laLabel = this.laCatLabelRef.current;
    let gnLabel = this.gnCatLabelRef.current;
    let gn_stLabel = this.gnStCatLabelRef.current;
    let gn_obcLabel = this.gnObcCatLabelRef.current;
    let nri_goLabel = this.nriGoCatLabelRef.current;
    let nri_ngoLabel = this.nriNgoCatLabelRef.current;
    let pio_goLabel = this.pioGoCatLabelRef.current;
    let pio_ngoLabel = this.pioNgoCatLabelRef.current;
    let ogaLabel = this.ogaCatLabelRef.current;
    let kmLabel = this.kmCatLabelRef.current;
    let fnLabel = this.fnCatLabelRef.current;
    // let gulfLabel = this.gulfCatLabelRef.current;
    /* to make general, csp and nri mutually exclusive to each other*/
    /* to make General (1) and Goan  Native (10) mutually exclusive to each other*/
    /* to make general and 14 mutually exclusive to each other */
    if (name === "genCat") {
      // what if csp is already checked?
      if (cspCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">CSP</span> category in order to
              select the <span className="category-name">General</span>{" "}
              category.
            </p>
          </div>
        );
        genCat.checked = false;
      } else if (
        nriGoCat.checked ||
        nriNgoCat.checked ||
        pioGoCat.checked ||
        pioNgoCat.checked
      ) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">NRI</span> category in order to
              select the <span className="category-name">General</span>{" "}
              category.
            </p>
          </div>
        );
        genCat.checked = false;
      }

      // what if Goan Native / ST / OBC
      if (gnCat.checked || gnStCat.checked || gnObcCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">Goan Native</span> category in
              order to select the <span className="category-name">General</span>{" "}
              category.
            </p>
          </div>
        );
        genCat.checked = false;
      }

      if (laCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">Local Applicant</span> category in
              order to select the <span className="category-name">General</span>{" "}
              category.
            </p>
          </div>
        );
        genCat.checked = false;
      } else if (ogaCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">OGA</span> category in order to
              select the <span className="category-name">General</span>{" "}
              category.
            </p>
          </div>
        );
        genCat.checked = false;
      }

      // if nothing which is mutually exclusive is checked.
      if (genCat.checked) {
        genLabel.classList.remove(inputStyle.disable);

        /* csp nri gen mutually exclusive */
        cspLabel.classList.add(inputStyle.disable);
        nri_goLabel.classList.add(inputStyle.disable);
        nri_ngoLabel.classList.add(inputStyle.disable);
        pio_goLabel.classList.add(inputStyle.disable);
        pio_ngoLabel.classList.add(inputStyle.disable);

        /* goan native is mutually exlusive with general */
        gnLabel.classList.add(inputStyle.disable);
        gn_obcLabel.classList.add(inputStyle.disable);
        gn_stLabel.classList.add(inputStyle.disable);

        /* 1 mutually exclusive with 9 and 12 and 15*/
        laLabel.classList.add(inputStyle.disable);
        ogaLabel.classList.add(inputStyle.disable);

        // finally setting general category to true. Checkbox to true.
        this.setState({
          general_category_selected: true,
        });
      } else {
        if (
          !(
            nriGoCat.checked ||
            nriNgoCat.checked ||
            pioGoCat.checked ||
            pioNgoCat.checked ||
            cspCat.checked
          )
        ) {
          cspLabel.classList.remove(inputStyle.disable);
          nri_goLabel.classList.remove(inputStyle.disable);
          nri_ngoLabel.classList.remove(inputStyle.disable);
          pio_goLabel.classList.remove(inputStyle.disable);
          pio_ngoLabel.classList.remove(inputStyle.disable);
        }

        gnLabel.classList.remove(inputStyle.disable);
        gn_obcLabel.classList.remove(inputStyle.disable);
        gn_stLabel.classList.remove(inputStyle.disable);

        if (!laCat.checked) {
          ogaLabel.classList.remove(inputStyle.disable);
        }

        if (!ogaCat.checked) {
          laLabel.classList.remove(inputStyle.disable);
        }

        this.setState({
          general_category_selected: false,
        });

        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          if (this.state.documents_uploaded[i] === "resident_proof") {
            this.removeDocument("resident_proof");
          }
        }

        if (scCat.checked || obcCat.checked || stCat.checked) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                Unchecking <span className="category-name">General</span>{" "}
                category will <span className="warn">uncheck</span>{" "}
                <span className="category-name">Reserved</span> Category too.
              </p>
            </div>
          );
          scCat.checked = false;
          stCat.checked = false;
          obcCat.checked = false;

          this.setState({
            sc_category_selected: false,
            st_category_selected: false,
            obc_category_selected: false,
          });

          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (
              this.state.documents_uploaded[i] ===
              "reserved_category_certificate"
            ) {
              this.removeDocument("reserved_category_certificate");
            }
          }
        }

        if (ffCat.checked) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                Unchecking <span className="category-name">General</span>{" "}
                category will <span className="warn">uncheck</span>{" "}
                <span className="category-name">Freedom Fighter</span> Category
                too.
              </p>
            </div>
          );
          ffCat.checked = false;
          esmLabel.classList.remove(inputStyle.disable);

          this.setState({
            freedom_fighter: false,
          });

          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (this.state.documents_uploaded[i] === "freedom_fighter_proof") {
              this.removeDocument("freedom_fighter_proof");
            }
          }
        }

        if (pwdCat.checked) {
          pwdCat.checked = false;

          this.setState({
            person_with_disability: false,
          });

          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (
              this.state.documents_uploaded[i] ===
              "person_with_disability_proof"
            ) {
              this.removeDocument("person_with_disability_proof");
            }

          }
          kmLabel.classList.remove(inputStyle.disable);

        }
      }
    }

    if (name === "cspCat") {
      // CSP is mutually exclusive to GEN.
      // What if CSP is already active?
      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">CSP</span> category.
            </p>
          </div>
        );
        cspCat.checked = false;
      }

      // What is NRI / PIO is checked.
      if (
        nriGoCat.checked ||
        nriNgoCat.checked ||
        pioGoCat.checked ||
        pioNgoCat.checked
      ) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">NRI</span> category in order to
              select the <span className="category-name">CSP</span> category.
            </p>
          </div>
        );
        cspCat.checked = false;
      }

      // If there are no conditional errors, finally setting CSP to true
      if (cspCat.checked) {
        nri_goLabel.classList.add(inputStyle.disable);
        nri_ngoLabel.classList.add(inputStyle.disable);
        pio_goLabel.classList.add(inputStyle.disable);
        pio_ngoLabel.classList.add(inputStyle.disable);
        genLabel.classList.add(inputStyle.disable);

        this.setState({
          children_of_central_state_employees: true,
        });
      } else {
        if (
          !(
            genCat.checked ||
            nriGoCat.checked ||
            nriNgoCat.checked ||
            pioGoCat.checked ||
            pioNgoCat.checked
          )
        ) {
          nri_goLabel.classList.remove(inputStyle.disable);
          nri_ngoLabel.classList.remove(inputStyle.disable);
          pio_goLabel.classList.remove(inputStyle.disable);
          pio_ngoLabel.classList.remove(inputStyle.disable);
          genLabel.classList.remove(inputStyle.disable);
        }

        this.setState({
          children_of_central_state_employees: false,
        });

        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          if (
            this.state.documents_uploaded[i] === "parents_service_certificate"
          ) {
            this.removeDocument("parents_service_certificate");
          }
          if (
            this.state.documents_uploaded[i] === "parents_residence_certificate"
          ) {
            this.removeDocument("parents_residence_certificate");
          }
        }
      }
    }

    if (name === "nri-cat") {
      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">NRI</span> category.
            </p>
          </div>
        );
        nriGoCat.checked = false;
        nriNgoCat.checked = false;
        pioGoCat.checked = false;
        pioNgoCat.checked = false;
      }

      if (cspCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">CSP</span> category in order to
              select the <span className="category-name">NRI</span> category.
            </p>
          </div>
        );
        nriGoCat.checked = false;
        nriNgoCat.checked = false;
        pioGoCat.checked = false;
        pioNgoCat.checked = false;
      }

      if (nriGoCat.checked) {
        genLabel.classList.add(inputStyle.disable);
        cspLabel.classList.add(inputStyle.disable);

        this.setState({
          nri_goan_origin: true,
          nri_non_goan_origin: false,
          pio_oci_goan_origin: false,
          pio_oci_non_goan_origin: false,
        });

        if (!(gnCat.checked || gnObcCat.checked || gnStCat.checked)) {
          this.setState({
            goan_native: true,
          });
          gnCat.checked = true;

          gnLabel.classList.remove(inputStyle.disable);
          gn_obcLabel.classList.remove(inputStyle.disable);
          gn_stLabel.classList.remove(inputStyle.disable);

          // nri_ngoLabel.classList.add('disable');
          // pio_goLabel.classList.add('disable');
          // pio_ngoLabel.classList.add('disable');

          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                <span className="category-name">NRI - Goan Origin</span> will
                select <span className="category-name">Goan Native</span>{" "}
                category automatically.
              </p>
            </div>
          );
        }
      } else if (nriNgoCat.checked) {
        if (gnCat.checked || gnObcCat.checked || gnStCat.checked) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                <span className="category-name">NRI - NGO</span> category cannot
                be selected with{" "}
                <span className="category-name">Goan Native</span> category.
              </p>
            </div>
          );
          nriNgoCat.checked = false;
          cspLabel.classList.remove(inputStyle.disable);

          /* this is done to uncheck nri goan origin. There was no way to check whether it was clicked b4.*/
          this.setState({
            nri_goan_origin: false,
          });
        } else {
          genLabel.classList.add(inputStyle.disable);
          cspLabel.classList.add(inputStyle.disable);

          gnLabel.classList.add(inputStyle.disable);
          gn_stLabel.classList.add(inputStyle.disable);
          gn_obcLabel.classList.add(inputStyle.disable);

          this.setState({
            nri_non_goan_origin: true,
            nri_goan_origin: false,
            pio_oci_goan_origin: false,
            pio_oci_non_goan_origin: false,
          });
        }
      } else if (pioGoCat.checked) {
        if (gnCat.checked || gnObcCat.checked || gnStCat.checked) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                <span className="category-name">PIO - GO</span> category cannot
                be selected with{" "}
                <span className="category-name">Goan Native</span> category.
              </p>
            </div>
          );
          pioGoCat.checked = false;
          cspLabel.classList.remove(inputStyle.disable);
          this.setState({
            nri_goan_origin: false,
          });
        } else {
          genLabel.classList.add(inputStyle.disable);
          cspLabel.classList.add(inputStyle.disable);

          gnLabel.classList.add(inputStyle.disable);
          gn_stLabel.classList.add(inputStyle.disable);
          gn_obcLabel.classList.add(inputStyle.disable);

          this.setState({
            nri_non_goan_origin: false,
            nri_goan_origin: false,
            pio_oci_goan_origin: true,
            pio_oci_non_goan_origin: false,
          });
        }
      } else if (pioNgoCat.checked) {
        if (gnCat.checked || gnObcCat.checked || gnStCat.checked) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Attention!</h1>
              <p className="counselling__para">
                <span className="category-name">PIO - NGO</span> category cannot
                be selected with{" "}
                <span className="category-name">Goan Native</span> category.
              </p>
            </div>
          );
          pioNgoCat.checked = false;
          cspLabel.classList.remove(inputStyle.disable);
          this.setState({
            nri_goan_origin: false,
          });
        } else {
          genLabel.classList.add(inputStyle.disable);
          cspLabel.classList.add(inputStyle.disable);

          gnLabel.classList.add(inputStyle.disable);
          gn_stLabel.classList.add(inputStyle.disable);
          gn_obcLabel.classList.add(inputStyle.disable);

          this.setState({
            nri_non_goan_origin: false,
            nri_goan_origin: false,
            pio_oci_goan_origin: false,
            pio_oci_non_goan_origin: true,
          });
        }
      } else {
        gnLabel.classList.remove(inputStyle.disable);
        gn_stLabel.classList.remove(inputStyle.disable);
        gn_obcLabel.classList.remove(inputStyle.disable);

        if (!(genCat.checked || cspCat.checked)) {
          cspLabel.classList.remove(inputStyle.disable);

          if (!(gnCat.checked || gnStCat.checked || gnObcCat.checked)) {
            genLabel.classList.remove(inputStyle.disable);
          }
        }

        this.setState({
          nri_goan_origin: false,
          nri_non_goan_origin: false,
          pio_oci_goan_origin: false,
          pio_oci_non_goan_origin: false,
        });

        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          if (this.state.documents_uploaded[i] === "passport") {
            this.removeDocument("passport");
          }
          if (
            this.state.documents_uploaded[i] === "non_resident_indian_proof"
          ) {
            this.removeDocument("non_resident_indian_proof");
          }
          if (this.state.documents_uploaded[i] === "pio_oci_proof") {
            this.removeDocument("pio_oci_proof");
          }

          if (
            this.state.documents_uploaded[i] === "parents_birth_certificate" &&
            !(gnCat.checked || gnObcCat.checked || gnStCat.checked)
          ) {
            this.removeDocument("parents_birth_certificate");
          }
        }
      }
    }

    /*
    To make General compulsory for Reserved categories
    */

    if (name === "reserved_category") {
      if (!genCat.checked) {
        scCat.checked = false;
        stCat.checked = false;
        obcCat.checked = false;

        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              You need to select <span className="category-name">General</span>{" "}
              category in order to be eligible for{" "}
              <span className="category-name">Reserved (SC / ST / OBC)</span>{" "}
              Category
            </p>
          </div>
        );
      } else {
        if (id === "scCat") {
          this.setState({
            sc_category_selected: true,
            obc_category_selected: false,
            st_category_selected: false,
          });
        } else if (id === "stCat") {
          this.setState({
            sc_category_selected: false,
            obc_category_selected: false,
            st_category_selected: true,
          });
        } else if (id === "obcCat") {
          this.setState({
            sc_category_selected: false,
            obc_category_selected: true,
            st_category_selected: false,
          });
        } else {
          this.setState({
            sc_category_selected: false,
            obc_category_selected: false,
            st_category_selected: false,
          });
          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (
              this.state.documents_uploaded[i] ===
              "reserved_category_certificate"
            ) {
              this.removeDocument("reserved_category_certificate");
            }
          }
        }
      }
    }

    /* to make freedom fighter mutually exlusive with Goan Native and ESM*/
    /* NOTE: Goan Native and ESM can be selected together */
    /* to make Goan Native (10) and General(1) mutually exclusive */
    /* FF can only be selected if Gen is selected */
    if (name === "ffCat") {
      if (esmCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">ESM</span> category in order to
              select the <span className="category-name">Freedom Fighter</span>{" "}
              category.
            </p>
          </div>
        );
        ffCat.checked = false;
      }

      if (genCat.checked) {
        if (ffCat.checked) {
          this.setState({
            freedom_fighter: true,
          });
          esmLabel.classList.add(inputStyle.disable);
        } else {
          esmLabel.classList.remove(inputStyle.disable);

          this.setState({
            freedom_fighter: false,
          });

          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (this.state.documents_uploaded[i] === "freedom_fighter_proof") {
              this.removeDocument("freedom_fighter_proof");
            }
          }
        }
      } else {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              You need to check <span className="category-name">General</span>{" "}
              category in order to be eligible for{" "}
              <span className="category-name">Freedom Fighter</span> Category
            </p>
          </div>
        );
        ffCat.checked = false;
      }
    }

    if (name === "esmCat") {
      if (ffCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">Freedom Fighter</span> category in
              order to select the <span className="category-name">ESM</span>{" "}
              category.
            </p>
          </div>
        );
        esmCat.checked = false;
      }
      if (esmCat.checked) {
        ffLabel.classList.add(inputStyle.disable);
        this.setState({
          ex_service_man: true,
        });
      } else if (!esmCat.checked) {
        ffLabel.classList.remove(inputStyle.disable);
        this.setState({
          ex_service_man: false,
        });
        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          if (this.state.documents_uploaded[i] === "ex_service_man_proof") {
            this.removeDocument("ex_service_man_proof");
          }
        }
      }
    }

    if (name === "goan-native") {
      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">Goan Native</span>{" "}
              category.
            </p>
          </div>
        );
        gnCat.checked = false;
        gnStCat.checked = false;
        gnObcCat.checked = false;
      }

      if (nriNgoCat.checked || pioGoCat.checked || pioNgoCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              <span className="category-name">Goan Native</span> category cannot
              be selected with{" "}
              <span className="category-name">NRI - NGO, PIO - GO</span> or{" "}
              <span className="category-name">PIO - NGO</span>category.
            </p>
          </div>
        );
        gnCat.checked = false;
        gnStCat.checked = false;
        gnObcCat.checked = false;
      }

      if (gnCat.checked || gnObcCat.checked || gnStCat.checked) {
        ffLabel.classList.add(inputStyle.disable);
        genLabel.classList.add(inputStyle.disable);
        scLabel.classList.add(inputStyle.disable);
        stLabel.classList.add(inputStyle.disable);
        obcLabel.classList.add(inputStyle.disable);

        nri_ngoLabel.classList.add(inputStyle.disable);
        pio_goLabel.classList.add(inputStyle.disable);
        pio_ngoLabel.classList.add(inputStyle.disable);

        if (gnCat.checked) {
          this.setState({
            goan_native: true,
            goan_native_obc: false,
            goan_native_st: false,
          });

          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            /* to delete reserved_category_certificate */
            if (
              this.state.documents_uploaded[i] ===
              "reserved_category_certificate"
            ) {
              this.removeDocument("reserved_category_certificate");
            }
          }
        } else if (gnObcCat.checked) {
          this.setState({
            goan_native_obc: true,
            goan_native: false,
            goan_native_st: false,
          });
        } else {
          this.setState({
            goan_native_st: true,
            goan_native: false,
            goan_native_obc: false,
          });
        }
      } else if (this.gnClearRef.current.checked) {
        nri_ngoLabel.classList.remove(inputStyle.disable);
        pio_goLabel.classList.remove(inputStyle.disable);
        pio_ngoLabel.classList.remove(inputStyle.disable);

        ffLabel.classList.remove(inputStyle.disable);
        genLabel.classList.remove(inputStyle.disable);
        scLabel.classList.remove(inputStyle.disable);
        stLabel.classList.remove(inputStyle.disable);
        obcLabel.classList.remove(inputStyle.disable);

        this.setState({
          goan_native: false,
          goan_native_obc: false,
          goan_native_st: false,
        });

        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          /* do not delete 'parents birth' if nri goan origin or pio/oci goan origin is checked */
          if (
            this.state.documents_uploaded[i] === "parents_birth_certificate" &&
            !this.state.nri_goan_origin
          ) {
            this.removeDocument("parents_birth_certificate");
          }

          /* to delete reserved_category_certificate */
          if (
            this.state.documents_uploaded[i] === "reserved_category_certificate"
          ) {
            this.removeDocument("reserved_category_certificate");
          }
        }
      }
    }

    /* To make PWD, LA, OGA, mutually exclusive to each other */
    /* 2-module: to make General mutually exclusive with 9 (LA) and 12 (OGA) */
    if (name === "pwdCat") {
      if (laCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">LA</span> category in order to
              select the <span className="category-name">PwD</span> category.
            </p>
          </div>
        );
        pwdCat.checked = false;
      } else if (ogaCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">OGA</span> category in order to
              select the <span className="category-name">PwD</span> category.
            </p>
          </div>
        );
        pwdCat.checked = false;
      } else if (kmCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">KM</span> category in order to
              select the <span className="category-name">PwD</span> category.
            </p>
          </div>
        );
        pwdCat.checked = false;
      } else if (fnCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">FN / PIO</span> category in order
              to select the <span className="category-name">PwD</span> category.
            </p>
          </div>
        );
        pwdCat.checked = false;
      }

      

      if (genCat.checked) {
        if (pwdCat.checked) {
          laLabel.classList.add(inputStyle.disable);
          ogaLabel.classList.add(inputStyle.disable);
          this.setState({
            person_with_disability: true,
          });
        } else {
          if (!(laCat.checked || ogaCat.checked)) {
            pwdLabel.classList.remove(inputStyle.disable);
            laLabel.classList.remove(inputStyle.disable);
            ogaLabel.classList.remove(inputStyle.disable);

            this.setState({
              person_with_disability: false,
            });

            for (let i = 0; i < this.state.documents_uploaded.length; i++) {
              if (
                this.state.documents_uploaded[i] ===
                "person_with_disability_proof"
              ) {
                this.removeDocument("person_with_disability_proof");
              }
            }
          }
        }
      } else {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              You need to select <span className="category-name">General</span>{" "}
              category in order to be eligible for{" "}
              <span className="category-name">PwD</span> Category
            </p>
          </div>
        );
        pwdCat.checked = false;
      }
    }

    if (name === "laCat") {
      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">LA</span> category.
            </p>
          </div>
        );
        laCat.checked = false;
      }

      if (pwdCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">PwD</span> category in order to
              select the <span className="category-name">LA</span> category.
            </p>
          </div>
        );
        laCat.checked = false;
      } else if (ogaCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">OGA</span> category in order to
              select the <span className="category-name">LA</span> category.
            </p>
          </div>
        );
        laCat.checked = false;
      }

      if (laCat.checked) {
        // laLabel.classList.add('disable');
        ogaLabel.classList.add(inputStyle.disable);
        kmLabel.classList.add(inputStyle.disable);
        fnLabel.classList.add(inputStyle.disable);
        pwdLabel.classList.add(inputStyle.disable);

        genLabel.classList.add(inputStyle.disable);

        this.setState({
          local_applicant: true,
        });
      } else {
        if (!(pwdCat.checked || ogaCat.checked || kmCat.checked || fnCat.checked)) {
          kmLabel.classList.remove(inputStyle.disable);
          pwdLabel.classList.remove(inputStyle.disable);
          genLabel.classList.remove(inputStyle.disable);

          if (!genCat.checked) {
            laLabel.classList.remove(inputStyle.disable);
            ogaLabel.classList.remove(inputStyle.disable);
            fnLabel.classList.remove(inputStyle.disable);
          }
        }

        this.setState({
          local_applicant: false,
        });
      }
    }

    if (name === "ogaCat") {
      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">OGA</span> category.
            </p>
          </div>
        );
        ogaCat.checked = false;
      }

      if (laCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">LA</span> category in order to
              select the <span className="category-name">OGA</span> category.
            </p>
          </div>
        );
        ogaCat.checked = false;
      } else if (pwdCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">PwD</span> category in order to
              select the <span className="category-name">OGA</span> category.
            </p>
          </div>
        );
        ogaCat.checked = false;
      }
      else if (kmCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">KM</span> category in order to
              select the <span className="category-name">OGA</span> category.
            </p>
          </div>
        );
        ogaCat.checked = false;
      } else if (fnCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">FN / PIO</span> category in order
              to select the <span className="category-name">OGA</span> category.
            </p>
          </div>
        );
        ogaCat.checked = false;
      }

      if (ogaCat.checked) {
        laLabel.classList.add(inputStyle.disable);
        kmLabel.classList.add(inputStyle.disable);
        fnLabel.classList.add(inputStyle.disable);
        // ogaLabel.classList.add('disable');
        pwdLabel.classList.add(inputStyle.disable);
        genLabel.classList.add(inputStyle.disable);

        this.setState({
          outside_goa_applicant: true,
        });
      } else {
        if (!(laCat.checked || pwdCat.checked || kmCat.checked || fnCat.checked )) {
          kmLabel.classList.remove(inputStyle.disable);
          pwdLabel.classList.remove(inputStyle.disable);
          genLabel.classList.remove(inputStyle.disable);

          if (!genCat.checked) {
            laLabel.classList.remove(inputStyle.disable);
            ogaLabel.classList.remove(inputStyle.disable);
            fnLabel.classList.remove(inputStyle.disable);
          }

          this.setState({
            outside_goa_applicant: false,
          });
        }
      }
    }

    if (name === "kmCat") {
      if (laCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">LA</span> category in order to
              select the <span className="category-name">Kashmiri Migrant</span>{" "}
              category.
            </p>
          </div>
        );
        kmCat.checked = false;
      } else if (pwdCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">PwD</span> category in order to
              select the <span className="category-name">Kashmiri Migrant</span>{" "}
              category.
            </p>
          </div>
        );
        kmCat.checked = false;
      } else if (ogaCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">OGA</span> category in order to
              select the <span className="category-name">Kashmiri Migrant</span>{" "}
              category.
            </p>
          </div>
        );
        kmCat.checked = false;
      } else if (fnCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">FN / PIO</span> category in order
              to select the{" "}
              <span className="category-name">Kashmiri Migrant</span> category.
            </p>
          </div>
        );
        kmCat.checked = false;
      }

      if (kmCat.checked) {
        laLabel.classList.add(inputStyle.disable);
        ogaLabel.classList.add(inputStyle.disable);
        // kmLabel.classList.add('disable');
        fnLabel.classList.add(inputStyle.disable);
        pwdLabel.classList.add(inputStyle.disable);

        this.setState({
          kashmiri_migrant: true,
        });
      } else {
        if (
          !(laCat.checked || ogaCat.checked || pwdCat.checked || fnCat.checked)
        ) {
          kmLabel.classList.remove(inputStyle.disable);
          fnLabel.classList.remove(inputStyle.disable);
          pwdLabel.classList.remove(inputStyle.disable);
          laLabel.classList.remove(inputStyle.disable);
          ogaLabel.classList.remove(inputStyle.disable);
        }

        this.setState({
          kashmiri_migrant: false,
        });

        for (let i = 0; i < this.state.documents_uploaded.length; i++) {
          if (this.state.documents_uploaded[i] === "kashmiri_migrant_proof") {
            this.removeRequestFromPreview("kashmiri_migrant_proof");
          }
        }
      }
    }

    if (name === "fnCat") {
      if (laCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">LA</span> category in order to
              select the <span className="category-name">FN / PIO</span>{" "}
              category.
            </p>
          </div>
        );
        fnCat.checked = false;
      } else if (pwdCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">PwD</span> category in order to
              select the <span className="category-name">FN / PIO</span>{" "}
              category.
            </p>
          </div>
        );
        fnCat.checked = false;
      } else if (ogaCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">OGA</span> category in order to
              select the <span className="category-name">FN / PIO</span>{" "}
              category.
            </p>
          </div>
        );
        fnCat.checked = false;
      } else if (kmCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">KM</span> category in order to
              select the <span className="category-name">FN / PIO</span>{" "}
              category.
            </p>
          </div>
        );
        fnCat.checked = false;
      }

      if (genCat.checked) {
        swal(
          <div className="counselling__modal">
            <h1 className="counselling__title">Attention!</h1>
            <p className="counselling__para">
              Please <span className="warn">uncheck</span>{" "}
              <span className="category-name">General</span> category in order
              to select the <span className="category-name">FN / PIO</span>{" "}
              category.
            </p>
          </div>
        );
        fnCat.checked = false;
      }

      if (fnCat.checked) {
        laLabel.classList.add(inputStyle.disable);
        ogaLabel.classList.add(inputStyle.disable);
        kmLabel.classList.add(inputStyle.disable);
        // fnLabel.classList.add('disable');
        pwdLabel.classList.add(inputStyle.disable);

        genLabel.classList.add(inputStyle.disable);

        this.setState({
          foreign_national: true,
        });
      } else {
        if (
          !(laCat.checked || ogaCat.checked || kmCat.checked || pwdCat.checked)
        ) {
          kmLabel.classList.remove(inputStyle.disable);
          pwdLabel.classList.remove(inputStyle.disable);
        }

        if (!genCat.checked) {
          genLabel.classList.remove(inputStyle.disable);
          laLabel.classList.remove(inputStyle.disable);
          ogaLabel.classList.remove(inputStyle.disable);
        }

        this.setState({
          foreign_national: false,
        });

        if (!gulfCat.checked) {
          for (let i = 0; i < this.state.documents_uploaded.length; i++) {
            if (this.state.documents_uploaded[i] === "foreign_national_proof") {
              this.removeRequestFromPreview("foreign_national_proof");
            }
          }
        }
      }
    }

    if (name === "gulfCat") {
      if (gulfCat.checked) {
        this.setState({
          gulf: true,
        });
      } else {
        this.setState({
          gulf: false,
        });
      }
    }
    if (
      !genCat.checked &&
      !scCat.checked &&
      !stCat.checked &&
      !obcCat.checked &&
      !ffCat.checked &&
      !pwdCat.checked &&
      !cspCat.checked &&
      !esmCat.checked &&
      !laCat.checked &&
      !gnCat.checked &&
      !gnStCat.checked &&
      !gnObcCat.checked &&
      !nriGoCat.checked &&
      !nriNgoCat.checked &&
      !pioNgoCat.checked &&
      !pioGoCat.checked &&
      !ogaCat.checked &&
      !kmCat.checked &&
      !fnCat.checked &&
      !gulfCat.checked
    ) {
      formErrors.category_selected = "Please select at least one category";
    } else {
      formErrors.category_selected = "";
    }

    setTimeout(() => {
      this.checkValidation();

      /* to show/hide upload fields */
      this.showDocumentInputElements();
    }, 100);
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  fetchUploadedDocuments = async (fieldNames) => {
    try {
      const axios = require("axios");
      const cookies = new Cookies();
      let requestOptionsForUpload = {
        headers: { 
          authorization: "token " + cookies.get("token"),
        },
        responseType: "arraybuffer",
      };

      const requests = [];
      // const fieldNames = this.state.documents_uploaded;
      var urlArr = [];

      fieldNames.forEach((file) =>
        requests.push(
          axios.get(
            `${process.env.REACT_APP_API_URL}/admission/application/uploads/${file}`,
            requestOptionsForUpload
          )
        )
      );

      const response = await Promise.all(requests);
      this.setState({
        docsFetched: true,
      });

      for (let i = 0; i < response.length; i++) {
        if (response[i].status === 200) {
          const bufferArray = response[i].data;
          const fileBlob = new Blob([bufferArray], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(fileBlob);

          urlArr.push([fieldNames[i], blobUrl]);
        }
      }

      this.setState({
        documents_uploaded_urls: urlArr,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  async componentDidMount() {
    this.changeStates();
    this.setState({
      categoryListOne: [
        {
          checkbox: true,
          label: "General Category",
          categoryNumber: "1",
          id: "genCat",
          refInput: this.genCatRef,
          refLabel: this.genCatLabelRef,
        },
        {
          checkbox: false,
          subCategoryName: "Caste",
          subItems: [
            {
              label: "Scheduled Caste (SC)",
              categoryNumber: "2",
              name: "reserved_category",
              id: "scCat",
              refInput: this.scCatRef,
              refLabel: this.scCatLabelRef,
            },
            {
              label: "Scheduled Tribe (ST)",
              categoryNumber: "3",
              name: "reserved_category",
              id: "stCat",
              refInput: this.stCatRef,
              refLabel: this.stCatLabelRef,
            },
            {
              label: "Other Backward Classes (OBC)",
              categoryNumber: "4",
              name: "reserved_category",
              id: "obcCat",
              refInput: this.obcCatRef,
              refLabel: this.obcCatLabelRef,
            },
            {
              label: "Clear",
              name: "reserved_category",
              id: "clear-cat-2",
              typeClear: true,
            },
          ],
        },
        {
          checkbox: true,
          label: "Freedom Fighter (FF)",
          categoryNumber: "5",
          id: "ffCat",
          refInput: this.ffCatRef,
          refLabel: this.ffCatLabelRef,
        },
        {
          checkbox: true,
          label: "Person with Disability (PwD)",
          categoryNumber: "6",
          id: "pwdCat",
          refInput: this.pwdCatRef,
          refLabel: this.pwdCatLabelRef,
        },
        {
          checkbox: true,
          label: "Children of Central/State Employees (CSP)",
          categoryNumber: "7",
          id: "cspCat",
          refInput: this.cspCatRef,
          refLabel: this.cspCatLabelRef,
        },
        {
          checkbox: true,
          label: "Ex - Service Men (ESM)",
          categoryNumber: "8",
          id: "esmCat",
          refInput: this.esmCatRef,
          refLabel: this.esmCatLabelRef,
        },
        {
          checkbox: true,
          label: "Local Applicant (LA)",
          categoryNumber: "9",
          id: "laCat",
          refInput: this.laCatRef,
          refLabel: this.laCatLabelRef,
        },
      ],

      categoryListTwo: [
        {
          checkbox: false,
          subCategoryName: "10. Goan Native",
          subItems: [
            {
              label: "Goan Native",
              categoryNumber: false,
              name: "goan-native",
              id: "gnCat",
              refInput: this.gnCatRef,
              refLabel: this.gnCatLabelRef,
            },
            {
              label: "Goan Native - ST",
              categoryNumber: false,
              name: "goan-native",
              id: "gnStCat",
              refInput: this.gnStCatRef,
              refLabel: this.gnStCatLabelRef,
            },
            {
              label: "Goan Native - OBC",
              categoryNumber: false,
              name: "goan-native",
              id: "gnObcCat",
              refInput: this.gnObcCatRef,
              refLabel: this.gnObcCatLabelRef,
            },
            {
              label: "Clear",
              name: "goan-native",
              id: "clear-gn",
              typeClear: true,
              refInput: this.gnClearRef,
            },
          ],
        },
        {
          checkbox: false,
          subCategoryName: "11. Non Resident Indian (NRI)",
          subItems: [
            {
              label: "NRI - Goan Origin (GO)",
              categoryNumber: false,
              name: "nri-cat",
              id: "nriGoCat",
              refInput: this.nriGoCatRef,
              refLabel: this.nriGoCatLabelRef,
            },
            {
              label: "NRI - Not Goan Origin (NGO)",
              categoryNumber: false,
              name: "nri-cat",
              id: "nriNgoCat",
              refInput: this.nriNgoCatRef,
              refLabel: this.nriNgoCatLabelRef,
            },
            {
              label: "PIO / OCI - Goan Origin (GO)",
              categoryNumber: false,
              name: "nri-cat",
              id: "pioGoCat",
              refInput: this.pioGoCatRef,
              refLabel: this.pioGoCatLabelRef,
            },
            {
              label: "PIO / OCI - Not Goan Origin (NGO)",
              categoryNumber: false,
              name: "nri-cat",
              id: "pioNgoCat",
              refInput: this.pioNgoCatRef,
              refLabel: this.pioNgoCatLabelRef,
            },
            {
              label: "Clear",
              name: "nri-cat",
              id: "clear-nri-cat",
              typeClear: true,
            },
          ],
        },
        {
          checkbox: true,
          label: "Outside Goa Applicant (OGA)",
          categoryNumber: "12",
          id: "ogaCat",
          refInput: this.ogaCatRef,
          refLabel: this.ogaCatLabelRef,
        },
        {
          checkbox: true,
          label: "Kashmiri Migrant",
          categoryNumber: "13",
          id: "kmCat",
          refInput: this.kmCatRef,
          refLabel: this.kmCatLabelRef,
        },
        {
          checkbox: true,
          label: "Foreign National (FN) / Person of Indian Origin (PIO)",
          categoryNumber: "14",
          id: "fnCat",
          refInput: this.fnCatRef,
          refLabel: this.fnCatLabelRef,
        },
        {
          checkbox: true,
          label: "Gulf",
          categoryNumber: "15",
          id: "gulfCat",
          refInput: this.gulfCatRef,
          refLabel: this.gulfCatLabelRef,
        },
      ],
    });

    try {
      let myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("authorization", "token " + cookies.get("token"));
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/`,
        requestOptions
      );
      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("isLoggedInToGcet", "false");

        this.setState({
          isAuthenticated: false,
        });
        const authProps = {
          isAuth: this.state.isAuthenticated,
        };
        this.props.checkLoginStatus(authProps);
      }

      let app = data.applicationData;

      this.setState({
        last_step: app.last_step,
        step_one_finished: app.step_one_finished,
        step_two_finished: app.step_two_finished,
        step_three_finished: app.step_three_finished,
        step_four_finished: app.step_four_finished,
        final_submission_done: app.is_application_submitted,
        documents_uploaded:
          app.documents_uploaded === (null || "") ? [] : app.documents_uploaded,
        full_name: app.name,
        nationality: app.nationality,
        postal_address: app.postal_address,
        is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
      });

      let docsUploaded =
        app.documents_uploaded === (null || "") ? [] : app.documents_uploaded;

      this.fetchUploadedDocuments(docsUploaded);

      if (app.step_three_finished) {
        this.setState({
          step_three_submitted_before: true,
          general_category_selected: app.general_category_selected,
          sc_category_selected: app.sc_category_selected,
          st_category_selected: app.st_category_selected,
          obc_category_selected: app.obc_category_selected,
          freedom_fighter: app.freedom_fighter,
          person_with_disability: app.person_with_disability,
          children_of_central_state_employees:
            app.children_of_central_state_employees,
          ex_service_man: app.ex_service_man,
          local_applicant: app.local_applicant,
          goan_native: app.goan_native,
          goan_native_st: app.goan_native_st,
          goan_native_obc: app.goan_native_obc,
          nri_goan_origin: app.nri_goan_origin,
          nri_non_goan_origin: app.nri_non_goan_origin,
          pio_oci_goan_origin: app.pio_oci_goan_origin,
          pio_oci_non_goan_origin: app.pio_oci_non_goan_origin,
          outside_goa_applicant: app.outside_goa_applicant,
          kashmiri_migrant: app.kashmiri_migrant,
          foreign_national: app.foreign_national,
          gulf: app.gulf,
          guardians_name: app.guardians_name,
        //  guardians_name: app.guardians_name,
        });

        this.genCatRef.current.checked = this.state.general_category_selected;
        this.scCatRef.current.checked = this.state.sc_category_selected;
        this.stCatRef.current.checked = this.state.st_category_selected;
        this.obcCatRef.current.checked = this.state.obc_category_selected;
        this.ffCatRef.current.checked = this.state.freedom_fighter;
        this.pwdCatRef.current.checked = this.state.person_with_disability;
        this.cspCatRef.current.checked =
          this.state.children_of_central_state_employees;
        this.esmCatRef.current.checked = this.state.ex_service_man;
        this.laCatRef.current.checked = this.state.local_applicant;
        this.gnCatRef.current.checked = this.state.goan_native;
        this.gnStCatRef.current.checked = this.state.goan_native_st;
        this.gnObcCatRef.current.checked = this.state.goan_native_obc;
        this.nriGoCatRef.current.checked = this.state.nri_goan_origin;
        this.nriNgoCatRef.current.checked = this.state.nri_non_goan_origin;
        this.pioGoCatRef.current.checked = this.state.pio_oci_goan_origin;
        this.pioNgoCatRef.current.checked = this.state.pio_oci_non_goan_origin;
        this.ogaCatRef.current.checked = this.state.outside_goa_applicant;
        this.kmCatRef.current.checked = this.state.kashmiri_migrant;
        this.fnCatRef.current.checked = this.state.foreign_national;
        this.gulfCatRef.current.checked = this.state.gulf;

        /* checking both the declarations */
        this.applicantsDeclarationRef.current.checked = true;
        this.guardiansDeclarationRef.current.checked = true;

        localStorage.setItem("neet_step_one_finished", app.step_one_finished);
        localStorage.setItem("neet_step_two_finished", app.step_two_finished);
        localStorage.setItem(
          "neet_step_three_finished",
          app.step_three_finished
        );
        localStorage.setItem("neet_step_four_finished", app.step_four_finished);
        localStorage.setItem(
          "neet_final_submission_done",
          app.is_application_submitted
        );

        this.showDocumentInputElements();
      }
    } catch (error) {
      console.log("error", error);
    }

    this.checkValidation();
  }

  // To open Document Preview modal on users request
  previewRequestFromPreview = (preUploaded, item) => {
    let previewContainer = this.previewContainerRef.current;
    let previewWindow = this.previewWindowRef.current;
    let pdffile_url;

    if (!preUploaded) {
      let pdffile = item.files[0];
      pdffile_url = URL.createObjectURL(pdffile);
    } else {
      pdffile_url = item;
    }

    previewContainer.style.display = "flex";
    previewWindow.setAttribute("src", pdffile_url);
  };

  closePreview = () => {
    let previewContainer = this.previewContainerRef.current;
    previewContainer.style.display = "none";
  };

  /* Updating document array bcz. Array is used when deleting the file*/
  updateDocumentsArray = (updArr) => {
    this.setState({
      documents_uploaded: updArr,
    });
  };

  render() {
    if (
      this.state.isAuthenticated &&
      this.state.submitted_successfully &&
      !this.state.step_three_submitted_before
    ) {
      return <Redirect to="/form-n/preview" />;
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true &&
      this.state.step_two_finished
    ) {
      return (
        <div className="application-form content-wrapper">
          <div id="category-wrapper"></div>
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Application Form A <span>* Mandatory Fields</span>
                </h1>

                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                      this.state.is_gcet_roll_no_verified,
                  }}
                />

                <div className="message__box">
                  <h3 className="message__title">Important Instructions</h3>
                  <ul className="message__list-container">
                    <li className="message__list-item">
                      APPLICANTS HAVE TO CHOOSE THE CATEGORY CAREFULLY. WE
                      RECOMMEND YOU TO FILL THIS PAGE ON DESKTOP/LAPTOP AS THERE
                      ARE A LOT OF FIELDS TO BE FILLED.
                    </li>
                    <li className="message__list-item">
                      Select the category / categories applicable to you from
                      the list given below.
                    </li>
                    <li className="message__list-item">
                      YOU HAVE TO SCAN AND ATTACH THE SUPPORTING DOCUMENTS
                      BELOW; PLEASE BE CAUTIOUS WHEN YOU ARE DECIDING ON THE
                      CATEGORY / CATEGORIES.
                    </li>
                    <li className="message__list-item">
                      YOU ARE ADVISED TO REFER TO “
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        Common Prospectus for Admission to the First Year of
                        Professional Degree Courses 2023-2024
                      </span>
                      ”
                      <Link
                        to="/degree-courses-pdf"
                        target="_blank"
                        style={{ color: "red" }}
                      >
                        (click here to read)
                      </Link>
                    </li>
                    <li className="message__list-item">
                      <b>
                        EVERYTIME YOU MAKE ANY CHANGES, PLEASE CLICK ON THE SAVE
                        BUTTON.
                      </b>
                    </li>
                    <li className="message__list-item" style={{ color: "red" }}>
                      NB:{" "}
                      <i>
                        <b>Last date</b> for submission of application form is{" "}
                        <b>Friday, 2nd June, 2023 (upto 23:30 hours)</b>
                      </i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="cell large-12" id="form-container">
                <form
                  onSubmit={this.submit_application}
                  className="application-form__form"
                  autoComplete="off"
                >
                  <p className="sub-heading">Select your category</p>

                  <div className="two-div">
                    <div className="category__container">
                      {this.state.categoryListOne.map((item, index) =>
                        item.checkbox ? (
                          <CategoryBox
                            label={item.label}
                            categoryNumber={item.categoryNumber}
                            type="checkbox"
                            name={item.id}
                            id={item.id}
                            onChange={this.handleChange}
                            ref={{
                              refInput: item.refInput,
                              refLabel: item.refLabel,
                            }}
                            key={`${item.label}-${item.id}`}
                          />
                        ) : (
                          <div
                            className="category__sub-category__container caste"
                            key={`${item.subCategoryName}-${index}`}
                          >
                            <div className="category__sub-group-name">
                              <span>{item.subCategoryName}</span>
                            </div>
                            {item.subItems.map((subItem, index) => (
                              <CategoryBox
                                label={subItem.label}
                                categoryNumber={subItem.categoryNumber}
                                type="radio"
                                name={subItem.name}
                                id={subItem.id}
                                onChange={this.handleChange}
                                typeClear={subItem.typeClear}
                                ref={{
                                  refInput: subItem.refInput,
                                  refLabel: subItem.refLabel,
                                }}
                                key={`${subItem.label}-${item.id}`}
                              />
                            ))}
                          </div>
                        )
                      )}
                    </div>

                    <div className="category__container">
                      {this.state.categoryListTwo.map((item, index) =>
                        item.checkbox ? (
                          <CategoryBox
                            label={item.label}
                            categoryNumber={item.categoryNumber}
                            type="checkbox"
                            name={item.id}
                            id={item.id}
                            onChange={this.handleChange}
                            ref={{
                              refInput: item.refInput,
                              refLabel: item.refLabel,
                            }}
                            key={`${item.label}-${item.id}`}
                          />
                        ) : (
                          <div
                            className="category__sub-category__container caste"
                            key={`${item.subCategoryName}-${index}`}
                          >
                            <div className="category__sub-group-name">
                              <span>{item.subCategoryName}</span>
                            </div>
                            {item.subItems.map((subItem) => (
                              <CategoryBox
                                label={subItem.label}
                                categoryNumber={subItem.categoryNumber}
                                type="radio"
                                name={subItem.name}
                                id={subItem.id}
                                onChange={this.handleChange}
                                typeClear={subItem.typeClear}
                                ref={{
                                  refInput: subItem.refInput,
                                  refLabel: subItem.refLabel,
                                }}
                                key={`${subItem.label}-${item.id}`}
                              />
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <hr className="horizontal-rule" />

                  {/* Upload section */}

                  <p className="sub-heading">Upload documents</p>
                  <div
                    className="message__box"
                    style={{ marginBottom: "40px" }}
                  >
                    <p>
                      If there are multiple documents to be submitted for one
                      field, make single pdf with multiple pages.
                      <br />
                      For example, leaving certificate might have multiple
                      documents, make 1 pdf with 1 document on each page.
                      <br />
                      Similarly, parents and applicants passport must be put on
                      1 pdf on different pages.
                    </p>

                    <br />
                    <p style={{ color: "red", fontWeight: "500" }}>
                      Please do not click on <b>SAVE</b>, <b>NEXT</b> or{" "}
                      <b>PREVIOUS</b> buttons or <b>refresh the page</b> if you
                      see 'uploading file...' in file upload section.
                    </p>
                  </div>

                  <p
                    className={!this.state.docsFetched ? "button loading" : ""}
                    style={{ background: "red" }}
                  >
                    {!this.state.docsFetched && "loading documents..."}
                  </p>

                  <div className="three-div">
                    {this.state.documentNamesArr.map(
                      (item, index) =>
                        item.show && (
                          <div
                            className={inputStyle["input-field-container"]}
                            key={`${item.id}-${index}`}
                          >
                            <label
                              htmlFor={item.id}
                              className={`${inputStyle.label} ${inputStyle.optional}`}
                            >
                              {item.name}
                            </label>
                            <DocumentUpload
                              target={item.id}
                              updDocsArr={this.updateDocumentsArray.bind(this)}
                              docsArr={this.state.documents_uploaded}
                              docsUrlArr={this.state.documents_uploaded_urls}
                              docsFetched={this.state.docsFetched}
                              sendPreviewRequestToCategory={this.previewRequestFromPreview.bind(
                                this
                              )}
                              name={item.name}
                            />
                          </div>
                        )
                    )}
                  </div>

                  <div
                    className="message__box"
                    style={{ marginBottom: "30px", marginTop: "30px" }}
                  >
                    <h3 className="message__title">
                      DECLARATION BY THE APPLICANT{" "}
                    </h3>
                    <div
                      className="input-field-container"
                      style={{ marginBottom: "0" }}
                    >
                      <p className="justify">
                        I hereby declare that I, <b>{this.state.full_name}</b>,{" "}
                        am{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            textTransform: "uppercase",
                          }}
                        >
                          {this.state.nationality}
                        </span>{" "}
                        and state that I have read and accepted the provisions
                        of the Prospectus and have enclosed the certified copies
                        of all the certificates in proper order as required and
                        submitted the application complete in all respects. In
                        the event of my application found to be deficient or
                        incomplete, and rejected by Admitting Authority, I shall
                        be held responsible for the same. I hereby declare that
                        the particulars furnished in the application are true,
                        complete, and correct to the best of my knowledge and
                        belief. I am fully aware that in the event of any
                        information being found false or incorrect or ineligible
                        being detected before or after the admission,
                        appropriate action as deemed fit by the Competent
                        Authority can be taken against me.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="applicants_declaration"
                          name="applicants_declaration"
                          style={{ width: "30px", height: "30px" }}
                          required
                          ref={this.applicantsDeclarationRef}
                        />
                        <label
                          htmlFor="applicants_declaration"
                          style={{ paddingTop: "4px", paddingLeft: "10px" }}
                        >
                          I Agree
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className="message__box"
                    style={{ marginBottom: "30px", marginTop: "30px" }}
                  >
                    <h3 className="message__title">
                      DECLARATION BY PARENT / GUARDIAN OF THE APPLICANT
                    </h3>
                    <div
                      className="input-field-container"
                      style={{ marginBottom: "0" }}
                    >
                      <p className="justify">
                        I, Shri/Smt.{" "}
                        <input
                          type="text"
                          className="declaration-input"
                          placeholder="Enter Father/Mother/Guardian name"
                          value={this.state.guardians_name}
                          id="guardians_name"
                          name="guardians_name"
                          onChange={this.handleChange}
                          required
                        />
                        , Father/Mother/Guardian of Mr./Miss.{" "}
                        <b>{this.state.full_name}</b>, resident of{" "}
                        <b>{this.state.postal_address}</b> hereby declare that I
                        have read and accepted the provisions of the prospectus
                        and the particulars furnished in the application are
                        correct to the best of my knowledge and belief. I
                        declare that I shall be held responsible for timely
                        payment of all fees, rent, and other charges in respect
                        of my son/daughter/ward during the period of his/her
                        studies in the College. I hereby declare that the
                        Institute will not in any way be held responsible for
                        accidents/injuries caused to my ward during the Classes,
                        Practicals, Inplant Training in Industries, Educational
                        Tours, Sports activity, etc.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="guardians_declaration"
                          name="guardians_declaration"
                          style={{ width: "30px", height: "30px" }}
                          required
                          ref={this.guardiansDeclarationRef}
                        />
                        <label
                          htmlFor="guardians_declaration"
                          style={{ paddingTop: "4px", paddingLeft: "10px" }}
                        >
                          I Agree
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Submit section */}

                  <div className="pre-footer">
                    <p
                      id="backend-error"
                      className={`${
                        this.state.submitted_successfully
                          ? "success"
                          : "failure"
                      } ${this.state.showErrorBox ? "show" : ""}`}
                      dangerouslySetInnerHTML={this.addBackendError()}
                    ></p>
                    <div className="button-grid">
                      <div className="pre-footer__navigator">
                        <Link
                          to="/form-n/academic-details"
                          className="button button--grey prev"
                          style={{ marginRight: "30px" }}
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M19.017 22.777C19.623 22.443 20 21.791 20 21.074L20 2.92604C20 2.20904 19.623 1.55704 19.017 1.22304C18.439 0.905038 17.76 0.927038 17.202 1.28104L2.887 10.355C2.331 10.706 2 11.321 2 12C2 12.679 2.331 13.294 2.886 13.646L17.201 22.72C17.76 23.073 18.439 23.095 19.017 22.777Z" />
                          </svg>
                          PREVIOUS
                        </Link>

                        <Link
                          to="/form-n/preview"
                          className="button button--grey next"
                          style={{
                            display: `${
                              this.state.step_three_finished
                                ? "inline-block"
                                : "none"
                            }`,
                          }}
                        >
                          NEXT
                          <svg viewBox="0 0 24 24">
                            <path d="M4.983 1.22296C4.377 1.55696 4 2.20896 4 2.92596L4 21.074C4 21.791 4.377 22.443 4.983 22.777C5.561 23.095 6.24 23.073 6.798 22.719L21.113 13.645C21.669 13.294 22 12.679 22 12C22 11.321 21.669 10.706 21.114 10.354L6.799 1.27996C6.24 0.926963 5.561 0.904961 4.983 1.22296Z" />
                          </svg>
                        </Link>
                      </div>

                      <button
                        type="submit"
                        className={`button ${
                          this.state.loading_button ? "loading" : ""
                        }`}
                        id="submit-button"
                      >
                        SAVE AND PROCEED
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="frame-container dummypreview"
            ref={this.previewContainerRef}
          >
            <div className="close-frame button" onClick={this.closePreview}>
              Close
            </div>
            <iframe
              id="preview_window"
              ref={this.previewWindowRef}
              frameBorder="0"
              title="preview of document"
            ></iframe>
          </div>
          <ScrollToTop />
        </div>
      );
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true
    ) {
      return <Redirect to="/form-n/academic-details" />;
    } else if (this.state.isAuthenticated && this.state.final_submission_done) {
      return <Redirect to="/form-n/profile" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}
