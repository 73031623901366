import React, { Component } from "react";
import swal from "@sweetalert/with-react";
import style from "../Input/input.module.scss";
import Cookies from "universal-cookie";
// the input style of this component is imported in App.scss. I was time bound so could not use CSS modules

export class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file_uploaded: false,
      inputRequired: false,
      filePath: "",
      fileDeleted: false,

      formErrors: {
        sizeError: "",
        typeError: "",
      },
    };

    this.previewRef = React.createRef();
    this.inputDocumentRef = React.createRef();
    this.sizeRef = React.createRef();
    this.fileTypeRef = React.createRef();
    this.fileNameRef = React.createRef();
    this.errorMessageRef = React.createRef();
    this.pdfIconRef = React.createRef();
    this.deleteRef = React.createRef();
    this.detailContainerRef = React.createRef();
  }

  clearPreviewBox() {
    let deleteRef = this.deleteRef.current;
    let doc = this.inputDocumentRef.current;
    let previewBox = this.previewRef.current;
    let errorBox = this.errorMessageRef.current;

    doc.style.display = "block";
    doc.value = "";
    previewBox.style.display = "none";
    previewBox.style.borderColor = "#dfdfdf";
    errorBox.style.display = "none";
    errorBox.innerHTML = "";
    errorBox.classList.remove("box");
    deleteRef.classList.remove("loading");
  }

  // functions
  removeDocument = async () => {
    /* to check whether the file is already in database*/
    let data = this.props.target;
    let selectedElementExists = false;
    let documentList = this.props.docsArr;

    // to set required to true if any required document is removed
    let isInputRequired = false;

    documentList.length > 0 &&
      documentList.forEach((document_name) => {
        if (document_name === data) {
          selectedElementExists = true;
        }
      });

    // refs
    let deleteRef = this.deleteRef.current;

    if (selectedElementExists) {
      deleteRef.classList.add("loading");
      var axios = require("axios");

      let documentList = this.props.docsArr;
      let updatedDocumentList = [];

      const cookies = new Cookies();

      var config = {
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/admission/application/uploads/${data}`,
        headers: {
          authorization: "token " + cookies.get("token"),
        },
      };
      await axios(config)
        .then((response) => {
          if (
            JSON.stringify(response.status) >= 200 &&
            JSON.stringify(response.status) < 300
          ) {
            this.setState({
              fileDeleted: true,
            });

            /* to remove the element id from the array */
            updatedDocumentList = documentList.filter(
              (value) => value !== data
            );

            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title">Successful</h1>
                <p className="counselling__para">
                  File has been deleted successfully.
                </p>
              </div>
            );

            deleteRef.classList.remove("loading");
          } else {
            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title">Error</h1>
                <p className="counselling__para">
                  File could not be deleted. Please try again.
                </p>
              </div>
            );
            deleteRef.classList.remove("loading");
          }
        })
        .catch(function (error) {
          console.log(error);
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Error</h1>
              <p className="counselling__para">
                The file could not be deleted. Please try again.
              </p>
            </div>
          );
          deleteRef.classList.remove("loading");
        });
      this.props.updDocsArr(updatedDocumentList);

      if (this.state.fileDeleted) {
        this.clearPreviewBox();
      }
    } else {
      this.clearPreviewBox();
    }
  };

  filePath = () => {
    let docsUrlArr = this.props.docsUrlArr;
    for (let i = 0; i < docsUrlArr.length; i++) {
      if (docsUrlArr[i][0] === this.props.target) {
        this.setState({
          filePath: docsUrlArr[i][1],
        });
      }
    }
  };

  // filepath is the path of the pre-uploaded file. If the file is not pre-uploaded, send input el (doc) reference.
  showPreview = async () => {
    await this.filePath();
    if (this.state.filePath !== "") {
      this.props.sendPreviewRequestToCategory(true, this.state.filePath);
    } else {
      let doc = this.inputDocumentRef.current;
      this.props.sendPreviewRequestToCategory(false, doc);
    }
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value, id } = event.target;
    let formErrors = this.state.formErrors;

    let label = this.props.name;

    this.setState({
      [name]: value,
    });

    // reference to various elements
    let doc = this.inputDocumentRef.current;
    let fileSize = this.sizeRef.current;
    let fileType = this.fileTypeRef.current;
    let fileName = this.fileNameRef.current;
    let previewBox = this.previewRef.current;
    let errorBox = this.errorMessageRef.current;

    /* Feeding data to preview box*/
    if (value.length > 0) {
      let size = doc.files[0].size;
      if (size > 1048576) {
        fileSize.innerText = (size / 1048576).toFixed(2) + " mb";
      } else if (size <= 1048576) {
        fileSize.innerText = (size / 1024).toFixed(2) + " kb";
      }
      doc.style.display = "none";
      previewBox.style.display = "flex";

      fileName.innerText = doc.files[0].name;
      fileType.innerText = doc.files[0].type;
    }

    /*if error is there, this will be true*/
    let error_is_there = false;

    /* validating the uploaded document*/

    let validate = () => {
      let size = doc.files[0].size;
      let type = doc.files[0].type;
      let sizeError = formErrors.sizeError;
      let typeError = formErrors.typeError;
      sizeError =
        size > 1048576 ? "The file size should be less then 1 MB" : "";
      typeError =
        type !== "application/pdf" ? "The file type should be PDF" : "";

      if (sizeError.length > 0 || typeError.length > 0) {
        errorBox.style.display = "block";
        previewBox.style.borderColor = "red";
        previewBox.style.backgroundColor = "#f9dede";
        errorBox.classList.add("box");
        error_is_there = true;
      } else {
        errorBox.style.display = "none";
        previewBox.style.borderColor = "#2fb60d";
        previewBox.style.backgroundColor = "#e7f4e4";
        error_is_there = false;
      }
      errorBox.innerHTML = `${sizeError} <span style="display:block; margin-top:2px;">${typeError}</span>`;
    };

    // calling validate function
    validate();

    // Upload the file to the server if there are no errors
    if (!error_is_there) {
      var formdata = new FormData();
      formdata.append(name, doc.files[0], doc.files[0].name);

      let pdfIcon = this.pdfIconRef.current;
      pdfIcon.classList.add("loading");

      let documentList = this.props.docsArr;
      
      var myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("authorization", "token " + cookies.get("token"));
      
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: myHeaders
        
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/uploads/${id}`,
        requestOptions
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              file_uploaded: true,
            });

            // update the document list
            documentList.push(id);
          } else {
            this.setState({
              file_uploaded: false,
            });
          }
          return response.text();
        })
        .then((result) => {
          pdfIcon.classList.remove("loading");
          if (this.state.file_uploaded) {
            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title">Successful</h1>
                <p className="counselling__para">
                  <b>{label}</b> has been uploaded successfully.
                </p>
              </div>
            );
          } else {
            swal(
              <div className="counselling__modal">
                <h1 className="counselling__title" style={{ color: "red" }}>
                  Error
                </h1>
                <p className="counselling__para">
                  {JSON.parse(result).message}
                </p>
              </div>
            );

            /* remove preview box if upload failed */
            previewBox.style.display = "none";
            doc.style.display = "block";
            doc.value = "";
          }
        })
        .catch((error) => {
          console.log("error", error);
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title" style={{ color: "red" }}>
                Error
              </h1>
              <p className="counselling__para">File could not be uploaded.</p>
            </div>
          );

          /* remove preview box if upload failed */
          previewBox.style.display = "none";
          doc.style.display = "block";
          doc.value = "";
        });

      this.props.updDocsArr(documentList);
    }
  };

  showUploadedDocuments = () => {
    let docsUrlArr = this.props.docsUrlArr;
    let docsArr = this.props.docsArr;
    if (docsUrlArr.length > 0) {
      for (let i = 0; i < docsUrlArr.length; i++) {
        if (
          docsUrlArr[i][0] === this.props.target &&
          docsArr[i] === this.props.target
        ) {
          // various refs to be used
          let doc = this.inputDocumentRef.current;
          let fileType = this.fileTypeRef.current;
          let fileName = this.fileNameRef.current;
          let previewBox = this.previewRef.current;

          doc.style.display = "none";
          fileName.innerText = `${docsUrlArr[i]}`;
          fileType.innerText = "pdf";

          previewBox.style.display = "flex";
          previewBox.style.borderColor = "#2fb60d";
          previewBox.style.backgroundColor = "#e7f4e4";
        }
      }
    }
  };

  componentDidUpdate() {
    if (this.props.docsFetched) {
      this.showUploadedDocuments();
    }
  }

  render() {
    return (
      <>
        <input
          type="file"
          onChange={this.handleChange}
          className={`${style["input-style"]} ${style["upload-field"]} upload-field category__documents-upload`}
          id={this.props.target}
          name={this.props.target}
          ref={this.inputDocumentRef}
          required={this.props.required || this.state.inputRequired}
        />
        <p className="error_message upload" ref={this.errorMessageRef}></p>

        <div className="document__preview" ref={this.previewRef}>
          <div
            className="document__detail-container"
            ref={this.detailContainerRef}
          >
            <div
              className="document__pdf-icon"
              ref={this.pdfIconRef}
              onClick={this.showPreview}
            ></div>
            <div>
              <p className="document__name" ref={this.fileNameRef}></p>
              <p>
                <span className="document__type" ref={this.fileTypeRef}></span>{" "}
                <span className="document__size" ref={this.sizeRef}></span>
              </p>
            </div>
          </div>
          <div
            className="document__delete"
            onClick={this.removeDocument}
            ref={this.deleteRef}
          >
            <svg
              viewBox="0 0 20 20"
              className="document__delete__icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.3335 1.66666L7.50016 2.49999H3.3335V4.16666H16.6668V2.49999H12.5002L11.6668 1.66666H8.3335ZM4.16683 5.83332V16.6667C4.16683 17.5833 4.91683 18.3333 5.8335 18.3333H14.1668C15.0835 18.3333 15.8335 17.5833 15.8335 16.6667V5.83332H4.16683ZM7.84196 8.33332L10.0002 10.4915L12.1584 8.33332L13.3335 9.50845L11.1753 11.6667L13.3335 13.8249L12.1584 15L10.0002 12.8418L7.84196 15L6.66683 13.8249L8.82503 11.6667L6.66683 9.50845L7.84196 8.33332Z"
                fill="#EB5757"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}
