import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./product.css";
import "./App.scss";

// components
import { Header } from "./components/Header/index";
import { Footer } from "./components/Footer/index";

// TEXT PAGES
import { HomeFormN } from "./pages/static/neet/HomeFormN";
import { InstructionsFormN } from "./pages/static/neet/InstructionsFormN";
import { FAQ } from "./pages/static/FAQ/index";
import { PdfPage } from "./pages/static/PdfPage.jsx";
import { PageNotFound } from "./pages/static/PageNotFound.jsx";
import { PaymentFailed } from "./pages/static/PaymentFailed.jsx";
import { Success } from "./pages/static/Success.jsx";

// AUTHENTICATION PAGES
import {
  Login,
  Register,
  ResendVerificationEmail,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  AccountVerified,
} from "./pages/auth/index";

// PROFILE PAGE
import { Profile } from "./pages/Profile/index";

// NEET APPLICATIONS
import {
  NProfile,
  NBasicDetails,
  NAcademicDetails,
  NCategory,
  // NCourseChoice,
  NPreview,
} from "./pages/neet-based-application/index";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      is_submitted: "",
      is_paid: "",
      is_seat_alloted: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      orderID: "",
      final_submission_done: false,
    };
  }

  isAuthenticated = (auth) => {
    this.setState({
      isAuthenticated: auth.isAuth,
      is_submitted: auth.is_submitted,
      is_paid: auth.is_paid,
      is_seat_alloted: auth.is_seat_alloted,
      candidate_name: auth.candidate_name,
      candidate_email: auth.candidate_email,
      candidate_phone: auth.candidate_phone,
      orderID: auth.orderID,
    });
  };

  final_submission_done = (data) => {
    this.setState({
      final_submission_done: data.final_submission_done,
    });
  };

  render() {
    const login_details = {
      // final_submission_done: true,
      // isAuthenticated: true,
      final_submission_done: this.state.final_submission_done,
      isAuthenticated: this.state.isAuthenticated,
    };

    return (
      <div>
        <Router>
          <Header
            auth={login_details}
            checkLoginStatus={this.isAuthenticated.bind(this)}
          />

          <div className="main-footer-wrapper">
            <Switch>
              {/* TEXT PAGES */}
              <HomeFormN exact path="/" auth={login_details} />
              {/*<InstructionsFormN path="/instructions" auth={login_details} />
              <Route exact path="/faq" component={FAQ} />
              <Route path="/degree-courses-pdf" component={PdfPage} />
              <Route path="/payment-failed" */}

              {/* AUTHENTICATION PAGES */}
             {/*} <Login
                exact
                path="/login"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />
              <Register exact path="/register" />
              <ResendVerificationEmail exact path="/resend" />
              <ForgotPassword exact path="/forgot" />
              <ResetPassword exact path="/reset" />
              <VerifyEmail exact path="/verify-email" />
              <AccountVerified exact path="/account-verified" />
    */}
              {/* NEET BASED APPLICATION
              <NProfile
                exact
                path="/form-n/profile"
                auth={login_details}
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />

              <NBasicDetails
                exact
                path="/form-n/basic-details"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />

              <NAcademicDetails
                exact
                path="/form-n/academic-details"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />

              <NCategory
                exact
                path="/form-n/category"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />

 */}             {/* <NCourseChoice
                exact
                path="/form-n/choice-form"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              /> */}

    {/*}          <NPreview
                exact
                path="/form-n/preview"
                checkLoginStatus={this.isAuthenticated.bind(this)}
              />*/}
              <Route path="*" component={PageNotFound} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
