import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

// components
import { Input, SubjectCheckbox } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";
import swal from "@sweetalert/with-react";

// style
import inputStyle from "../../components/Input/input.module.scss";
import Cookies from "universal-cookie";

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class NBasicDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      componentDidMount: "",
      submitted_successfully: false,
      showErrorBox: false,
      last_step: "",
      final_submission_done: false,

      photo_preview: "",
      loading_button: "",
      loading_button_otp: "",
      loading_button_verify: "",
      loading_button_gcet_submit: "",
      backendError: "",
      otp_sent: false,
      otp_verified: false,
      order_id_created: false,
      message_popped: false,
      applied_for_gcet: false,
      // to be changed
      step_one_finished: false,
      step_two_finished: false,
      step_three_finished: false,
      step_four_finished: false,
      step_one_submitted_before: false,
      steps: "",
      full_name: "",
      lockName: false,
      date_of_birth: "",
      gender: "",
      email: "",
      mobile: "",
      alternate_mobile_number: "",
      aadhar: "",
      postal_address: "",
      form_a_reg_no: "",
      photo: "",
      nationality: "indian",
      gcet_roll_no: "",
      is_gcet_roll_no_verified: false,
      is_applying_for_neet: true,
      inputValue: "",

      // submitButtonText: "Save and proceed for payment",
      submitButtonText: "Save and proceed",

      formErrors: {
        full_name: "",
        date_of_birth: "",
        gender: "",
        mobile: "",
        aadhar: "",
        photo_size: "",
        photo_type: "",
      },
    };

    this.imageRef = React.createRef();
    this.previewRef = React.createRef();
    this.genderMaleRef = React.createRef();
    this.genderFemaleRef = React.createRef();
    this.genderOtherRef = React.createRef();
    //this.dateOfBirthRef = React.createRef();
    this.appliedForGCETRef = React.createRef();
    this.notAppliedForGCETRef = React.createRef();
    this.fullNameRef = React.createRef();
  }

  checkValidation = () => {
    if (formValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    }
  };

  submit_application = (event) => {
    event.preventDefault();
    this.setState({
      loading_button: true,
    });

    if (formValid(this.state.formErrors)) {
      var myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("authorization", "token " + cookies.get("token"));

      let photo = this.imageRef.current;
      var formdata = new FormData();
      formdata.append("name", this.state.full_name.toUpperCase());
      formdata.append("date_of_birth", this.state.date_of_birth);
      formdata.append("gender", this.state.gender);
      formdata.append("aadhar_card_number", this.state.aadhar); /*changes*/
      formdata.append("is_applying_for_neet",this.state.is_applying_for_neet)
      if (photo.files.length > 0) {
        formdata.append("photo", photo.files[0]);
      }
      formdata.append("mobile_number", this.state.mobile);
      formdata.append(
        "alternate_mobile_number",
        this.state.alternate_mobile_number
      );
      formdata.append("postal_address", this.state.postal_address);
      formdata.append("nationality", this.state.nationality);

      if (!this.state.order_id_created) {
        formdata.append("form_a_reg_no", this.state.form_a_reg_no);
      }

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/step1`,
        requestOptions
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              //proceed: true,
              step_one_finished: true,
              submitted_successfully: true,
              showErrorBox: true,
            });
          } else if (response.status === 401) {
            localStorage.setItem("isLoggedInToGcet", "false");

            this.setState({
              isAuthenticated: false,
            });
            const authProps = {
              isAuth: this.state.isAuthenticated,
            };
            this.props.checkLoginStatus(authProps);
          } else {
            this.setState({
              submitted_successfully: false,
              showErrorBox: true,
            });
          }
          
          return response.text();
        })
        .then((result) => {
          this.setState({
            showErrorBox: true,
            // submitted_successfully: false,
            backendError: JSON.parse(result).message,
            loading_button: false,
          });
          
        })
        .catch((error) => {
          this.setState({
            showErrorBox: true,
            submitted_successfully: false,
            backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
            loading_button: false,
          });
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        showErrorBox: true,
        backendError: `There are errors in the form. <br /> <span>${error}</span>`,
        loading_button: false,
        submitted_successfully: false,
      });
    }
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value, id } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "full_name") {
      formErrors.full_name =
        value.length > 4 ? "" : "Please enter your full name";
    }

    let photo = this.imageRef.current;
    if (name === "photo") {
      formErrors.photo_size =
        photo.files[0].size < 210000
          ? ""
          : "The file size should be less than 200kb";
      let photo_type = photo.files[0].type;
      formErrors.photo_type =
        photo_type === "image/png" ||
        photo_type === "image/jpeg" ||
        photo_type === "image/jpg"
          ? ""
          : `You uploaded ${photo_type}. \nOnly jpeg, jpg and png files are allowed`;

      if (
        formErrors.photo_size.length === 0 &&
        formErrors.photo_type.length === 0
      ) {
        this.setState({
          photo_preview: true,
        });

        let reader = new FileReader();
        let photo_preview = this.previewRef.current;
        reader.onload = function () {
          photo_preview.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }

    if (name === "applied_for_gcet") {
      if (this.appliedForGCETRef.current.checked) {
        this.setState({
          applied_for_gcet: true,
        });
      } else if (this.notAppliedForGCETRef.current.checked) {
        this.setState({
          applied_for_gcet: false,
        });
      }
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  componentDidMount() {
    let myHeaders = new Headers();
    const cookies = new Cookies();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "token " + cookies.get("token"));

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/application/`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");

          this.setState({
            isAuthenticated: false,
          });
          const authProps = {
            isAuth: this.state.isAuthenticated,
          };
          this.props.checkLoginStatus(authProps);
        }
        return response.text();
      })
      .then((result) => {
        let app = JSON.parse(result).applicationData;

        this.setState({
          email: app.email_id,
          step_one_finished: app.step_one_finished,
          step_two_finished: app.step_two_finished,
          step_three_finished: app.step_three_finished,
          step_four_finished: app.step_four_finished,
          final_submission_done: app.is_application_submitted,
          message_popped: app.is_order_generated,
          order_id_created: app.is_order_generated,
          is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
        //  applying_for_neet: app.applying_for_neet,
          gcet_roll_no: app.gcet_roll_no,
        });

        if (this.state.is_gcet_roll_no_verified) {
          this.setState({
            full_name: app.name,
            lockName: true,
          })
        }

        if (app.step_one_finished) {
          if (app.gender === "male") {
            this.genderMaleRef.current.checked = true;
            this.setState({
              gender: "male",
            });
          } else if (app.gender === "female") {
            this.genderFemaleRef.current.checked = true;
            this.setState({
              gender: "female",
            });
          } else if (app.gender === "other") {
            this.genderOtherRef.current.checked = true;
            this.setState({
              gender: "other",
            });
          }

          let photo_preview = this.previewRef.current;
          photo_preview.src = app.photo;
          this.imageRef.current.required = false;

          /* making aadhar non editable */
          // document.getElementById("aadhar-container").classList.add("lock");
          // document.getElementById("aadhar").setAttribute("readOnly", true);

          this.setState({
            step_one_submitted_before: true,
            photo_preview: true,
            full_name: app.name,
            date_of_birth: app.date_of_birth.split("-").reverse().join("-"),
            mobile: app.mobile_number,
            alternate_mobile_number: app.alternate_mobile_number,
            aadhar: app.aadhar_card_number,
            componentDidMount: true,
            postal_address: app.postal_address,
            nationality: app.nationality,
            form_a_reg_no: app.form_a_reg_no,
            submitButtonText: "save and proceed",
          });

          localStorage.setItem("neet_step_one_finished", app.step_one_finished);
          localStorage.setItem("neet_step_two_finished", app.step_two_finished);
          localStorage.setItem(
            "neet_step_three_finished",
            app.step_three_finished
          );
          localStorage.setItem(
            "neet_step_four_finished",
            app.step_four_finished
          );
          localStorage.setItem(
            "neet_final_submission_done",
            app.is_application_submitted
          );

          //this.dateOfBirthRef.current.value = app.state.date_of_birth;
        }
      })
      .catch((error) => console.log("error", error));
  }

  create_order_id = (event) => {
    event.preventDefault();
    this.setState({
      loading_button_gcet_submit: true,
    });

    var myHeaders = new Headers();
    const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/application/create-order`,
      requestOptions
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            order_id_created: true,
          });
        } else {
          this.setState({
            order_id_created: false,
          });
        }
        return response.text();
      })
      .then((result) => {
        // let details = JSON.parse(result).data;

        if (this.state.order_id_created) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Successful</h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );

          this.setState({
            message_popped: true,
          });
        } else {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title" style={{ color: "red" }}>
                Error
              </h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );
        }

        this.setState({
          loading_button_gcet_submit: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({
          loading_button_gcet_submit: false,
        });
      });
  };

  send_verification_email = (event) => {
    event.preventDefault();
    this.setState({
      loading_button_otp: true,
    });

    var myHeaders = new Headers();
    const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));

    var raw = JSON.stringify({ rollNo: this.state.gcet_roll_no });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/admission/send-otp`, requestOptions)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            otp_sent: true,
          });
          localStorage.setItem("gcet_roll_no", this.state.gcet_roll_no);
        } else {
          this.setState({
            otp_sent: false,
          });
        }
        return response.text();
      })
      .then((result) => {
        let details = JSON.parse(result).data;
        console.log(details);

        if (this.state.otp_sent) {
          // this.setState({
          //   full_name: details.name,
          // });
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Successful</h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );
        } else {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title" style={{ color: "red" }}>
                Error
              </h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );
        }

        this.setState({
          loading_button_otp: false,
        });
      })
      .catch((error) => console.log("error", error));
  };

  verify_otp = (event) => {
    event.preventDefault();
    this.setState({
      loading_button_verify: true,
    });

    var myHeaders = new Headers();
    const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));

    var raw = JSON.stringify({ otp: this.state.otp });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/verify-otp`,
      requestOptions
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            is_gcet_roll_no_verified: true,
          //  applying_for_neet: false,
            
          });
        } else {
          this.setState({
            is_gcet_roll_no_verified: false,
          //  applying_for_neet: true,
          });
        }
        return response.text();
      })
      .then((result) => {
        // let details = JSON.parse(result).data;

        this.setState({
          loading_button_verify: false,
        });

        if (this.state.is_gcet_roll_no_verified) {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title">Successful</h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );
          this.setState({
            message_popped: true,
          });

         // this.fetchName();
        } else {
          swal(
            <div className="counselling__modal">
              <h1 className="counselling__title" style={{ color: "red" }}>
                Error
              </h1>
              <p className="counselling__para">{JSON.parse(result).message}</p>
            </div>
          );
        }
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    if (
      this.state.isAuthenticated &&
      this.state.submitted_successfully &&
      !this.state.step_one_submitted_before
    ) {
      return <Redirect to="/form-n/academic-details" />;
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true
    ) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Application Form A <span>* Mandatory Fields</span>
                </h1>

                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                      this.state.is_gcet_roll_no_verified,
                  }}
                />
              </div>

              <div
                className="cell large-12"
                style={{
                  display: `${
                    (this.state.order_id_created &&
                      this.state.message_popped) ||
                    this.state.is_gcet_roll_no_verified
                      ? "none"
                      : "block"
                  }`,
                }}
              >
                <p className="sub-heading" style={{ marginBottom: "15px" }}>
                  Did you register for GCET 2023? *<br />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      color: "red",
                      lineHeight: "normal"
                    }}
                  >
                    
                  </span>
                </p>

                <div
                  className={inputStyle["subject__container"]}
                  style={{
                    marginTop: "0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    minHeight: "0",
                  }}
                >
                  <p className={inputStyle["error_message"]} id="subject-error">
                    {this.state.formErrors.exam_selection}
                  </p>

                  <SubjectCheckbox
                    label="YES"
                    name="applied_for_gcet"
                    id="applied_for_gcet_yes"
                    onChange={this.handleChange}
                    required
                    ref={this.appliedForGCETRef}
                  />

                  <div style={{ width: "20px" }}></div>

                  <SubjectCheckbox
                    label="NO"
                    name="applied_for_gcet"
                    id="applied_for_gcet_no"
                    onChange={this.handleChange}
                    required
                    ref={this.notAppliedForGCETRef}
                  />
                </div>

                <form
                  onSubmit={this.create_order_id}
                  style={{
                    display: `${
                      this.state.applied_for_gcet ? "none" : "block"
                    }`,
                  }}
                >
                  <button
                    type="submit"
                    className={`button ${
                      this.state.loading_button_gcet_submit ? "loading" : ""
                    }`}
                    style={{ marginBottom: "20px" }}
                  >
                    Submit
                  </button>
                </form>

                <div
                  className="four-div"
                  style={{
                    display: `${this.state.applied_for_gcet ? "grid" : "none"}`,
                  }}
                >
                  <form
                    className="application-form__form"
                    onSubmit={this.send_verification_email}
                  >
                    <Input
                      label="GCET 2023 Roll Number"
                      type="number"
                      value={this.state.gcet_roll_no}
                      name="gcet_roll_no"
                      id="gcet_roll_no"
                      onChange={this.handleChange}
                      min="0"
                      max="25999"
                      step="1"
                      required
                    />
                    <button
                      type="submit"
                      className={`button ${
                        this.state.loading_button_otp ? "loading" : ""
                      }`}
                      style={{ marginBottom: "20px" }}
                    >
                      Receive OTP
                    </button>
                  </form>
                  <form
                    className="application-form__form"
                    onSubmit={this.verify_otp}
                  >
                    <Input
                      label="OTP"
                      type="number"
                      value={this.state.otp}
                      name="otp"
                      id="otp"
                      onChange={this.handleChange}
                      min="0"
                      required
                    />
                    <button
                      className={`button ${
                        this.state.loading_button_verify ? "loading" : ""
                      }`}
                      type="submit"
                      style={{ marginBottom: "20px" }}
                    >
                      Verify
                    </button>
                  </form>
                </div>
              </div>

              <div
                className="cell large-12"
                id="form-container"
                style={{
                  display: `${
                    (this.state.order_id_created &&
                      this.state.message_popped) ||
                    this.state.is_gcet_roll_no_verified
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                <form
                  onSubmit={this.submit_application}
                  className="application-form__form"
                  autoComplete="off"
                >
                  <p className="sub-heading">Personal Details</p>

                  <Input
                    label="Name"
                    subLabel="as it appears in SSCE certificate"
                    type="text"
                    value={this.state.full_name}
                    name="full_name"
                    id="full_name"
                    onChange={this.handleChange}
                    required
                    ref={this.fullNameRef}
                    lock={this.state.lockName}
                  />

                  <div className="three-div">
                    <Input
                      label="Date of Birth"
                      subLabel="as per SSCE certificate"
                      type="date"
                      value={this.state.date_of_birth}
                      name="date_of_birth"
                      id="date_of_birth"
                      onChange={this.handleChange}
                      required
                      placeholder="yyyy-mm-dd"
                    />

                    <div className={inputStyle["input-field-container"]}>
                      <label htmlFor="gender" className={inputStyle.label}>
                        Gender:
                      </label>
                      <div className={inputStyle["gender-container"]}>
                        <div>
                          <input
                            type="radio"
                            name="gender"
                            id="male"
                            value="male"
                            onChange={this.handleChange}
                            autoComplete="new-off"
                            required
                            ref={this.genderMaleRef}
                          />
                          <label
                            htmlFor="male"
                            className="optional radio_option"
                          >
                            Male
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            name="gender"
                            id="female"
                            value="female"
                            onChange={this.handleChange}
                            autoComplete="new-off"
                            required
                            ref={this.genderFemaleRef}
                          />
                          <label
                            htmlFor="female"
                            className="optional radio_option"
                          >
                            Female
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            name="gender"
                            id="other"
                            value="other"
                            onChange={this.handleChange}
                            autoComplete="new-off"
                            required
                            ref={this.genderOtherRef}
                          />
                          <label
                            htmlFor="other"
                            className="optional radio_option"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={inputStyle["input-field-container"]}>
                      <label htmlFor="nationality" className={inputStyle.label}>
                        Nationality
                      </label>
                      <select
                        name="nationality"
                        id="nationality"
                        value={this.state.nationality}
                        required
                        autoComplete="new-off"
                        onChange={this.handleChange}
                        className={inputStyle["input-style"]}
                      >
                        <option value="indian">Indian</option>
                        <option value="nri">NRI</option>
                        <option value="pio">PIO</option>
                        <option value="oci">OCI</option>
                      </select>
                    </div>
                  </div>

                  <div className="two-div">
                    <Input
                      label="Mobile Number"
                      type="tel"
                      value={this.state.mobile}
                      name="mobile"
                      id="mobile"
                      onChange={this.handleChange}
                      maxLength="15"
                      required
                    />

                    <Input
                      label="Alternate Mobile Number"
                      type="tel"
                      value={this.state.alternate_mobile_number}
                      name="alternate_mobile_number"
                      id="alternate_mobile_number"
                      onChange={this.handleChange}
                      maxLength="15"
                      //required
                    />

                    <Input
                      label="Aadhar Card No."
                      type="text"
                      value={this.state.aadhar}
                      name="aadhar"
                      id="aadhar"
                      onChange={this.handleChange}
                      maxLength="12"
                      required
                    />

                    <Input
                      label="Postal Address"
                      type="text"
                      value={this.state.postal_address}
                      name="postal_address"
                      id="postal_address"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  {/* Upload section */}

                  <div className="three-div">
                    {/* {!this.state.order_id_created && (
                      <Input
                        label="Form A(G) Registration number"
                        type="text"
                        value={this.state.form_a_reg_no}
                        name="form_a_reg_no"
                        id="form_a_reg_no"
                        onChange={this.handleChange}
                        disabled={this.state.order_id_created ? true : false}
                      />
                    )} */}
                    <Input
                      label="Upload Photo"
                      type="file"
                      value={this.state.photo}
                      onChange={this.handleChange}
                      id="photo"
                      name="photo"
                      required
                      sizeErr={this.state.formErrors.photo_size}
                      typeErr={this.state.formErrors.photo_type}
                      ref={this.imageRef}
                    />
                    <img
                      id="photo_preview"
                      alt="photo_preview"
                      className={
                        this.state.photo_preview
                          ? "show_preview"
                          : "hide_preview"
                      }
                      ref={this.previewRef}
                    />
                  </div>

                  <hr className="horizontal-rule" />

                  {/* Submit section */}

                  <div className="pre-footer">
                    <p
                      id="backend-error"
                      className={`${
                        this.state.submitted_successfully
                          ? "success"
                          : "failure"
                      } ${this.state.showErrorBox ? "show" : ""}`}
                      dangerouslySetInnerHTML={this.addBackendError()}
                    ></p>
                    <div className="button-grid">
                      <div className="pre-footer__navigator">
                        <div></div>
                        <Link
                          to="/form-n/academic-details"
                          className="button button--grey next"
                          style={{
                            display: `${
                              this.state.step_one_finished
                                ? "inline-block"
                                : "none"
                            }`,
                          }}
                        >
                          NEXT
                          <svg viewBox="0 0 24 24">
                            <path d="M4.983 1.22296C4.377 1.55696 4 2.20896 4 2.92596L4 21.074C4 21.791 4.377 22.443 4.983 22.777C5.561 23.095 6.24 23.073 6.798 22.719L21.113 13.645C21.669 13.294 22 12.679 22 12C22 11.321 21.669 10.706 21.114 10.354L6.799 1.27996C6.24 0.926963 5.561 0.904961 4.983 1.22296Z" />
                          </svg>
                        </Link>
                      </div>

                      <button
                        type="submit"
                        className={`button ${
                          this.state.loading_button ? "loading" : ""
                        }`}
                        id="submit-button"
                      >
                        {this.state.submitButtonText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else if (this.state.isAuthenticated && this.state.final_submission_done) {
      return <Redirect to="/form-n/profile" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}
