import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ScrollToTop from "../../util/ScrollToTop";
import style from "./profile.module.scss";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      submitted_successfully: "",
      final_submission_done: false,
      last_step: "",
      pdf_fetched: false,

      f_step_one_finished: "",
      f_step_two_finished: "",
      f_step_three_finished: "",
      f_step_four_finished: "",
      first_year_status: "loading...",
      first_year_color: "",
      application_submitted: false,

      showErrorBox: false,

      loading_button: "",
      backendError: "",

      message: "loading...",
    };
  }

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  async componentDidMount() {
    try {
      let myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/`,
        requestOptions
      );
      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("isLoggedInToGcet", "false");

        this.setState({
          isAuthenticated: false,
        });
        const authProps = {
          isAuth: this.state.isAuthenticated,
        };
        this.props.checkLoginStatus(authProps);
      }

      let app = data.applicationData;

      this.setState({
        f_step_one_finished: app.step_one_finished,
        f_step_two_finished: app.step_two_finished,
        f_step_three_finished: app.step_three_finished,
        f_step_four_finished: app.step_four_finished,
        application_submitted: app.is_application_submitted,
        // f_step_four_finished: app.is_roll_no_generated,
      });

      // localStorage.setItem(
      //   "neet_step_one_finished",
      //   this.state.f_step_one_finished
      // );
      // localStorage.setItem(
      //   "neet_step_two_finished",
      //   this.state.f_step_two_finished
      // );
      // localStorage.setItem(
      //   "neet_step_three_finished",
      //   this.state.f_step_three_finished
      // );
      // localStorage.setItem(
      //   "neet_step_four_finished",
      //   this.state.f_step_four_finished
      // );

      if (this.state.application_submitted) {
        this.setState({
          first_year_status: "Application Complete",
          first_year_color: "green",
        });
      } else if (!this.state.f_step_one_finished) {
        this.setState({
          first_year_status: "Not applied",
          first_year_color: "#485474",
        });
      } else {
        this.setState({
          first_year_status: "In progress",
          // first_year_status: "Not completed",
          first_year_color: "red",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    if (this.state.isAuthenticated) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">My Profile</h1>
              </div>

              {/* <div className="cell large-6 medium-6">
                <div className={style["profile-section"]}>
                  <h2 className={style["profile-section__title"]}>
                    Admission form for First Year of Professional Degree Courses
                    based on <span>GCET</span> examination.
                  </h2>
                  <p className={style["profile-section__status-container"]}>
                    <span className={style["profile-section__status-label"]}>
                      Status:{" "}
                    </span>
                    <span
                      className={style["profile-section__status not-applied"]}
                      style={{ color: `${this.state.first_year_color}` }}
                    >
                      {this.state.first_year_status}
                    </span>
                  </p>
                  <div>
                    <Link to="/form-g/profile" className="button">
                      Go to application
                    </Link>
                  </div>
                </div>
              </div> */}

              <div className="cell large-6 medium-6">
                <div className={style["profile-section"]}>
                  <h2 className={style["profile-section__title"]}>
                    Application form for admission to First Year of Professional Degree Courses
                    based on <span>NEET/GCET</span> examination.
                    <br />
                  </h2>
                  <p className={style["profile-section__status-container"]}>
                    <span className={style["profile-section__status-label"]}>
                      Status:{" "}
                    </span>
                    <span
                      className={style["profile-section__status not-applied"]}
                      style={{ color: `${this.state.first_year_color}` }}
                    >
                      {this.state.first_year_status}
                    </span>
                  </p>
                  <div
                  >
                    <Link to="/form-n/profile" className="button">
                      Go to application
                    </Link>
                  </div>
                </div>
              </div>

              <div className="cell large-12">
                <div className="pre-footer" style={{ marginTop: "30px" }}>
                  <p
                    id="backend-error"
                    className={`${
                      this.state.submitted_successfully ? "success" : "failure"
                    } ${this.state.showErrorBox ? "show" : ""}`}
                    dangerouslySetInnerHTML={this.addBackendError()}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}
