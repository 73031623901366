import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";

export class NPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      submitted_successfully: "",
      last_step: "",
      step_one_finished: true,
      step_two_finished: true,
      step_three_finished: true,
      step_four_finished: true,

      pdf_downloaded: false,

      full_name: "",
      photo_preview: false,
      date_of_birth: "",
      gender: "",
      email: "",
      postal_address: "",
      mobile: "",
      alternate_mobile_number: "",
      aadhar: "",
      photo: "",
      nationality: "",
      form_a_reg_no: "",

      hssc_board: "",
      hssc_board_year: "",
      hssc_percentage: "",

      hssc_total_marks: "",
      hssc_total_maximum_marks: "",
      hssc_english_marks: "",
      hssc_english_maximum_marks: "",
      hssc_physics_marks: "",
      hssc_physics_maximum_marks: "",
      hssc_maths_marks: "",
      hssc_maths_maximum_marks: "",
      hssc_chemistry_marks: "",
      hssc_chemistry_maximum_marks: "",
      hssc_biology_marks: "",
      hssc_biology_maximum_marks: "",
      hssc_others_marks: "",
      hssc_others_maximum_marks: "",

      general_category_selected: false,
      sc_category_selected: false,
      st_category_selected: false,
      obc_category_selected: false,
      freedom_fighter: false,
      person_with_disability: false,
      children_of_central_state_employees: false,
      ex_service_man: false,
      local_applicant: false,
      goan_native: false,
      goan_native_st: false,
      goan_native_obc: false,
      nri_goan_origin: false,
      nri_non_goan_origin: false,
      pio_goan_origin: false,
      pio_non_goan_origin: false,
      outside_goa_applicant: false,
      kashmiri_migrant: false,
      foreign_national: false,
      gulf: false,

      applying_for_bvsc_ah: false,
      choiceArr: [],
      tfw_preference: "",
      tfw_ews_consideration: "",

      orderId: "",
      order_id_created: false,
      fees_paid: false,
      showPreview: false,
      is_gcet_roll_no_verified: false,

      categoryArr: [],

      showErrorBox: false,

      loading_button: "",
      backendError: "",

      message:
        '<span style="font-weight:500">Acknowledgment Card</span> will be downloaded automatically. A copy will be emailed to the student&#39;s email ID.<br />If the Acknowledgment card is not downloaded automatically, please click on the button below</p>',
    };
  }

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  async componentDidMount() {
    try {
      let myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/`,
        requestOptions
      );
      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("isLoggedInToGcet", "false");

        this.setState({
          isAuthenticated: false,
        });
        const authProps = {
          isAuth: this.state.isAuthenticated,
        };
        this.props.checkLoginStatus(authProps);
      }

      let app = data.applicationData;

      this.setState({
        last_step: app.last_step,
        step_one_finished: app.step_one_finished,
        step_two_finished: app.step_two_finished,
        step_three_finished: app.step_three_finished,
        step_four_finished: app.step_four_finished,
        final_submission_done: app.is_application_submitted,
        orderId: app.razorpay_order_id, 
        order_id_created: app.is_order_generated,
        fees_paid: app.is_paid,
        is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
        
      });

      if (
        (this.state.order_id_created && this.state.fees_paid) ||
        (!this.state.order_id_created && this.state.is_gcet_roll_no_verified)
      ) {
        this.setState({
          showPreview: true,
        });
      } else {
        this.setState({
          showPreview: false,
        });
      }

      if (app.step_one_finished) {
        this.setState({
          photo_preview: true,
          full_name: app.name,
          date_of_birth: app.date_of_birth,
          gender: app.gender,
          mobile: app.mobile_number,
          alternate_mobile_number: app.alternate_mobile_number,
          aadhar: app.aadhar_card_number,
          email: app.email_id,
          postal_address: app.postal_address,
          componentDidMount: true,
          nationality: app.nationality,
          form_a_reg_no: app.form_a_reg_no,
        });

        let photo_preview = document.getElementById("final_photo_preview");
        photo_preview.src = app.photo;
      }

      if (app.step_two_finished) {
        this.setState({
          hssc_board: app.hssc_board === null ? "" : app.hssc_board,
          hssc_board_year:
            app.hssc_board_year === null ? "" : app.hssc_board_year,
          hssc_board_seat_number: app.hssc_board_seat_number,

          hssc_total_marks:
            app.hssc_total_marks === null ? "" : app.hssc_total_marks,
          hssc_total_maximum_marks:
            app.hssc_total_maximum_marks === null
              ? ""
              : app.hssc_total_maximum_marks,
          hssc_english_marks:
            app.hssc_english_marks === null ? "" : app.hssc_english_marks,
          hssc_english_maximum_marks:
            app.hssc_english_maximum_marks === null
              ? ""
              : app.hssc_english_maximum_marks,
          hssc_physics_marks:
            app.hssc_physics_marks === null ? "" : app.hssc_physics_marks,
          hssc_physics_maximum_marks:
            app.hssc_physics_maximum_marks === null
              ? ""
              : app.hssc_physics_maximum_marks,
          hssc_maths_marks:
            app.hssc_maths_marks === null ? "" : app.hssc_maths_marks,
          hssc_maths_maximum_marks:
            app.hssc_maths_maximum_marks === null
              ? ""
              : app.hssc_maths_maximum_marks,
          hssc_chemistry_marks:
            app.hssc_chemistry_marks === null ? "" : app.hssc_chemistry_marks,
          hssc_chemistry_maximum_marks:
            app.hssc_chemistry_maximum_marks === null
              ? ""
              : app.hssc_chemistry_maximum_marks,
          hssc_biology_marks:
            app.hssc_biology_marks === null ? "" : app.hssc_biology_marks,
          hssc_biology_maximum_marks:
            app.hssc_biology_maximum_marks === null
              ? ""
              : app.hssc_biology_maximum_marks,
          hssc_others_marks:
            app.hssc_others_marks === null ? "" : app.hssc_others_marks,
          hssc_others_maximum_marks:
            app.hssc_others_maximum_marks === null
              ? ""
              : app.hssc_others_maximum_marks,

          neet_roll_no: app.neet_roll_no,
          neet_percentage: app.neet_percentage,
          neet_rank: app.neet_rank,
          gcet_roll_no: app.gcet_roll_no,
          gcet_physics_marks: app.gcet_physics_marks,
          gcet_chemistry_marks: app.gcet_chemistry_marks,
          gcet_maths_marks: app.gcet_maths_marks,

          applying_for_bvsc_ah: app.applying_for_bvsc_ah,
        });
      }

      if (app.step_three_finished) {
        this.setState({
          general_category_selected: app.general_category_selected,
          sc_category_selected: app.sc_category_selected,
          st_category_selected: app.st_category_selected,
          obc_category_selected: app.obc_category_selected,
          freedom_fighter: app.freedom_fighter,
          person_with_disability: app.person_with_disability,
          children_of_central_state_employees:
            app.children_of_central_state_employees,
          ex_service_man: app.ex_service_man,
          local_applicant: app.local_applicant,
          goan_native: app.goan_native,
          goan_native_st: app.goan_native_st,
          goan_native_obc: app.goan_native_obc,
          nri_goan_origin: app.nri_goan_origin,
          nri_non_goan_origin: app.nri_non_goan_origin,
          pio_goan_origin: app.pio_goan_origin,
          pio_non_goan_origin: app.pio_non_goan_origin,
          outside_goa_applicant: app.outside_goa_applicant,
          kashmiri_migrant: app.kashmiri_migrant,
          foreign_national: app.foreign_national,
          gulf: app.gulf,
          documents_uploaded:
            app.documents_uploaded === null ? "" : app.documents_uploaded,
        });

        let categoryArr = [];

        let categoryList = [
          [this.state.general_category_selected, "GEN"],
          [this.state.sc_category_selected, "SC"],
          [this.state.st_category_selected, "ST"],
          [this.state.obc_category_selected, "OBC"],
          [this.state.freedom_fighter, "Freedom Fighter"],
          [this.state.person_with_disability, "PwD"],
          [this.state.children_of_central_state_employees, "CSP"],
          [this.state.ex_service_man, "ESM"],
          [this.state.local_applicant, "LA"],
          [this.state.goan_native, "Goan Native"],
          [this.state.goan_native_st, "Goan Native - ST"],
          [this.state.goan_native_obc, "Goan Native - OBC"],
          [this.state.nri_goan_origin, "NRI - Goan Origin"],
          [this.state.nri_non_goan_origin, "NRI - Non Goan Origin"],
          [this.state.pio_goan_origin, "PIO/OCI - Goan Origin"],
          [this.state.pio_non_goan_origin, "PIO/OCI - Non Goan Origin"],
          [this.state.outside_goa_applicant, "OGA"],
          [this.state.kashmiri_migrant, "KM"],
          [this.state.foreign_national, "FN/PIO"],
          [this.state.gulf, "Gulf"],
        ];

        for (let i = 0, j = 0; i < categoryList.length; i++) {
          if (categoryList[i][0]) {
            categoryArr[j] = categoryList[i][1];
            j++;
          }
        }

        let mappedArr = categoryArr.map((x, index) => (
          <span key={index}>{x} &nbsp;&nbsp;</span>
        ));

        this.setState({
          categoryArr: mappedArr,
        });
      }

      if (app.step_four_finished) {
        this.setState({
          choiceArr: app.choice_of_colleges,
          tfw_preference: app.has_applied_for_fee_waiver,
        });

        if (app.tfw_ews_consideration === "TFW, EWS") {
          this.setState({
            tfw_ews_consideration: "TFW, EWS",
          });
        } else if (app.tfw_ews_consideration === "TFW") {
          this.setState({
            tfw_ews_consideration: "TFW",
          });
        } else if (app.tfw_ews_consideration === "EWS") {
          this.setState({
            tfw_ews_consideration: "EWS",
          });
        } else if (app.tfw_ews_consideration === "NONE") {
          this.setState({
            tfw_ews_consideration: "NONE",
          });
        }
      }

      localStorage.setItem("neet_step_one_finished", app.step_one_finished);
      localStorage.setItem("neet_step_two_finished", app.step_two_finished);
      localStorage.setItem("neet_step_three_finished", app.step_three_finished);
      localStorage.setItem("neet_step_four_finished", app.step_four_finished);
      localStorage.setItem(
        "neet_final_submission_done",
        app.is_application_submitted
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  finalSubmit = () => {
    this.setState({
      loading_button: true,
    });
    var myHeaders = new Headers();
    const cookies = new Cookies();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "token " + cookies.get("token"));

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/application/`,
      requestOptions
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            showErrorBox: true,
            submitted_successfully: true,
          });
        } else if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");

          this.setState({
            isAuthenticated: false,
          });
          const authProps = {
            isAuth: this.state.isAuthenticated,
          };
          this.props.checkLoginStatus(authProps);
        } else {
          this.setState({
            submitted_successfully: false,
            showErrorBox: true,
            loading_button: false,
          });
        }
        return response.text();
      })
      .then((result) => {
        this.setState({
          backendError: JSON.parse(result).message,
          loading_button: false,
        });

        if (this.state.submitted_successfully) {
          localStorage.setItem("neet_final_submission_done", "true");
          var bin = atob(JSON.parse(result).pdf);
          var len = bin.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
              bytes[i] = bin.charCodeAt(i);
          }
          const file = new Blob([bytes], {
            type: "application/pdf",
          });
          var a = document.createElement("a");
          const fileURL = URL.createObjectURL(file);
          a.href = fileURL;
          a.download = `${
            JSON.parse(result).applicantDetails.rollNo
          }_${JSON.parse(result).applicantDetails.name.split(" ").join("_")}`;
          document.body.appendChild(a);
          a.click();
          this.setState({
            pdf_downloaded: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          showErrorBox: true,
          submitted_successfully: false,
          backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
          loading_button: false,
        });
        console.log("error", error);
      });
  };

  render() {
    if (
      this.state.isAuthenticated &&
      this.state.submitted_successfully &&
      this.state.pdf_downloaded
    ) {
      return (
        <Redirect
          to={{
            pathname: "/form-n/profile",
            data: { message: this.state.message },
          }}
        />
      );
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true &&
      this.state.step_three_finished
    ) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Application Form A <span>* Mandatory Fields</span>
                </h1>

                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                      this.state.is_gcet_roll_no_verified,
                  }}
                />

                <div
                  className="message__box"
                  style={{
                    marginBottom: "30px",
                    display: `${this.state.fees_paid  && !this.state.is_gcet_roll_no_verified? "block" : "none"}`,
                    backgroundColor: "#e7f4e4",
                    border: "1px solid #2fb60d",
                  }}
                >
                  <h3 className="message__title">
                    Application Fees Paid Successfully
                  </h3>
                  <ul className="message__list-container">
                    <li
                      className="message__list-item"
                      style={{ textTransform: "none", color: "#2b2b5b" }}
                    >
                      Your payment is successful
                      <br />
                      Please click on <b>FINAL SUBMIT</b> button to complete the
                      application form.
                      <br />
                      Order Id: {this.state.orderId} <br />
                      Amount Paid: 1700
                    </li>
                  </ul>
                </div>

                <div
                  className="message__box"
                  style={{
                    marginBottom: "30px",
                    display: `${this.state.showPreview ? "block" : "none"}`,
                  }}
                >
                  <h3 className="message__title">Important Instructions</h3>
                  <ul className="message__list-container">
                    <li className="message__list-item">
                      KINDLY ENSURE THAT ALL THE PARTICULARS BELOW ARE TRUE,
                      COMPLETE AND CORRECT BEFORE YOU CLICK ON{" "}
                      <b>FINAL SUBMIT</b> BUTTON
                    </li>
                    <li className="message__list-item">
                      IF YOU WANT TO CHANGE ANY DETAILS, PLEASE CLICK ON THE
                      STEP IN THE PROGRESS BAR ABOVE,{" "}
                      <b>MAKE THE CHANGES AND SAVE</b>. THE CHANGES WILL BE
                      REFLECTED IN THE PREVIEW.
                    </li>
                    <li className="message__list-item" style={{ color: "red" }}>
                      APPLICATION CANNOT BE EDITED ONCE YOU CLICK ON 'FINAL
                      SUBMIT' BUTTON. SUMMARY SHEET OF YOUR APPLICATION WILL BE EMAILED TO YOU.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="cell large-8"
                style={{
                  display: `${this.state.showPreview ? "block" : "none"}`,
                }}
              >
                <p className="sub-heading" style={{ marginBottom: "10px" }}>
                  Sample Acknowledgment Card
                </p>
                <div className="detail-card">
                  <div className="acknowledgment_card">
                    <img
                      src="/images/dte-logo.png"
                      alt="DTE Logo"
                      className="acknowledgment_card__dte-logo"
                    />
                    <div className="acknowledgment_card__title">
                      <p className="acknowledgment_card__title--small">
                        Government of Goa
                      </p>
                      <p className="acknowledgment_card__title--big">
                        Directorate of Technical Education
                      </p>
                      <p className="acknowledgment_card__address">
                        Alto-Porvorim, Bardez Goa
                      </p>
                      <p
                        className="acknowledgment_card__title--small"
                        style={{ fontWeight: "500" }}
                      >
                        Sample Acknowledgment Card for application for
 GCET and                        NEET based courses.
                      </p>
                    </div>
                  </div>

                  <div className="detail-card__section">
                    <img
                      className="detail-card__photo"
                      id="final_photo_preview"
                      alt="applicant"
                    />
                    <div className="detail-card__subsection">
                      <div className="detail-card__name">
                        <span className="detail-card__label">Full Name:</span>{" "}
                        {this.state.full_name}
                      </div>

                      <div className="detail-card__categories">
                        <span className="detail-card__label">
                          Categories selected:
                        </span>
                        <span>{this.state.categoryArr}</span>
                      </div>

                      <div className="registration_number">
                        <span className="detail-card__label">
                          Registration Number:
                        </span>
                        Will be generated once you click on 'Final Submit'
                        button below
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="cell large-6"
                style={{
                  display: `${this.state.showPreview ? "block" : "none"}`,
                }}
              >
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Basic Details</b>
                        </td>
                      </tr>

                      <tr>
                        <td>Name</td>
                        <td>{this.state.full_name}</td>
                      </tr>

                      <tr>
                        <td>Date of Birth</td>
                        <td>{this.state.date_of_birth}</td>
                      </tr>

                      <tr>
                        <td>Gender</td>
                        <td>{this.state.gender}</td>
                      </tr>

                      <tr>
                        <td>Mobile No.</td>
                        <td>{this.state.mobile}</td>
                      </tr>

                      <tr>
                        <td>Alternate Mobile No.</td>
                        <td>{this.state.alternate_mobile_number}</td>
                      </tr>

                      <tr>
                        <td>Aadhar No. </td>
                        <td>{this.state.aadhar}</td>
                      </tr>

                      <tr>
                        <td>Postal Address</td>
                        <td>{this.state.postal_address}</td>
                      </tr>

                      <tr>
                        <td>Nationality</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {this.state.nationality}
                        </td>
                      </tr>

                      <tr
                        style={{
                          display: this.state.order_id_created
                            ? "none"
                            : "grid",
                        }}
                      >
                        <td>Form A Registration Number</td>
                        <td>{this.state.form_a_reg_no}</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Categories selected</b>
                        </td>
                        <td>{this.state.categoryArr}</td>
                      </tr>

                      
                    </tbody>
                  </table>
                </div>

                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>12th Standard Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Board</td>
                        <td>{this.state.hssc_board}</td>
                      </tr>

                      <tr>
                        <td>Month &amp; Year of passing</td>
                        <td>{this.state.hssc_board_year}</td>
                      </tr>

                      <tr>
                        <td>Seat Number</td>
                        <td>{this.state.hssc_board_seat_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="cell large-6"
                style={{
                  display: `${this.state.showPreview ? "block" : "none"}`,
                }}
              >
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>GCET Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>GCET Roll Number</td>
                        <td>{this.state.gcet_roll_no}</td>
                      </tr>

                      <tr>
                        <td>Physics</td>
                        <td>{this.state.gcet_physics_marks}</td>
                      </tr>

                      <tr>
                        <td>Chemistry</td>
                        <td>{this.state.gcet_chemistry_marks}</td>
                      </tr>
                      <tr>
                        <td>Maths</td>
                        <td>{this.state.gcet_maths_marks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <b>NEET Details</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Roll Number</td>
                        <td>{this.state.neet_roll_no === -1 ? "": this.state.neet_roll_no}</td>
                      </tr>

                      <tr>
                        <td>All India Rank</td>
                        <td>{this.state.neet_rank}</td>
                      </tr>

                      <tr>
                        <td>Percentile Score</td>
                        <td>{this.state.neet_percentage}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="preview-info">
                  <table>
                    <tbody>
                      <tr className="three-sections">
                        <td>
                          <b>12th Standard Marks</b>
                        </td>
                        <td style={{ fontWeight: "600", fontSize: "14px" }}>
                          Marks
                        </td>
                        <td style={{ fontWeight: "600", fontSize: "14px" }}>
                          Max Marks
                        </td>
                      </tr>

                      <tr className="three-sections">
                        <td>Total Marks</td>
                        <td>{this.state.hssc_total_marks}</td>
                        <td>{this.state.hssc_total_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>English</td>
                        <td>{this.state.hssc_english_marks}</td>
                        <td>{this.state.hssc_english_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>Physics</td>
                        <td>{this.state.hssc_physics_marks}</td>
                        <td>{this.state.hssc_physics_maximum_marks}</td>
                      </tr>

                       <tr className="three-sections">
                        <td>Maths</td>
                        <td>{this.state.hssc_maths_marks}</td>
                        <td>{this.state.hssc_maths_maximum_marks}</td>
                      </tr> 

                      <tr className="three-sections">
                        <td>Chemistry</td>
                        <td>{this.state.hssc_chemistry_marks}</td>
                        <td>{this.state.hssc_chemistry_maximum_marks}</td>
                      </tr>

                      <tr className="three-sections">
                        <td>Biology</td>
                        <td>{this.state.hssc_biology_marks}</td>
                        <td>{this.state.hssc_biology_maximum_marks}</td>
                      </tr>
                      
                       <tr className="three-sections">
                        <td>Others</td>
                        <td>{this.state.hssc_others_marks}</td>
                        <td>{this.state.hssc_others_maximum_marks}</td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="cell large-12"
                style={{
                  marginTop: "30px",
                  display: `${this.state.showPreview ? "block" : "none"}`,
                }}
              >
                <div className="pre-footer">
                  <div className="button-grid">
                    <div className="pre-footer__navigator">
                      <Link
                        to="/form-n/category"
                        className="button button--grey prev"
                        style={{ marginRight: "30px" }}
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M19.017 22.777C19.623 22.443 20 21.791 20 21.074L20 2.92604C20 2.20904 19.623 1.55704 19.017 1.22304C18.439 0.905038 17.76 0.927038 17.202 1.28104L2.887 10.355C2.331 10.706 2 11.321 2 12C2 12.679 2.331 13.294 2.886 13.646L17.201 22.72C17.76 23.073 18.439 23.095 19.017 22.777Z" />
                        </svg>
                        PREVIOUS
                      </Link>
                      <div></div>
                    </div>
                    <button
                      type="submit"
                      className={`button ${
                        this.state.loading_button ? "loading" : ""
                      }`}
                      id="submit-button"
                      onClick={this.finalSubmit}
                    >
                      FINAL SUBMIT
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="cell large-12"
                style={{
                  display: `${
                    this.state.fees_paid || !this.state.order_id_created
                      ? "none"
                      : "block"
                  }`,
                }}
              >
                <div
                  className="message__box"
                  style={{ marginTop: "30px", marginBottom: "0" }}
                >
                  <h3 className="message__title">Payment</h3>
                  <p className="paymentPage__text">
                    Applicant needs to pay Application fee of Rs. 1700 to
                    complete the form submission. Please click on the button
                    below to pay the fees
                  </p>
                </div>
              </div>

              <div
                className="cell large-12"
                style={{
                  display: `${
                    this.state.fees_paid || !this.state.order_id_created
                      ? "none"
                      : "block"
                  }`,
                }}
              >
                <div className="pre-footer" style={{ marginTop: "30px" }}>
                  <p
                    id="backend-error"
                    className={`${
                      this.state.submitted_successfully ? "success" : "failure"
                    } ${this.state.showErrorBox ? "show" : ""}`}
                    dangerouslySetInnerHTML={this.addBackendError()}
                  ></p>
                  <form
                    method="POST"
                    //action="https://api.razorpay.com/v1/checkout/embedded"
                    action="https://api.razorpay.com/v1/checkout/embedded"
                  >
                    <input
                      type="hidden"
                      name="key_id"
                      //value="rzp_test_vEugzFUolpld9w"
                      // value="rzp_live_fT8d3Trwy46tCE"
                      value ='rzp_live_P8B3kl4kUC0QMP'
                    />

                    <input
                      type="hidden"
                      name="order_id"
                      value={this.state.orderId}
                    />

                    <input type="hidden" name="amount" value="170000" />
                    {/* 170000 */}

                    <input type="hidden" name="name" value="DTE - Degree Admissions" />

                    <input
                      type="hidden"
                      name="description"
                      value="Pay the exam fees"
                    />

                    <input
                      type="hidden"
                      name="prefill[email]"
                      value={this.state.email}
                    />

                    <input
                      type="hidden"
                      name="prefill[contact]"
                      value={this.state.mobile}
                    />

                    <input
                      type="hidden"
                      name="notes[transaction_id]"
                      value="transaction_1234"
                    />

                    <input
                      type="hidden"
                      name="callback_url"
                      value={`${process.env.REACT_APP_API_URL}/admission/application/payment`}
                    />

                    <div className="button-grid">
                      <div className="pre-footer__navigator">
                        <Link
                          to="/form-n/category"
                          className="button button--grey prev"
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M19.017 22.777C19.623 22.443 20 21.791 20 21.074L20 2.92604C20 2.20904 19.623 1.55704 19.017 1.22304C18.439 0.905038 17.76 0.927038 17.202 1.28104L2.887 10.355C2.331 10.706 2 11.321 2 12C2 12.679 2.331 13.294 2.886 13.646L17.201 22.72C17.76 23.073 18.439 23.095 19.017 22.777Z" />
                          </svg>
                          PREVIOUS
                        </Link>
                      </div>
                      <button
                        type="submit"
                        className={`button ${
                          this.state.loading_button ? "loading" : ""
                        }`}
                        id="submit-button"
                      >
                        Proceed for Payment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true
    ) {
      return <Redirect to="/form-n/category" />;
    } else if (this.state.isAuthenticated && this.state.final_submission_done) {
      return <Redirect to="/form-n/profile" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}
