import React from "react";
import {Link} from "react-router-dom";
import style from "./footer.module.scss";

export const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footer__contact}>
        <p>
          For help, mail us at:{" "}
          <a href="mailto:dtegoaadm-deg@gov.in">dtegoaadm-deg@gov.in</a>, Call <a href="tel:+919823391497">982-339-1497</a> or{" "}
          <a href="tel:+919561270021">956-127-0021</a> between 10 am to 5 pm<br />
          Contact us for queries related only to online form submission. <mark style={{backgroundColor: "yellow"}}>&nbsp; Please read the <Link to="/faq" style={{color: 'red'}}>FAQ</Link> section before calling us. &nbsp;</mark>
        </p>
      </div>

      <div className={style.footer__credits}>
        <p>
          This portal is designed, developed and managed by{" "}
          <a
            href="https://www.gec.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Goa College of Engineering
          </a>
          , Farmagudi, Ponda
          <br /> For the{" "}
          <a
            href="https://dte.goa.gov.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Directorate of Technical Education
          </a>
          , Goa.
        </p>
      </div>
    </footer>
  );
};
