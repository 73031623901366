import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import style from "./header.module.scss";
import Cookies from "universal-cookie";

import govtLogo from "../../images/govt-logo.png";
import dteLogo from "../../images/dte-logo.png";
import homeIcon from "../../images/home-icon.svg";
import { Link } from "react-router-dom";

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: "",
      isAuthenticated: "",
      openMenu: false,
    };
  }

  toggleMenu = () => {
    if (this.state.openMenu === false) {
      this.setState({
        openMenu: true,
      });
    } else {
      this.setState({
        openMenu: false,
      });
    }
  };

  closeMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  logout = () => {
    var myHeaders = new Headers();
    const cookies = new Cookies();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "token " + cookies.get("token"));


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    localStorage.setItem("neet_step_one_finished", false);
    localStorage.setItem("neet_step_two_finished", false);
    localStorage.setItem("neet_step_three_finished", false);
    localStorage.setItem("neet_step_four_finished", false);
    localStorage.setItem("neet_final_submission_done", false);
    cookies.remove("token");
    localStorage.setItem("isLoggedInToGcet", "false");
    this.setState({
      isAuthenticated: false,
      isAdmin: false,
    });
    const authProps = {
      isAuth: this.state.isAuthenticated,
      isAdmin: this.state.isAdmin,
    };
    this.props.checkLoginStatus(authProps);
    fetch(`${process.env.REACT_APP_API_URL}/accounts/logout`, requestOptions)
      .then((response) => {
        this.props.history.push("/");
        response.text();
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    return (
      <>
        <div className="grid-container full">
          <div className="grid-x">
            <div className="cell large-7">
              <div className={`${style.govt} ${style["govt-info-container"]}`}>
                <img
                  src={govtLogo}
                  alt="Goa Government logo"
                  className={style["govt__goa-logo"]}
                />
                <div className={style["govt__title"]}>
                  <p className={style["govt__title--small"]}>
                    Government of Goa
                  </p>
                  <p className={style["govt__title--big"]}>
                    Directorate of Technical Education
                  </p>
                  <p className={style.govt__address}>
                    Alto-Porvorim, Bardez Goa
                  </p>
                </div>
                <img
                  src={dteLogo}
                  alt="DTE Logo"
                  className={style["govt__dte-logo"]}
                />
              </div>
            </div>

            <div className="cell large-5">
              <div className={style.gcet}>
                <p className={style.gcet__text}>
                  Application Form A for Admission to First Year of Professional Degree Courses 2023 -
                  2024 (Except B. Arch.)
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* navigation */}
        <nav id="nav_logged-out">
          <div className="grid-container">
            <div className="grid-x">
              <div className="cell large-12">
                <div className={style.nav}>
                  <NavLink to="/" onClick={this.closeMenu}>
                    <div className={style.nav__home}>
                      <img
                        src={homeIcon}
                        alt="home icon"
                        className={style.nav__home__icon}
                      />
                      <p className={style.nav__home__title}>Home</p>
                    </div>
                  </NavLink>

                  <div
                    className={`${style["nav__item-container"]} ${
                      this.state.openMenu ? "" : style.hide
                    }`}
                  >
                    <ul>
                      {this.props.auth.isAuthenticated && (
                        <li className={style.nav__item}>
                          <NavLink
                            to="/myprofile"
                            activeClassName={style["nav__item--active"]}
                            onClick={this.closeMenu}
                          >
                            My Profile
                          </NavLink>
                        </li>
                      )}
                      {/* <li className={style.nav__item}>
                        <NavLink
                          activeClassName={style["nav__item--active"]}
                          to="/instructions"
                          onClick={this.closeMenu}
                        >
                          Instructions
                        </NavLink>
                      </li>
                      <li className={style.nav__item}>
                        <NavLink
                          activeClassName={style["nav__item--active"]}
                          to="/faq"
                          onClick={this.closeMenu}
                        >
                          FAQ
                        </NavLink>
                      </li> */}
                      {this.props.auth.isAuthenticated ? (
                        <Link to = "/">
                        <li
                          className={style.nav__item}
                          onClick={() => {
                            this.logout();
                            this.closeMenu();
                          }}
                        >
                          <span>Logout</span>
                        </li>
                        </Link>
                      ) : (
                        <>
                          {/*<li className={style.nav__item}>
                            <NavLink
                              to="/register"
                              activeClassName={style["nav__item--active"]}
                              onClick={this.closeMenu}
                            >
                              Register
                            </NavLink>
                          </li>
                          <li className={style.nav__item}>
                            <NavLink
                              to="/login"
                              activeClassName={style["nav__item--active"]}
                              onClick={this.closeMenu}
                            >
                              Login
                            </NavLink>
                      </li> */}
                        </>
                      )}
                    </ul>
                  </div>
                  <svg
                    onClick={this.toggleMenu}
                    className={style.nav__menu}
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
