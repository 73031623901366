import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../util/ScrollToTop";
import errorImage from "../../images/404_error.png";

export const PageNotFound = () => {
  return (
    <>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell large-12">
            <div className="four0four">
              <img
                src={errorImage}
                className="four0four__image"
                alt="404 illustration"
              />
              <h1 className="four0four__title">404 page not found</h1>
              <p className="four0four__text">
                The page you are looking for does not exist. Please make sure
                that you have entered the correct url or{" "}
                <Link to="/" style={{ color: "#4285F4", fontWeight: "500" }}>
                  click here
                </Link>{" "}
                to go to the home page.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
};
