import React from "react";
import style from "./input.module.scss";

// Basic Input component
export const Input = React.forwardRef(({
  label,
  subLabel,
  type,
  value,
  name,
  id,
  onChange,
  required,
  placeholder,
  readOnly,
  maxLength,
  err,
  sizeErr,
  typeErr,
  min,
  max,
  lock,
}, ref) => {
  return (
    <div className={`${style["input-field-container"]} ${lock && style.lock}`}>
      {label && (
        <label
          htmlFor={id}
          className={`${style.label} ${!required && style.optional}`}
        >
          {label}: {subLabel && <span>({subLabel})</span>}
        </label>
      )}
      <input
        type={type}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        autoComplete="new-off"
        required={required}
        className={`${style["input-style"]} ${
          (sizeErr || typeErr || err) && style.input_error
        }`}
        placeholder={placeholder}
        readOnly={readOnly}
        maxLength={maxLength}
        min={min}
        max={max}
        ref={ref}
      />
      {(err || sizeErr || typeErr) && (
        <p className={`${style.error_message} ${style.upload}`}>
          <b>Errors</b>:<br />
          {err}
          {sizeErr}
          <br />
          {typeErr}
        </p>
      )}
    </div>
  );
});

// Filled Radio/Checkbox with active/inactive state
export const SubjectCheckbox = React.forwardRef(({
  label,
  type,
  name,
  id,
  onChange,
  readOnly,
  checked,
  required,
}, ref) => {
  return (
    <div className={style["input-field-container"]}>
      <input
        type={type || "radio"}
        name={name}
        id={id}
        className={style["filled-checkbox-input"]}
        onChange={onChange}
        readOnly={readOnly}
        checked={checked}
        required={required}
        ref={ref}
      ></input>
      <label
        htmlFor={id}
        className={`${style["subject__label"]} ${style["filled-checkbox-label"]}`}
      >
        {label}
      </label>
    </div>
  );
});

// Normal checkbox with text
export const Checkbox = ({
  label,
  name,
  checked,
  id,
  onChange,
  required,
  message,
}) => {
  return (
    <>
      <div className={style["checkbox-container"]}>
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          required={required}
          className={style["checkbox"]}
        />
        <label htmlFor={name} className={style["checkbox-label"]}>
          {label}
        </label>
      </div>
      <p>{message}</p>
    </>
  );
};

// Category input box
export const CategoryBox = React.forwardRef(
  ({ label, categoryNumber, type, name, id, onChange, typeClear }, ref) => {
    const { refInput, refLabel } = ref;
    return (
      <div style={{ marginBottom: "20px" }}>
        <input
          type={type}
          name={name}
          id={id}
          className={`${style["category__radio"]} ${typeClear && style.clear}`}
          onChange={onChange}
          ref={refInput}
        />
        <label
          htmlFor={id}
          className={`${style["category__label"]} ${
            typeClear && style["clear-label"]
          }`}
          ref={refLabel}
        >
          {categoryNumber && <span>{categoryNumber}.</span>}
          <span>{label}</span>
        </label>
      </div>
    );
  }
);
