import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

// components
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";
import { SubjectCheckbox } from "../../components/Input/index";

// style
import inputStyle from "../../components/Input/input.module.scss";

import Select, { components } from "react-select";
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const optionsForAll = [
  { value: "ARC", label: "ARC" },
  { value: "ITG", label: "ITG" },
  { value: "COG", label: "COG" },
  { value: "ETG", label: "ETG" },
  { value: "EEG", label: "EEG" },
  { value: "MEG", label: "MEG" },
  { value: "CIV", label: "CIV" },
  { value: "ITP", label: "ITP" },
  { value: "COP", label: "COP" },
  { value: "ETP", label: "ETP" },
  { value: "MEP", label: "MEP" },
  { value: "ITR", label: "ITR" },
  { value: "COR", label: "COR" },
  { value: "ETR", label: "ETR" },
  { value: "MER", label: "MER" },
  { value: "COD", label: "COD" },
  { value: "ETD", label: "ETD" },
  { value: "MED", label: "MED" },
  { value: "CID", label: "CID" },
  { value: "COA", label: "COA" },
  { value: "ECA", label: "ECA" },
  { value: "MEA", label: "MEA" },
  { value: "PHG", label: "PHG" },
  { value: "PHP", label: "PHP" },
  { value: "MBS", label: "MBS" },
  { value: "BDS", label: "BDS" },
  { value: "BHM", label: "BHM" },
  { value: "BAM", label: "BAM" },
  { value: "BNG", label: "BNG" },
  { value: "BNV", label: "BNV" },
  { value: "BPT", label: "BPT" },
  { value: "BOT", label: "BOT" },
  { value: "BOP", label: "BOP" },
  { value: "BMT", label: "BMT" },
  { value: "BAT", label: "BAT" },
  { value: "MAF", label: "MAF" },
  { value: "RVR", label: "RVR" },
];

const optionsForNeetOnly = [
  { value: "ARC", label: "ARC" },
  { value: "MBS", label: "MBS" },
  { value: "BDS", label: "BDS" },
  { value: "BHM", label: "BHM" },
  { value: "BAM", label: "BAM" },
  { value: "BNG", label: "BNG" },
  { value: "BNV", label: "BNV" },
  { value: "BPT", label: "BPT" },
  { value: "BOT", label: "BOT" },
  { value: "BOP", label: "BOP" },
  { value: "BMT", label: "BMT" },
  { value: "BAT", label: "BAT" },
  { value: "MAF", label: "MAF" },
  { value: "RVR", label: "RVR" },
];

// const options = this.state.applying_for_bvsc_ah? optionsWithBvsc: optionsWithoutBvsc;

const loginValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class NCourseChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      is_submitted: "",
      componentDidMount: "",
      loading_button: "",
      backendError: "",
      steps: "",
      orderID_added: false,
      toCheckout: false,
      submitted_successfully: false,
      final_submission_done: false,
      last_step: "",
      showErrorBox: false,

      tfw_preference: false,
      tfw_ews_consideration: "",
      choiceArr: [],
      newValue: [],

      onlyNeet: false,

      choice_selection_finished: false,
      pdf_downloaded: false,
      applying_for_bvsc_ah: false,

      // to be changed
      step_one_finished: true,
      step_two_finished: true,
      step_three_finished: true,
      step_four_finished: false,
      order_id_created: false,

      step_two_submitted_before: false,

      documentList: [],

      formErrors: {
        exam: "",
        board: "",
        exam_year: "",
        exam_seat_number: "",
        exam_selection: "",
        course_choices: "",
      },
    };

    this.tfwRef = React.createRef();
    this.ewsRef = React.createRef();
    this.neitherTfwEwsRef = React.createRef();
    this.tfwYesRef = React.createRef();
    this.tfwNoRef = React.createRef();
    this.tfwNotApplicableRef = React.createRef();
  }

  checkValidation = () => {
    if (loginValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
        showErrorBox: true,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
        showErrorBox: true,
      });
    }
  };

  submit = (event) => {
    event.preventDefault();

    if (loginValid(this.state.formErrors)) {
      var raw = JSON.stringify({
        choice_of_colleges:
          this.state.choiceArr.length === 0 ? null : this.state.choiceArr,
        tfw_ews_consideration: this.state.tfw_ews_consideration,
        has_applied_for_fee_waiver: this.state.tfw_preference,
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        credentials: "include",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/step4`,
        requestOptions
      )
        .then((response) => {
          this.setState({
            loading_button: true,
          });
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              showErrorBox: true,
              submitted_successfully: true,
            });
          } else if (response.status === 401) {
            localStorage.setItem("isLoggedInToGcet", "false");

            this.setState({
              isAuthenticated: false,
            });
            const authProps = {
              isAuth: this.state.isAuthenticated,
            };
            this.props.checkLoginStatus(authProps);
          } else {
            this.setState({
              submitted_successfully: false,
              showErrorBox: true,
              loading_button: false,
            });
          }
          // console.log(response);
          return response.text();
        })
        .then((result) => {
          this.setState({
            backendError: JSON.parse(result).message,
            loading_button: false,
          });
        })
        .catch((error) => {
          this.setState({
            showErrorBox: true,
            submitted_successfully: false,
            backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
            loading_button: false,
          });
          console.log("error", error);
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: `There are errors in the form. <br /><span>${error}</span>`,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });

    if (this.state.order_id_created) {
      // will run for the NEET only flow (which includes payment)
      if (name === "ews_consideration" && this.ewsRef.current.checked) {
        this.setState({
          tfw_ews_consideration: "EWS",
        });
        this.neitherTfwEwsRef.current.checked = false;
      } else if (name === "neither" && this.neitherTfwEwsRef.current.checked) {
        this.setState({
          tfw_ews_consideration: "NONE",
        });
        this.ewsRef.current.checked = false;
      }
    } else {
      // will run for the NEET+GCET flow (no payment)
      if (name === "tfw") {
        if (this.tfwYesRef.current.checked) {
          if (this.tfwRef.current.checked) {
            this.setState({
              tfw_preference: true,
            });
          } else {
            this.tfwYesRef.current.checked = false;
            alert("Please select TFW");
          }
        } else if (this.tfwNoRef.current.checked) {
          if (this.tfwRef.current.checked) {
            this.setState({
              tfw_preference: false,
            });
          } else {
            this.tfwNoRef.current.checked = false;
            alert("Please select TFW");
          }
        } else if (this.tfwNotApplicableRef.current.checked) {
          this.setState({
            tfw_preference: false,
          });
        }
      }

      if (name === "ews_consideration" || name === "tfw_consideration") {
        if (document.getElementById(name).checked) {
          this.neitherTfwEwsRef.current.checked = false;
        }

        if (this.tfwRef.current.checked && this.ewsRef.current.checked) {
          this.setState({
            tfw_ews_consideration: "TFW, EWS",
          });
        } else if (this.ewsRef.current.checked) {
          this.setState({
            tfw_ews_consideration: "EWS",
          });
          this.tfwNotApplicableRef.current.checked = true;
        } else if (this.tfwRef.current.checked) {
          this.setState({
            tfw_ews_consideration: "TFW",
          });
          this.tfwNotApplicableRef.current.checked = false;
        } else {
          this.setState({
            tfw_ews_consideration: "",
          });
        }
      }

      if (name === "neither") {
        if (document.getElementById(name).checked) {
          this.ewsRef.current.checked = false;
          this.tfwRef.current.checked = false;
          this.tfwYesRef.current.checked = false;
          this.tfwNoRef.current.checked = false;
          this.tfwNotApplicableRef.current.checked = true;

          this.setState({
            tfw_ews_consideration: "NONE",
            tfw_preference: false,
          });
        } else {
          this.setState({
            tfw_ews_consideration: "",
          });
        }
      }
    }
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  async componentDidMount() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/`,
        requestOptions
      );
      const data = await response.json();

      if (response.status === 401) {
        localStorage.setItem("isLoggedInToGcet", "false");

        this.setState({
          isAuthenticated: false,
        });
        const authProps = {
          isAuth: this.state.isAuthenticated,
        };
        this.props.checkLoginStatus(authProps);
      }

      let app = data.applicationData;

      this.setState({
        choice_selection_finished: app.step_four_finished,
        step_one_finished: app.step_one_finished,
        step_two_finished: app.step_two_finished,
        step_three_finished: app.step_three_finished,
        step_four_finished: app.step_four_finished,
        final_submission_done: app.is_application_submitted,
        applying_for_bvsc_ah: app.applying_for_bvsc_ah,
        order_id_created: app.is_order_generated,
        // order_id_created: false,
        is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
        choiceArr: app.choice_of_colleges !== null && app.choice_of_colleges,
        tfw_preference: app.has_applied_for_fee_waiver,
        // in backend, has_applied_for_fee_waiver is used for tfw_prefernec in this project. Please make sure you fix it.
      });

      if (app.step_four_finished) {
        this.setState({
          step_four_submitted_before: true,
        });

        if (app.is_order_generated) {
          if (app.tfw_ews_consideration === "EWS") {
            this.ewsRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "EWS",
            });
          } else if (app.tfw_ews_consideration === "NONE") {
            this.neitherTfwEwsRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "NONE",
            });
          }
        } else {
           // set the tfw preference
           if (
            app.has_applied_for_fee_waiver &&
            app.tfw_ews_consideration !== "NONE"
          ) {
            this.tfwYesRef.current.checked = true;
          } else {
            this.tfwNoRef.current.checked = true;
          }

          // set the tfw and ews options
          if (app.tfw_ews_consideration === "TFW, EWS") {
            this.tfwRef.current.checked = true;
            this.ewsRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "TFW, EWS",
            });
          } else if (app.tfw_ews_consideration === "TFW") {
            this.tfwRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "TFW",
            });
          } else if (app.tfw_ews_consideration === "EWS") {
            this.ewsRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "EWS",
            });
          } else if (app.tfw_ews_consideration === "NONE") {
            this.neitherTfwEwsRef.current.checked = true;
            this.tfwNotApplicableRef.current.checked = true;
            this.setState({
              tfw_ews_consideration: "NONE",
            });
          }
        }
      }

      let arrValue = app.choice_of_colleges;

      for (let i = 0; i < arrValue.length; i++) {
        this.state.newValue[i] = {
          value: `${arrValue[i]}`,
          label: `${arrValue[i]}`,
        };
      }

      localStorage.setItem("neet_step_one_finished", app.step_one_finished);
      localStorage.setItem("neet_step_two_finished", app.step_two_finished);
      localStorage.setItem("neet_step_three_finished", app.step_three_finished);
      localStorage.setItem("neet_step_four_finished", app.step_four_finished);
      localStorage.setItem(
        "neet_final_submission_done",
        app.is_roll_no_generated
      );
    } catch (error) {
      console.log("error", error);
    }

    const documentForNeetOnly = [
      {
        id: "1",
        course: "B. Arch.",
        institute: "GCA, Altino, Panaji",
        code: "ARC",
      },
      {
        id: "2",
        course: "M.B.B.S.",
        institute: "GMC, Bambolim",
        code: "MBS",
      },
      { id: "3", course: "B.D.S", institute: "GDC, Bambolim", code: "BDS" },
      {
        id: "4",
        course: "B.H.M.S.",
        institute: "SKHMC, Shiroda",
        code: "BHM",
      },
      { id: "5", course: "B.A.M.S", institute: "GAMRC", code: "BAM" },
      {
        id: "6",
        course: "B.Sc. in Nursing",
        institute: "INE, Bambolim",
        code: "BNG",
      },
      {
        id: "7",
        course: "B.Sc. in Nursing",
        institute: "VINE, Colvale",
        code: "BNV",
      },
      {
        id: "8",
        course: "B.P.T. (Bachelor of Physiotherapy",
        institute: "GMC, Bambolim",
        code: "BPT",
      },
      {
        id: "9",
        course: "B.O.T. (Bachelor of Occupational therapy",
        institute: "GMC, Bambolim",
        code: "BOT",
      },
      {
        id: "10",
        course: "B.Opt. (Bachelor of Optometry)",
        institute: "GMC Bambolim",
        code: "BOP",
      },
      {
        id: "11",
        course: "B.Sc. M.I.T (B.Sc. in Medical Imaging Technology)",
        institute: "GMC, Bambolim",
        code: "BMT",
      },
      {
        id: "12",
        course: "B.Sc. A.T (B.Sc. in Anaesthesia Technology)",
        institute: "GMC Bambolim",
        code: "BAT",
      },
      {
        id: "13",
        course: "B.V.Sc. & A.H.",
        institute: "Maharashtra Animal and Fishery Science University, Nagpur",
        code: "MAF",
      },
      {
        id: "14",
        course: "B.V.Sc. & A.H.",
        institute:
          "Rajiv Gandhi Institute of Veterinary Education & Research Puducherry",
        code: "RVR",
      },
    ];

    const documentForAll = [
      {
        id: "1",
        course: "B. Arch.",
        institute: "GCA, Altino, Panaji",
        code: "ARC",
      },
      {
        id: "2",
        course: "B.E. (Information Technology)",
        institute: "GEC, Farmagudi",
        code: "ITG",
      },
      {
        id: "3",
        course: "B.E. (Computer)",
        institute: "GEC, Farmagudi",
        code: "COG",
      },
      {
        id: "4",
        course: "B.E. (Electronics & Telecom.)",
        institute: "GEC, Farmagudi",
        code: "ETG",
      },
      {
        id: "5",
        course: "B.E. (Electrical & Electronics)",
        institute: "GEC, Farmagudi",
        code: "EEG",
      },
      {
        id: "6",
        course: "B.E. (Mechanical)",
        institute: "GEC, Farmagudi",
        code: "MEG",
      },
      {
        id: "7",
        course: "B.E. (Civil)",
        institute: "GEC, Farmagudi",
        code: "CIV",
      },

      {
        id: "8",
        course: "B.E. (Information Technology)",
        institute: "P.C.C.E, Verna",
        code: "ITP",
      },
      {
        id: "9",
        course: "B.E. (Computer)",
        institute: "P.C.C.E, Verna",
        code: "COP",
      },
      {
        id: "10",
        course: "B.E. (Electronics & Telecom.)",
        institute: "P.C.C.E, Verna",
        code: "ETP",
      },
      {
        id: "11",
        course: "B.E. (Mechanical)",
        institute: "P.C.C.E, Verna",
        code: "MEP",
      },

      {
        id: "12",
        course: "B.E. (Information Technology)",
        institute: "R.I.E.I.T., Shiroda",
        code: "ITR",
      },
      {
        id: "13",
        course: "B.E. (Computer)",
        institute: "R.I.E.I.T., Shiroda",
        code: "COR",
      },
      {
        id: "14",
        course: "B.E. (Electronics & Telecom.)",
        institute: "R.I.E.I.T., Shiroda",
        code: "ETR",
      },
      {
        id: "15",
        course: "B.E. (Mechanical)",
        institute: "R.I.E.I.T., Shiroda",
        code: "MER",
      },

      {
        id: "16",
        course: "B.E. (Computer)",
        institute: "DBCE, Fatorda",
        code: "COD",
      },
      {
        id: "17",
        course: "B.E. (Electronics & Telecom.)",
        institute: "DBCE, Fatorda",
        code: "ETD",
      },
      {
        id: "18",
        course: "B.E. (Mechanical)",
        institute: "DBCE, Fatorda",
        code: "MED",
      },
      {
        id: "19",
        course: "B.E. (Civil)",
        institute: "DBCE, Fatorda",
        code: "CID",
      },

      {
        id: "20",
        course: "B.E. (Computer)",
        institute: "AITD, Assagao",
        code: "COA",
      },
      {
        id: "21",
        course: "B.E. (Electronics & Communi.)",
        institute: "AITD, Assagao",
        code: "ECA",
      },
      {
        id: "22",
        course: "B.E. (Mechanical)",
        institute: "AITD, Assagao",
        code: "MEA",
      },

      { id: "23", course: "B.Pharm.", institute: "GCP, Panaji", code: "PHG" },
      {
        id: "24",
        course: "B.Pharm.",
        institute: "PES, Farmagudi",
        code: "PHP",
      },

      {
        id: "25",
        course: "M.B.B.S.",
        institute: "GMC, Bambolim",
        code: "MBS",
      },
      { id: "26", course: "B.D.S", institute: "GDC, Bambolim", code: "BDS" },
      {
        id: "27",
        course: "B.H.M.S.",
        institute: "SKHMC, Shiroda",
        code: "BHM",
      },
      { id: "28", course: "B.A.M.S", institute: "GAMRC", code: "BAM" },
      {
        id: "29",
        course: "B.Sc. in Nursing",
        institute: "INE, Bambolim",
        code: "BNG",
      },
      {
        id: "30",
        course: "B.Sc. in Nursing",
        institute: "VINE, Colvale",
        code: "BNV",
      },
      {
        id: "31",
        course: "B.P.T. (Bachelor of Physiotherapy",
        institute: "GMC, Bambolim",
        code: "BPT",
      },
      {
        id: "32",
        course: "B.O.T. (Bachelor of Occupational therapy",
        institute: "GMC, Bambolim",
        code: "BOT",
      },
      {
        id: "33",
        course: "B.Opt. (Bachelor of Optometry)",
        institute: "GMC Bambolim",
        code: "BOP",
      },
      {
        id: "34",
        course: "B.Sc. M.I.T (B.Sc. in Medical Imaging Technology)",
        institute: "GMC, Bambolim",
        code: "BMT",
      },
      {
        id: "35",
        course: "B.Sc. A.T (B.Sc. in Anaesthesia Technology)",
        institute: "GMC Bambolim",
        code: "BAT",
      },
      {
        id: "36",
        course: "B.V.Sc. & A.H.",
        institute: "Maharashtra Animal and Fishery Science University, Nagpur",
        code: "MAF",
      },
      {
        id: "37",
        course: "B.V.Sc. & A.H.",
        institute:
          "Rajiv Gandhi Institute of Veterinary Education & Research Puducherry",
        code: "RVR",
      },
    ];

    this.setState({
      documentList: this.state.order_id_created
        ? documentForNeetOnly
        : documentForAll,
    });
  }

  handleSelectChange = (newValue) => {
    this.setState({
      inputValue: newValue,
    });
    let array = [];
    let formErrors = this.state.formErrors;

    for (let i = 0; i < newValue.length; i++) {
      array[i] = newValue[i].label;
    }

    this.setState({
      choiceArr: array,
      newValue: newValue,
    });

    let minimumChoice = this.state.order_id_created ? 10 : 30;

    if (newValue.length < minimumChoice) {
      formErrors.course_choices = this.state.order_id_created
        ? "Please select minimum 10 NEET based courses"
        : "Please select minimum 20 GCET Based courses and 10 NEET based courses";
    } else {
      formErrors.course_choices = "";
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  render() {
    if (
      this.state.isAuthenticated &&
      this.state.submitted_successfully &&
      !this.state.step_two_submitted_before
    ) {
      return <Redirect to="/form-n/preview" />;
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true &&
      this.state.step_one_finished
    ) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Admission Form <span>* Mandatory Fields</span>
                </h1>

                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.step_four_finished,
                    step5: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                      this.state.is_gcet_roll_no_verified,
                  }}
                />
              </div>

              <div className="cell large-12">
                <h2
                  className="application-form__title"
                  style={{ textAlign: "center" }}
                >
                  {this.state.order_id_created
                    ? "Form for Choice of Courses for Admissions to NEET and NATA based Courses"
                    : "Form for Choice of Courses for Admissions to NEET, GCET and NATA based Courses"}
                </h2>

                <h3 style={{ marginBottom: "20px", marginTop: "50px" }}>
                  Instructions
                </h3>
                <ol
                  className="application__list-container"
                  style={{ textAlign: "justify" }}
                >
                  <li className="application__list-item">
                    <span>
                      It is proposed to allot course/institution, based on
                      merit, as per the preferred choice, opted by the
                      applicant.
                      <br />
                      <br />
                      Therefore, all applicants are required to submit their
                      choice of courses, in the order of their preference, in
                      the input field provided below.
                    </span>
                  </li>

                  <li
                    className="application__list-item"
                    style={{
                      display: `${this.state.order_id_created ? "none" : ""}`,
                    }}
                  >
                    <span>
                      Applicants for admission to NEET based courses, along with
                      GCET and/or NATA based courses, are required to indicate
                      maximum 37 choices of courses i.e. 23 courses for GCET
                      based admissions, 13 courses for NEET based admission and
                      NATA based B.Arch. admissions, in order of their relative
                      preference.
                      <br />
                      <div style={{ color: "#2267DB", margin: "15px 0 0 0" }}>
                        e.g. 1st choice - MBBS, 2nd choice - COG, 3rd choice -
                        BDS, 4th choice - PHG, 5th choice - ARC, 6th choice -
                        INE, 7th choice - MEG, etc.
                      </div>
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      It is advisable that applicants exercise options for
                      MAXIMUM number of eligible courses, in order of
                      preference, as per the choice of the applicant, since
                      applicant shall <b>NOT</b> be considered for admission to
                      the courses, which he/she has <b>NOT</b> included /
                      indicated in the Form.{" "}
                      {this.state.order_id_created
                        ? ""
                        : "The list of 37 courses with course code is provided below."}
                      <br />
                      <br />
                      <div
                        style={{
                          color: "red",
                          display: `${
                            this.state.order_id_created ? "none" : "block"
                          }`,
                        }}
                      >
                        MANDATORY - APPLICANT SHALL SELECT MINIMUM 20 GCET BASED
                        COURSES AND MINIMUM 10 NEET BASED COURSES.
                      </div>
                      <div
                        style={{
                          color: "red",
                          display: `${
                            this.state.order_id_created ? "block" : "none"
                          }`,
                        }}
                      >
                        MANDATORY - APPLICANT SHALL SELECT MINIMUM 10 NEET BASED
                        COURSES.
                      </div>
                    </span>
                  </li>

                  <li
                    className="application__list-item"
                    style={{
                      display: `${this.state.order_id_created ? "none" : ""}`,
                    }}
                  >
                    <span>
                      These options exercised shall be used for the allotment of
                      seats in the rounds of allotment of seats, to be conducted
                      for NEET/GCET/NATA based courses. The EARLIER SUBMITTED
                      OPTIONS for the first round of allotment for GCET based
                      courses shall NOT BE VALID for further rounds of
                      admission.
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      Applicant shall be allotted available seat of his/her
                      highest choice submitted in this form, as per his/her rank
                      in the Merit List, and shall have no claim for allotment
                      of seats of his/her lower choice of courses.{" "}
                      {this.state.order_id_created
                        ? ""
                        : "In case applicants are already alloted a higher preference GCET or NATA based course, based on their earlier submitted choices in the earlier rounds of allotment, they will not be considered for allotment of lower choices of courses in this form."}
                      <div
                        style={{
                          color: "#2267DB",
                          margin: "15px 0 0 0",
                          display: `${
                            this.state.order_id_created ? "none" : "block"
                          }`,
                        }}
                      >
                        e.g. an applicant has 1st choice - MBBS, 2nd choice -
                        ETG, 3rd choice - MEG and 4th choice - BDS. This
                        applicant, if has been allotted MEG in earlier round of
                        GCET based allotment, will not be considered for BDS
                        (4th choice in this form) but will continue to be
                        eligible for admission to his 1st and 2nd choice (MBBS
                        and ETG).
                      </div>
                    </span>
                  </li>

                  <li
                    className="application__list-item"
                    style={{
                      display: `${this.state.order_id_created ? "" : "none"}`,
                    }}
                  >
                    <span>
                      Applicants who have appeared ONLY at NEET, are required to
                      indicate MAXIMUM 13 choices, for admissions to 13 courses
                      based on NEET.
                    </span>
                  </li>

                  <li
                    className="application__list-item"
                    style={{
                      display: `${this.state.order_id_created ? "" : "none"}`,
                    }}
                  >
                    <span>
                      Applicants who appeared at NEET and NATA are required to
                      indicate maximum 14 choices, for admissions to 13 courses
                      based on NEET and B.Arch. based on NATA.
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      Applicants eligible in more than <b>ONE</b> category will
                      be allotted available seat in the category, as per his/her
                      highest <b>CHOICE</b> exercised.
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      Applicant who is allotted a seat in a particular round,
                      whether accepted or not, will be considered for allotment
                      of seats in subsequent rounds, for his/her higher choice
                      of courses.
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      Applicants who wish to be considered for{" "}
                      {this.state.order_id_created
                        ? ""
                        : "TFW (Tuition Fee Waiver) and "}
                      EWS (Economically Weaker Section) seats, need to submit
                      applicable{" "}
                      <b>
                        {this.state.order_id_created ? "" : "Income / "}Income
                        &amp; Assets Certificate
                      </b>{" "}
                      (in specified format -{" "}
                      <b>{this.state.order_id_created ? "" : "6/"}9</b> in the
                      Prospectus).
                      <br />
                      <br />
                      If {this.state.order_id_created ? "" : "Income / "} Income
                      &amp; Assets Certificate is not submitted earlier with the
                      application form, self-attested scanned copy in PDF format
                      of the same, with registration number written on the top,
                      needs to be sent on mail to{" "}
                      <a href="mailto:support@goa-degree-admissions.in">
                        support@goa-degree-admissions.in
                      </a>
                      , clearly stating their REGISTRATION NUMBER, followed by{" "}
                      {this.state.order_id_created ? "" : "Income / "} INCOME
                      &amp; ASSETS CERTIFICATE in the SUBJECT of the mail, by
                      24th October, 2020, failing which, applicants may not be
                      considered for allotment of TFW &amp; EWS seat.
                    </span>
                  </li>

                  <li
                    className="application__list-item"
                    style={{
                      display: `${
                        this.state.order_id_created ? "none" : "block"
                      }`,
                    }}
                  >
                    <span>
                      Applicants shall indicate whether TFW seat should be
                      accorded higher priority for allotment or their preferred
                      choice of seat should be accorded higher priority for
                      allotment, by ticking on the option provided in the Form.
                      <br />
                      <br />
                      e.g. <b>Merit No. 185</b> has opted for Mechanical Engg.
                      at GEC (MEG) as <b>First Choice</b> &amp; Electronics
                      &amp; Telecommunication Engg. at RIEIT (ETR) as{" "}
                      <b>Second Choice</b>
                      <br />
                      <br />
                      Applicant is getting Mechanical Engg. at GEC as per his
                      merit (<b>without TFW</b>) but Electronics &amp;
                      Telecommunication Engg. at RIEIT(<b>with TFW</b>). <br />
                      <br />
                      In the above scenario, applicant has to indicate whether
                      he prefers to be allotted Mechanical Engg. - MEG (
                      <b>First Choice</b>) or Electronics &amp;
                      Telecommunication Engg. - ETR (
                      <b>Second Choice, but with TFW</b>).
                    </span>
                  </li>

                  <li className="application__list-item">
                    <span>
                      Applicants are advised to select the correct 3 letter
                      course codes. All applicants are advised to exercise
                      maximum care and caution in indicating their choice of
                      courses, in order of preference,{" "}
                      <b style={{ color: "red" }}>
                        as they shall not be permitted to
                        add/delete/amend/alter/revise/edit the choices, after
                        submission of this form
                      </b>
                      . Hence, all applicants may carefully review their choices
                      after PREVIEW, before clicking 'SAVE AND PROCEED' and
                      'FINAL SUBMIT'.
                    </span>
                  </li>
                </ol>

                <table className="application__table for-choice">
                  <thead>
                    <tr className="application__table__row">
                      <th className="application__table__item heading">
                        Sr. No.
                      </th>
                      <th className="application__table__item heading">
                        Course
                      </th>
                      <th className="application__table__item heading">
                        Institution
                      </th>
                      <th className="application__table__item heading">Code</th>
                    </tr>
                  </thead>

                  <tbody id="table-body">
                    {this.state.documentList.map((item) => (
                      <tr className="application__table__row" key={item.id}>
                        <td className="application__table__item">{item.id}</td>
                        <td className="application__table__item">
                          {item.course}
                        </td>
                        <td className="application__table__item">
                          {item.institute}
                        </td>
                        <td className="application__table__item">
                          {item.code}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <h2 style={{ marginTop: "50px" }}>
                  Choice of Courses in order of preference: <br />
                  <span
                    style={{
                      fontSize: "16px",
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    {this.state.order_id_created
                      ? "Minumum 10 NEET based courses to be selected"
                      : "Minimum 20 GCET based courses and minumum 10 NEET based courses to be selected"}
                  </span>
                </h2>

                <form
                  onSubmit={this.submit}
                  className="application-form__form for-choice"
                  style={{ marginTop: "40px" }}
                >
                  <div className="message__box">
                    <h3 className="message__title">
                      Form for Choice of Courses:
                    </h3>
                    <p className="justify" style={{ marginBottom: "15px" }}>
                      Click on the bar below to select the course codes.{" "}
                      <b>Scroll</b> to see more options.
                      <br />
                      (Students are advised to indicate ALL / MAXIMUM number of
                      choices.)
                    </p>
                    <Select
                      options={
                        this.state.order_id_created
                          ? optionsForNeetOnly
                          : optionsForAll
                      }
                      isMulti
                      components={{ Placeholder }}
                      placeholder={"click here, scroll and select the choices"}
                      value={this.state.newValue}
                      styles={{
                        multiValueLabel: (base) => ({
                          ...base,
                          backgroundColor: "#147ce5",
                          color: "white",
                          fontSize: "18px",
                        }),
                      }}
                      onChange={this.handleSelectChange}
                    />
                  </div>

                  <div className="message__box">
                    <p className="justify">
                      {this.state.order_id_created
                        ? "I wish to be considered for EWS "
                        : "I also wish to be considered for "}
                      (necessary{" "}
                      <b>
                        {this.state.order_id_created ? "" : "Income / "}Income
                        &amp; Assets certificate
                      </b>{" "}
                      to be submitted):
                    </p>

                    <div className={inputStyle["subject__container"]}>
                      {!this.state.order_id_created && (
                        <SubjectCheckbox
                          label="TFW"
                          type="checkbox"
                          name="tfw_consideration"
                          id="tfw_consideration"
                          onChange={this.handleChange}
                          ref={this.tfwRef}
                        />
                      )}
                      <SubjectCheckbox
                        label={this.state.order_id_created ? "Yes" : "EWS"}
                        type="checkbox"
                        name="ews_consideration"
                        id="ews_consideration"
                        onChange={this.handleChange}
                        ref={this.ewsRef}
                      />
                      <SubjectCheckbox
                        label={
                          this.state.order_id_created
                            ? "No"
                            : "Neither TFW nor EWS"
                        }
                        type="checkbox"
                        name="neither"
                        id="neither"
                        onChange={this.handleChange}
                        ref={this.neitherTfwEwsRef}
                      />
                    </div>
                  </div>

                  <div
                    className="message__box"
                    style={{ marginBottom: "30px", marginTop: "30px" }}
                  >
                    <h3 className="message__title">DECLARATION</h3>
                    <div
                      className="input-field-container"
                      style={{ marginBottom: "0" }}
                    >
                      {!this.state.order_id_created && (
                        <>
                          <p className="justify">
                            I state that my preferred choice of courses should
                            be given higher preference in allotment of seat over
                            TFW seats.
                          </p>

                          <div
                            className="subject__container tfw_ews_consideration"
                            style={{
                              margin: "20px 0",
                              minHeight: "0",
                            }}
                          >
                            <SubjectCheckbox
                              type="radio"
                              label="Yes"
                              name="tfw"
                              id="tfw_yes"
                              onChange={this.handleChange}
                              required
                              ref={this.tfwYesRef}
                              disabled={
                                this.state.order_id_created ? true : false
                              }
                            />
                            <SubjectCheckbox
                              type="radio"
                              label="No"
                              name="tfw"
                              id="tfw_no"
                              onChange={this.handleChange}
                              required
                              ref={this.tfwNoRef}
                              disabled={
                                this.state.order_id_created ? true : false
                              }
                            />
                            <SubjectCheckbox
                              type="radio"
                              label="Not Applicable"
                              name="tfw"
                              id="tfw_not_applicable"
                              onChange={this.handleChange}
                              required
                              ref={this.tfwNotApplicableRef}
                              disabled={
                                this.state.order_id_created ? true : false
                              }
                            />
                          </div>
                        </>
                      )}
                      <p className="justify">
                        <b>
                          Allotment of seats by the Admission Committee, on
                          Merit, based on my above preferences, shall be FINAL
                          &amp; BINDING.
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="choice_form_preview">
                    <h2>Preview</h2>
                    <p style={{ color: "red" }}>
                      Kindly preview the choice of courses exercised. In case
                      you wish to revise your choices go back to form and edit,
                      before clicking 'SAVE AND PROCEED' and 'FINAL SUBMIT'
                      buttons.
                    </p>
                    <p>Choice of Courses in order of preference </p>
                    <table>
                      <thead>
                        <tr>
                          <th>Choice No.</th>
                          <th>Course Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1st</td>
                          <td>{this.state.choiceArr[0]}</td>
                        </tr>
                        <tr>
                          <td>2nd</td>
                          <td>{this.state.choiceArr[1]}</td>
                        </tr>
                        <tr>
                          <td>3rd</td>
                          <td>{this.state.choiceArr[2]}</td>
                        </tr>
                        <tr>
                          <td>4th</td>
                          <td>{this.state.choiceArr[3]}</td>
                        </tr>
                        <tr>
                          <td>5th</td>
                          <td>{this.state.choiceArr[4]}</td>
                        </tr>
                        <tr>
                          <td>6th</td>
                          <td>{this.state.choiceArr[5]}</td>
                        </tr>
                        <tr>
                          <td>7th</td>
                          <td>{this.state.choiceArr[6]}</td>
                        </tr>
                        <tr>
                          <td>8th</td>
                          <td>{this.state.choiceArr[7]}</td>
                        </tr>
                        <tr>
                          <td>9th</td>
                          <td>{this.state.choiceArr[8]}</td>
                        </tr>
                        <tr>
                          <td>10th</td>
                          <td>{this.state.choiceArr[9]}</td>
                        </tr>
                        <tr>
                          <td>11th</td>
                          <td>{this.state.choiceArr[10]}</td>
                        </tr>
                        <tr>
                          <td>12th</td>
                          <td>{this.state.choiceArr[11]}</td>
                        </tr>
                        <tr>
                          <td>13th</td>
                          <td>{this.state.choiceArr[12]}</td>
                        </tr>
                        <tr>
                          <td>14th</td>
                          <td>{this.state.choiceArr[13]}</td>
                        </tr>
                      </tbody>
                      <tbody
                        style={{
                          display: `${
                            this.state.order_id_created ? "none" : ""
                          }`,
                        }}
                      >
                        <tr>
                          <td>15th</td>
                          <td>{this.state.choiceArr[14]}</td>
                        </tr>
                        <tr>
                          <td>16th</td>
                          <td>{this.state.choiceArr[15]}</td>
                        </tr>
                        <tr>
                          <td>17th</td>
                          <td>{this.state.choiceArr[16]}</td>
                        </tr>
                        <tr>
                          <td>18th</td>
                          <td>{this.state.choiceArr[17]}</td>
                        </tr>
                        <tr>
                          <td>19th</td>
                          <td>{this.state.choiceArr[18]}</td>
                        </tr>
                        <tr>
                          <td>20th</td>
                          <td>{this.state.choiceArr[19]}</td>
                        </tr>
                        <tr>
                          <td>21st</td>
                          <td>{this.state.choiceArr[20]}</td>
                        </tr>
                        <tr>
                          <td>22nd</td>
                          <td>{this.state.choiceArr[21]}</td>
                        </tr>
                        <tr>
                          <td>23rd</td>
                          <td>{this.state.choiceArr[22]}</td>
                        </tr>
                        <tr>
                          <td>24th</td>
                          <td>{this.state.choiceArr[23]}</td>
                        </tr>
                        <tr>
                          <td>25th</td>
                          <td>{this.state.choiceArr[24]}</td>
                        </tr>
                        <tr>
                          <td>26th</td>
                          <td>{this.state.choiceArr[25]}</td>
                        </tr>
                        <tr>
                          <td>27th</td>
                          <td>{this.state.choiceArr[26]}</td>
                        </tr>
                        <tr>
                          <td>28th</td>
                          <td>{this.state.choiceArr[27]}</td>
                        </tr>
                        <tr>
                          <td>29th</td>
                          <td>{this.state.choiceArr[28]}</td>
                        </tr>
                        <tr>
                          <td>30th</td>
                          <td>{this.state.choiceArr[29]}</td>
                        </tr>
                        <tr>
                          <td>31st</td>
                          <td>{this.state.choiceArr[30]}</td>
                        </tr>
                        <tr>
                          <td>32nd</td>
                          <td>{this.state.choiceArr[31]}</td>
                        </tr>
                        <tr>
                          <td>33rd</td>
                          <td>{this.state.choiceArr[32]}</td>
                        </tr>
                        <tr>
                          <td>34th</td>
                          <td>{this.state.choiceArr[33]}</td>
                        </tr>
                        <tr>
                          <td>35th</td>
                          <td>{this.state.choiceArr[34]}</td>
                        </tr>
                        <tr>
                          <td>36th</td>
                          <td>{this.state.choiceArr[35]}</td>
                        </tr>
                        <tr>
                          <td>37th</td>
                          <td>{this.state.choiceArr[36]}</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      <span>
                        {this.state.order_id_created
                          ? "I wish to be considered for EWS"
                          : "I also wish to be considered for"}
                      </span>
                      :{" "}
                      {this.state.order_id_created
                        ? this.state.tfw_ews_consideration === "EWS"
                          ? "Yes"
                          : "No"
                        : this.state.tfw_ews_consideration}
                    </p>
                    <p
                      style={{
                        display: this.state.order_id_created ? "none" : "block",
                      }}
                    >
                      <span>
                        I state that my preferred choice of courses should be
                        given higher preference in allotment of seat over TFW
                        seats:
                      </span>
                      {this.state.tfw_preference ? "Yes" : "No"}
                    </p>
                  </div>

                  <div className="pre-footer">
                    <p
                      id="backend-error"
                      className={`${
                        this.state.submitted_successfully
                          ? "success"
                          : "failure"
                      } ${this.state.showErrorBox ? "show" : ""}`}
                      dangerouslySetInnerHTML={this.addBackendError()}
                    ></p>
                    <div className="button-grid">
                      <div className="pre-footer__navigator">
                        <Link
                          to="/form-n/category"
                          className="button button--grey prev"
                          style={{ marginRight: "30px" }}
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M19.017 22.777C19.623 22.443 20 21.791 20 21.074L20 2.92604C20 2.20904 19.623 1.55704 19.017 1.22304C18.439 0.905038 17.76 0.927038 17.202 1.28104L2.887 10.355C2.331 10.706 2 11.321 2 12C2 12.679 2.331 13.294 2.886 13.646L17.201 22.72C17.76 23.073 18.439 23.095 19.017 22.777Z" />
                          </svg>
                          PREVIOUS
                        </Link>

                        <Link
                          to="/form-n/preview"
                          className="button button--grey next"
                          style={{
                            display: `${
                              this.state.step_four_finished
                                ? "inline-block"
                                : "none"
                            }`,
                          }}
                        >
                          NEXT
                          <svg viewBox="0 0 24 24">
                            <path d="M4.983 1.22296C4.377 1.55696 4 2.20896 4 2.92596L4 21.074C4 21.791 4.377 22.443 4.983 22.777C5.561 23.095 6.24 23.073 6.798 22.719L21.113 13.645C21.669 13.294 22 12.679 22 12C22 11.321 21.669 10.706 21.114 10.354L6.799 1.27996C6.24 0.926963 5.561 0.904961 4.983 1.22296Z" />
                          </svg>
                        </Link>
                      </div>

                      <button
                        type="submit"
                        className={`button ${
                          this.state.loading_button ? "loading" : ""
                        }`}
                        id="submit-button"
                      >
                        SAVE AND PROCEED
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true
    ) {
      return <Redirect to="/form-n/category" />;
    } else if (this.state.isAuthenticated && this.state.final_submission_done) {
      return <Redirect to="/form-n/profile" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}
