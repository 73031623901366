import React, { useState } from "react";
import style from "./faqItem.module.scss";
import chevron from "../../images/chevron-down.png";

export const FaqItem = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  let toggleItem = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={style["faq__section"]}>
      <button className={style["collapse__button"]} onClick={toggleItem}>
        <img src={chevron} alt="dropdown icon" className={`${style.icon} ${isOpen && style.invert}`} />{" "}
        {question}
      </button>
      {isOpen && children}
    </div>
  );
};
