import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "universal-cookie";
// components
import { Input } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop.jsx";

const emailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const loginValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class Login extends Component {
  constructor(props) {
    super(props);

    this.error = React.createRef();

    this.state = {
      loading_button: "",
      serverError: "",
      backendError: "",
      submitted_successfully: false,
      showErrorBox: false,
      isAuthenticated: "",
      is_submitted: "",
      is_paid: "",
      is_seat_alloted: "",
      orderID: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      email: "",
      password: "",
      formErrors: {
        email: "",
        password: "",
        reCaptcha: "please verify you are not a robot",
      },
    };
  }

  checkValidation = () => {
    if (loginValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      // let arr = [];
      // for(let i=0;  i<error.length; i++){
      //     arr[i] = `<li>${error[i]}</li><li>${error[i]}</li>`
      // }
      this.setState({
        backendError: error,
        showErrorBox: true,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        showErrorBox: true,
      });
    }
  };

  login = (event) => {
    event.preventDefault();

    if (loginValid(this.state.formErrors)) {
      this.setState({
        loading_button: true,
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_API_URL}/accounts/login`, requestOptions)
        .then((response) => {
          return Promise.all([
            Promise.resolve(response.status),
            response.json(),
          ]);
        })
        .then((result) => {
          const data=result[1];
          if (result[0] >= 200 && result[0] < 300) {
            var expires = new Date(Date.now() + 3 * 60 * 60 * 1000);
            const cookies = new Cookies();
            cookies.set("token", data.token, { path: "/", expires });
            localStorage.setItem("isLoggedInToGcet", "true");
            this.setState({
              isAuthenticated: true,
              backendError: `${data.message}\n`,
              loading_button: false,
            });
          } else {
            this.setState({
              backendError: `${data.message}\n`,
              loading_button: false,
              showErrorBox: true,
            });
          }

          const authProps = {
            isAuth: this.state.isAuthenticated,
          };

          this.props.checkLoginStatus(authProps);
        })
        .catch((error) => {
          this.setState({
            backendError: `${error}. REFRESH THE PAGE. Check your internet connection`,
            loading_button: false,
            showErrorBox: true,
          });

          // document.getElementById('button').classList.remove('loading');
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: `There are errors in the form. <br /><span>${error}</span>`,
      });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "email") {
      formErrors.email =
        emailRegex.test(value) && value.length > 0
          ? ""
          : "invalid email address";
    }

    if (name === "password") {
      formErrors.password =
        value.length > 7
          ? ""
          : "password length should be 8 or more characters";
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  verifyCallback = (response) => {
    let formErrors = this.state.formErrors;
    if (response) {
      formErrors.reCaptcha = "";
    } else {
      formErrors.reCaptcha = "please verify you are not a robot";
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to="/myprofile" />;
    } else if (this.state.isAuthenticated === false) {
      return <Redirect to="/" />;
    }
    return (
      <div className="register content-wrapper">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-12">
              <h1 className="register__title">Login</h1>
            </div>

            <div className="cell large-6">
              <form onSubmit={this.login} className="register__form">
                <Input
                  label="Email Address"
                  type="email"
                  value={this.state.email}
                  name="email"
                  id="email"
                  onChange={this.handleChange}
                  required
                  err={this.state.formErrors.email}
                />

                <Input
                  label="Password"
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password"
                  onChange={this.handleChange}
                  required
                  err={this.state.formErrors.password}
                />

                <p className="forgot-password">
                  <Link to="/forgot">Forgot Password?</Link>
                </p>

                <ReCAPTCHA
                  sitekey="6LdjCRIeAAAAAEzKdxutgx22QPtvrQbktHGPbxrc"
                  onChange={this.verifyCallback}
                />
                <p
                  id="backend-error"
                  className={`${
                    this.state.submitted_successfully ? "success" : "failure"
                  } ${this.state.showErrorBox ? "show" : ""}`}
                  dangerouslySetInnerHTML={this.addBackendError()}
                ></p>
                <button
                  type="submit"
                  className={`button ${
                    this.state.loading_button ? "loading" : ""
                  }`}
                  id="button"
                >
                  Login to your acccount
                </button>

                <p className="forgot-password register">
                  <Link to="/register">
                    Don't have an account?{" "}
                    <span style={{ color: "#4285F4", fontWeight: "500" }}>
                      Register here
                    </span>
                  </Link>
                </p>
                <p className="forgot-password register">
                  <Link to="/resend">
                    Account not verified? Did not receive the verification mail?{" "}
                    <span style={{ color: "#4285F4", fontWeight: "500" }}>
                      Click here
                    </span>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}