import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ScrollToTop from "../../util/ScrollToTop";

export class Success extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_seat_alloted: "",
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
    };
  }

  componentDidMount() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/online-application/application-details`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");

          this.setState({
            isAuthenticated: false,
          });
          const authProps = {
            isAuth: this.state.isAuthenticated,
          };
          this.props.checkLoginStatus(authProps);
        }

        return response.text();
      })
      .then((result) => {
        let app = JSON.parse(result).applicationDetails;

        if (app.is_seat_alloted) {
          this.setState({
            is_seat_alloted: true,
          });
        } else {
          this.setState({
            is_seat_alloted: false,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  downloadPdf = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      credentials: "include",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/online-application/download-admit-card`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");
          const authProps = {
            isAuth: false,
          };
          this.props.checkLoginStatus(authProps);
        }
        return response.text();
      })
      .then((result) => {
        const file = new Blob([new Uint8Array(JSON.parse(result).pdf.data)], {
          type: "application/pdf",
        });
        console.log(file);
        var a = document.createElement("a");
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = `${JSON.parse(result).applicantDetails.rollNo}_${
          JSON.parse(result).applicantDetails.name
        }`;
        document.body.appendChild(a);
        a.click();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };
  render() {
    if (this.state.isAuthenticated) {
      return (
        <div>
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <div className="content-wrapper">
                  <div className="simple-page">
                    <h1 className="simple-page__title">
                      Registration is successful.
                    </h1>
                    <p className="simple-page__para">
                      <b>
                        Acknowledgment Card will be downloaded automatically. A
                        copy will be emailed to the student's email ID
                      </b>
                    </p>

                    <p className="simple-page__para">
                      If the <b>Acknowledgment card</b> is not downloaded
                      automatically, please click on the button below
                    </p>
                    <Link
                      to="/myprofile"
                      className="button"
                      onClick={this.downloadPdf}
                    >
                      Download Acknowledgment Card
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToTop />
          </div>
        </div>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}
