import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

// components
import { Input } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop"

const emailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backendError: "",
      loading_button: "",
      submitted_successfully: false,
      showErrorBox: false,
      toThankyou: "",
      email: "",
      password: "",
      confirm_password: "",
      statusCode: "",
      formErrors: {
        email: "",
        password: "",
        confirm: "",
        id: "",
      },
    };
  }

  register = (event) => {
    event.preventDefault();
    this.setState({
      loading_button: true,
    });

    if (formValid(this.state.formErrors)) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.confirm_password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
console.log(requestOptions)
      console.log(`${process.env.REACT_APP_API_URL}/accounts/register`)
      fetch(`${process.env.REACT_APP_API_URL}/accounts/register`, requestOptions)
        .then((response) => {
          this.setState({
            showErrorBox: true,
          });
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              statusCode: true,
              submitted_successfully: true,
            });
          } else {
            this.setState({
              submitted_successfully: false,
            });
            console.log(response);
          }
          return response.text();
        })
        .then((result) => {
          this.setState({
            backendError: JSON.parse(result).message,
            loading_button: false,
          });

          if (this.state.statusCode) {
            this.setState({
              toThankyou: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
            loading_button: false,
            showErrorBox: true,
            submitted_successfully: false,
          });
          console.log("error", error);
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: `There are errors in the form. <br /> <span>${error}</span>`,
        loading_button: false,
      });
    }
  };

  checkValidation = () => {
    if (formValid(this.state.formErrors)) {
      let error = "";
      this.setState({
        showErrorBox:false,
        backendError: error,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
      });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "email") {
      formErrors.email =
        emailRegex.test(value) && value.length > 0
          ? ""
          : "invalid email address";
    }

    if (name === "password") {
      formErrors.password =
        value.length > 7
          ? ""
          : "password length should be 8 or more characters";
    }

    if (name === "confirm_password") {
      formErrors.confirm =
        value === this.state.password ? "" : "passwords so not match";
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  render() {
    if (this.state.toThankyou) {
      return <Redirect to="/verify-email" />;
    }

    return (
      <div className="register content-wrapper">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-12">
              <h1 className="register__title">Register</h1>
            </div>

            <div className="cell large-6">
              <ol className="register__list">
                <li className="register__list__item">
                  You must ENTER a valid Email ID
                </li>
                <li className="register__list__item">
                  Password MUST have minimum 8 characters – a combination of
                  alphabets, numbers, special characters.
                </li>
                <li className="register__list__item">
                  Confirm the password by entering it twice.
                </li>
                <li className="register__list__item">CLICK ON - SIGN UP</li>
                <li className="register__list__item">
                  Verify your email id by clicking on the link sent to your
                  email.
                </li>
              </ol>
            </div>

            <div className="cell large-6">
              <form onSubmit={this.register} className="register__form">
                <Input
                  label="Email Address"
                  type="email"
                  value={this.state.email}
                  name="email"
                  id="email"
                  onChange={this.handleChange}
                  required
                  err={this.state.formErrors.email}
                />

                <Input
                  label="Password"
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password"
                  onChange={this.handleChange}
                  required
                  err={this.state.formErrors.password}
                />

                <Input
                  label="Confirm Password"
                  type="password"
                  value={this.state.confirm}
                  name="confirm_password"
                  id="confirm_password"
                  onChange={this.handleChange}
                  required
                  err={this.state.formErrors.confirm}
                />

                <p
                  id="backend-error"
                  className={`${
                    this.state.submitted_successfully ? "success" : "failure"
                  } ${this.state.showErrorBox ? "show" : "hide"}`}
                  dangerouslySetInnerHTML={this.addBackendError()}
                ></p>

                {/* <input type="button" value="sign up" className="button" /> */}
                <button
                  type="submit"
                  className={`button ${
                    this.state.loading_button ? "loading" : ""
                  }`}
                >
                  sign up
                </button>
                <p className="forgot-password register">
                  <Link to="/login">
                    Already have an account?{" "}
                    <span style={{ color: "#4285F4", fontWeight: "500" }}>
                      Login here
                    </span>
                  </Link>
                </p>
                <p className="forgot-password register">
                  <Link to="/resend">
                    Account not verified? Did not receive the verification mail?{" "}
                    <span style={{ color: "#4285F4", fontWeight: "500" }}>
                      Resend verification link
                    </span>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
