import React, { Component } from "react";
import { Link } from "react-router-dom";

// components
import ScrollToTop from "../../../util/ScrollToTop.jsx";
import { FaqItem } from "../../../components/FaqItem/index";

// images
import signBox from "../../../images/sign-box.jpg";
import signScanGif from "../../../images/sign-scan-vr.gif";
import signError from "../../../images/sign-error.jpeg";
import signCompare from "../../../images/signCompare.png";

import style from "./faq.module.scss";

export class FAQ extends Component {
  render() {
    return (
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-12">
              <div className="content-wrapper">
                <div className={style.faq}>
                  <h1 className={style["faq__title"]}>
                    Frequently Asked Questions
                  </h1>

                  <FaqItem question="How to scan photo and image">
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          To scan photo and signature, you will have to download{" "}
                          <span style={{ fontWeight: "600" }}>
                            Microsoft Lens
                          </span>{" "}
                          from Google Play Store or Apple AppStore in your
                          phone. If your phone doesn't support Microsoft Lens,
                          you may download any relevant app or software.
                          <br />
                          <br />
                          You may refer to this video or follow the instructions
                          below.
                        </p>
                      </div>

                      <iframe
                        className={style["faq__video"]}
                        src="https://www.youtube.com/embed/M3wNLYT-LoA"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        title="tutorial video"
                        allowFullScreen
                      ></iframe>

                      <div className={style["collapse__text-container"]}>
                        <h4 className={style["collapse__title"]}>
                          Steps to follow
                        </h4>
                        <ol className={style["collapse__list-container"]}>
                          <li className={style["collapse__list-item"]}>
                            Draw a box on a blank paper with dimensions [3.5 cm
                            (width) x 1.5 cm (Height)]
                          </li>
                          <li className={style["collapse__list-item"]}>
                            Sign inside the box you have drawn. (Refer to the
                            image below)
                          </li>
                        </ol>

                        <figure>
                          <img src={signBox} alt="drawing signature box" />
                          <figcaption>
                            A 3.5cm x 1.5cm box drawn on an A4 size paper.
                            Inside the box is the signature
                          </figcaption>
                        </figure>
                      </div>

                      <div className={style["collapse__text-container"]}>
                        <ol className={style["collapse__list-container"]}>
                          <li className={style["collapse__list-item"]}>
                            Scan the photo using Microsoft Lens
                          </li>
                          <li className={style["collapse__list-item"]}>
                            To scan, take the camera close to the paper
                          </li>
                        </ol>

                        <figure>
                          <img src={signScanGif} alt="drawing signature box" />
                          <figcaption>
                            Signature is getting scanned with Microsoft Office
                            Lens mobile app. Red rectangle indicates the area to
                            be captured.{" "}
                          </figcaption>
                        </figure>
                      </div>

                      <div className={style["collapse__text-container"]}>
                        <ol className={style["collapse__list-container"]}>
                          <li className={style["collapse__list-item"]}>
                            Once the signature is scanned, scan your passport
                            size photograph in similar manner
                          </li>
                          <li className={style["collapse__list-item"]}>
                            Upload the scanned images while filling forms
                          </li>
                        </ol>
                      </div>

                      <div className={style["collapse__text-container"]}>
                        <h4 className={style["collapse__title"]}>
                          Upload the images properly
                        </h4>
                        <ol className={style["collapse__list-container"]}>
                          <li className={style["collapse__list-item"]}>
                            If the signature is not uploaded properly, as shown
                            in the image below, please rotate the image using
                            mobile phone image editor.
                          </li>
                          <li className={style["collapse__list-item"]}>
                            Upload the signature again after rotating.
                          </li>
                        </ol>
                      </div>
                      <figure className={style["big"]}>
                        <img
                          src={signCompare}
                          alt="showing how to upload sign correctly"
                        />
                        <figcaption>
                          Make sure your sign is visible properly.
                        </figcaption>
                      </figure>
                    </div>
                  </FaqItem>

                  <FaqItem question="How to reduce the the file size of the image?">
                    <div className={style["collapse__content"]}>
                      <figure>
                        <img
                          src={signError}
                          alt="showing error while uploading sign"
                        />
                        <figcaption>
                          The text in the red is the error.
                        </figcaption>
                      </figure>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          The size of the photo should be less than 200kB and
                          the size of the signature should be less than 30kB. If
                          the above error comes, use{" "}
                          <a
                            href="http://compressor.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            compressor.io
                          </a>{" "}
                          or{" "}
                          <a
                            href="https://www.reduceimages.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            reduceimages.com
                          </a>{" "}
                          to reduce the image size.
                          <br />
                          <br />
                          The shortcut to reduce the image size is to share the
                          photo on whatsapp. Whatsapp compresses the image size
                          automatically.
                        </p>
                      </div>
                    </div>
                  </FaqItem>

                  <FaqItem question="Can the form be edited after submission?">
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          No, you cannot edit the form once submitted.
                        </p>
                      </div>
                    </div>
                  </FaqItem>

                  <FaqItem question="Which browsers should I use?">
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          The portal works fine on the latest{" "}
                          <b>Google Chrome</b>, Mozilla Firefox and Microsoft
                          Edge browsers.
                        </p>
                      </div>
                    </div>
                  </FaqItem>

                  {/* <FaqItem
                    question={"Internal Server Error in Center Selection"}
                  >
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          This problem usually arises if you have registered
                          with iPhone and iPad. If you ave used any of these
                          devices to fill the form, kindly send us your photo
                          and signature to the following email{" "}
                          <a href="mailto:support@goacet.in">
                            support@goacet.in
                          </a>
                          . Please check back again in 24 hours, if the issue
                          still persists, please call and inform.
                        </p>
                      </div>
                    </div>
                  </FaqItem> */}

                  <FaqItem question="Getting error Link expired while trying to verify email.">
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          This happens when you click on request for a new
                          verification link. When a new verification link is
                          sent, the old one gets expired. To solve this error,
                          check for the latest email or follow the steps below.
                        </p>
                        <ol className={style["collapse__list-container"]}>
                          <li className={style["collapse__list-item"]}>
                            Delete all the emails received from
                            support@goa-degree-admissions.in
                          </li>
                          <li className={style["collapse__list-item"]}>
                            Request for a new verification link{" "}
                            <Link to="/resend">here</Link>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </FaqItem>

                  {/* <FaqItem question="Payment fails due to Invalid Contact Number">
                    .
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          Please go to the checkout page (goacet.in/checkout)
                          and see if the phone number you entered is correct and
                          all 10 digits are perfectly visible.
                          <br />
                          <br />
                          If the contact number is correct, and the payment is
                          still not going through, please create a new account
                          with a different email id and enter a different number
                          this time.
                        </p>
                      </div>
                    </div>
                  </FaqItem> */}

                  {/* <FaqItem question="Payment not working">
                    <div className={style["collapse__content"]}>
                      <div className={style["collapse__text-container"]}>
                        <p className={style["collapse__text"]}>
                          If the payment fails, please use some other
                          debit/credit card or other payment modes such as{" "}
                          <b>Net Banking</b>.
                        </p>
                      </div>
                    </div>
                  </FaqItem> */}
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      </div>
    );
  }
}
