import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../util/ScrollToTop.jsx";
// import eligibility_list from "./pdf/eligibility_list.pdf";

export class HomeFormN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.auth.isAuthenticated,
    };
  }

  render() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell large-12">
            <div className="content-wrapper">
              <div className="home">
                <h1 className="home__title">Online Application for Admissions Closed</h1>

                <p className="home__para">
                This site is presently closed.<br></br>For admission related queries, applicants may contact Directorate of Technical Education on email. <br></br> 
                <strong>dtegoaadm-deg@gov.in </strong> is the contact email address.
                </p>

                
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}
