import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import style from "../../styles/stepper.module.scss";

class NStepper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step_one_finished:
        localStorage.getItem("neet_step_one_finished") === "true"
          ? true
          : false,
      step_two_finished:
        localStorage.getItem("neet_step_two_finished") === "true"
          ? true
          : false,
      step_three_finished:
        localStorage.getItem("neet_step_three_finished") === "true"
          ? true
          : false,
      // step_four_finished:
      //   localStorage.getItem("neet_step_four_finished") === "true"
      //     ? true
      //     : false,
      neet_final_submission_done:
        localStorage.getItem("neet_final_submission_done") === "true"
          ? true
          : false,
    };
  }

  render() {
    return (
      <>
        <p className={style["stepper__title"]}>Progress Bar</p>
        <div className={style["stepper"]}>
          <NavLink
            to="/form-n/basic-details"
            activeClassName={style["stepper__step--active"]}
            className={`${style["stepper__step"]} ${
              this.props.step.step1 || this.state.step_one_finished
                ? style.showTick
                : ""
            }`}
            style={{ pointerEvents: `${this.props.step.step4 ? "none" : ""}` }}
          >
            <p className={style["stepper__step__number"]}>step 1</p>
            <p className={style["stepper__step__title"]}>Basic Details</p>
          </NavLink>

          <NavLink
            to="/form-n/academic-details"
            activeClassName={style["stepper__step--active"]}
            className={`${style["stepper__step"]} ${
              this.props.step.step2 || this.state.step_two_finished
                ? style.showTick
                : ""
            } ${
              this.props.step.step1 || this.state.step_one_finished
                ? ""
                : style.disable
            }`}
            style={{ pointerEvents: `${this.props.step.step4 ? "none" : ""}` }}
          >
            <p className={style["stepper__step__number"]}>step 2</p>
            <p className={style["stepper__step__title"]}>Academic Details</p>
          </NavLink>

          <NavLink
            to="/form-n/category"
            activeClassName={style["stepper__step--active"]}
            className={`${style["stepper__step"]} ${
              this.props.step.step3 || this.state.step_three_finished
                ? style.showTick
                : ""
            } ${
              this.props.step.step2 || this.state.step_two_finished
                ? ""
                : style.disable
            }`}
            style={{ pointerEvents: `${this.props.step.step4 ? "none" : ""}` }}
          >
            <p className={style["stepper__step__number"]}>step 3</p>
            <p className={style["stepper__step__title"]}>Select Category</p>
          </NavLink>

          {/* <NavLink
            to="/form-n/choice-form"
            activeClassName={style["stepper__step--active"]}
            className={`${style["stepper__step"]} ${
              this.props.step.step4 || this.state.step_four_finished
                ? style.showTick
                : ""
            } ${
              this.props.step.step3 || this.state.step_three_finished
                ? ""
                : style.disable
            }`}
            style={{ pointerEvents: `${this.props.step.step5 ? "none" : ""}` }}
          >
            <p className={style["stepper__step__number"]}>step 4</p>
            <p className={style["stepper__step__title"]}>Choice of Courses</p>
          </NavLink> */}

          <NavLink
            to="/form-n/preview"
            activeClassName={style["stepper__step--active"]}
            className={`${style["stepper__step"]} ${
              this.props.step.step4 ? style.showTick : ""
            } ${
              this.props.step.step3 || this.state.step_three_finished
                ? ""
                : style.disable
            }`}
            style={{ pointerEvents: `${this.props.step.step4 ? "none" : ""}` }}
          >
            <p className={style["stepper__step__number"]}>step 4</p>
            <p className={style["stepper__step__title"]}>
              {this.props.step.is_gcet_roll_no_verified
                ? "Submit"
                : "Pay & Submit"}
            </p>
          </NavLink>
        </div>
      </>
    );
  }
}

export default NStepper;
