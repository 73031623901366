import React, { Component } from "react";

// components
import { Input } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop.jsx";

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_button: "",
      backendError: "",
      showErrorBox: false,
      password: "",
      confirm_password: "",
      successful_submission: false,
      // token: '',

      formErrors: {
        password: "",
        confirm_password: "",
      },
    };
  }

  checkValidation = () => {
    if (formValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "password") {
      formErrors.password =
        value.length > 7
          ? ""
          : "password length should be 8 or more characters";
    }

    if (name === "confirm_password") {
      formErrors.confirm_password =
        value === this.state.password ? "" : "passwords do not match";
    }

    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  submit_email = (event) => {
    event.preventDefault();
    this.setState({
      loading_button: true,
    });

    if (formValid(this.state.formErrors)) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const url_array = window.location.toString().split("/");
      const uidb64 = url_array[url_array.length-2];
      const token = url_array[url_array.length-1];

      console.log(token,uidb64);
      var raw = JSON.stringify({
        password: this.state.password,
        confirmPassword: this.state.confirm_password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/accounts/reset-password/`+uidb64+"/"+token,
        requestOptions
      )
        .then((response) => {
          this.setState({
            showErrorBox: true,
          });
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              successful_submission: true,
            });
          } else {
            this.setState({
              successful_submission: false,
            });
          }
          return response.text();
        })
        .then((result) => {
          console.log(result);
          this.setState({
            backendError: JSON.parse(result).message,
            loading_button: false,
            showErrorBox: true,
          });
        })
        .catch((error) => {
          this.setState({
            backendError: `${error}. REFRESH THE PAGE. Check your internet connection`,
            loading_button: false,
            showErrorBox: true,
          });
          console.log("error", error);
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: `There are errors in the form. <br /> <span>${error}</span>`,
        loading_button: false,
      });
    }
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  render() {
    return (
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-5">
              <div className="content-wrapper">
                <div className="home">
                  <h1 className="home__title">Reset your password</h1>

                  <form onSubmit={this.submit_email} className="register__form">
                    <Input
                      label="New Password"
                      type="password"
                      value={this.state.password}
                      name="password"
                      id="password"
                      onChange={this.handleChange}
                      required
                      err={this.state.formErrors.password}
                    />
                    <Input
                      label="Confirm New Password"
                      type="password"
                      value={this.state.confirm_password}
                      name="confirm_password"
                      id="confirm_password"
                      onChange={this.handleChange}
                      required
                      err={this.state.formErrors.confirm_password}
                    />

                    <p
                      id="backend-error"
                      className={`${
                        this.state.successful_submission ? "success" : "failure"
                      } ${this.state.showErrorBox ? "show" : ""}`}
                      dangerouslySetInnerHTML={this.addBackendError()}
                    ></p>
                    <button
                      type="submit"
                      className={`button ${
                        this.state.loading_button ? "loading" : ""
                      }`}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      </div>
    );
  }
}
