import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Cookies from "universal-cookie";

// comoponents
import { Input, SubjectCheckbox } from "../../components/Input/index";
import ScrollToTop from "../../util/ScrollToTop";
import NStepper from "./NStepper.jsx";

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  return valid;
};

export class NAcademicDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated:
        localStorage.getItem("isLoggedInToGcet") === "true" ? true : false,
      // isAuthenticated: true,
      is_submitted: "",
      componentDidMount: "",
      loading_button: "",
      backendError: "",
      steps: "",
      orderID_added: false,
      toCheckout: false,
      submitted_successfully: false,
      final_submission_done: false,
      last_step: "",
      showErrorBox: false,

      neet_roll_no: "",
      neet_rank: "",
      neet_percentage: "",

      hssc_board: "",
      hssc_board_year: "",
      hssc_board_seat_number: "",

      hssc_total_marks: "",
      hssc_total_maximum_marks: "",
      hssc_english_marks: "",
      hssc_english_maximum_marks: "",
      hssc_physics_marks: "",
      hssc_physics_maximum_marks: "",
      hssc_maths_marks: "",
      hssc_maths_maximum_marks: "",
      hssc_chemistry_marks: "",
      hssc_chemistry_maximum_marks: "",
      hssc_biology_marks: "",
      hssc_biology_maximum_marks: "",
      hssc_others_marks: "",
      hssc_others_maximum_marks: "",

      applying_for_bvsc_ah: false,
      is_applying_for_neet: false,
      // to be changed
      step_one_finished: true,
      step_two_finished: false,
      step_three_finished: false,
      step_four_finished: false,

      step_two_submitted_before: false,
      formErrors: {
        exam: "",
        board: "",
        exam_year: "",
        exam_seat_number: "",
        exam_selection: "",
      },
    };

    this.bvscYesRef = React.createRef();
    this.bvscNoRef = React.createRef();
    this.neetYesRef = React.createRef();
   // this.neetYesRef.current.checked=true;
    this.neetNoRef = React.createRef();
    //this.neetNoRef.current.checked=false;
  }

  checkValidation = () => {
    if (formValid(this.state.formErrors)) {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    } else {
      let errArr = Object.entries(this.state.formErrors);
      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: error,
        loading_button: false,
      });
    }
  };

  submit_application = (event) => {
    event.preventDefault();
    this.setState({
      loading_button: true,
    });

    if (formValid(this.state.formErrors)) {
      console.log(this.state.formErrors)
      var myHeaders = new Headers();
      const cookies = new Cookies();
      myHeaders.append("authorization", "token " + cookies.get("token"));

      let keyArray = [
        ["hssc_board", this.state.hssc_board],
        ["hssc_board_year", this.state.hssc_board_year],
        ["hssc_board_seat_number", this.state.hssc_board_seat_number],
        ["neet_roll_no", this.state.neet_roll_no],
        ["neet_rank", this.state.neet_rank],
        ["neet_percentage", this.state.neet_percentage],
        ["hssc_total_marks", this.state.hssc_total_marks],
        ["hssc_total_maximum_marks", this.state.hssc_total_maximum_marks],
        ["hssc_english_marks", this.state.hssc_english_marks],
        ["hssc_english_maximum_marks", this.state.hssc_english_maximum_marks],
        ["hssc_physics_marks", this.state.hssc_physics_marks],
        ["hssc_physics_maximum_marks", this.state.hssc_physics_maximum_marks],
        ["hssc_chemistry_marks", this.state.hssc_chemistry_marks],
        ["hssc_chemistry_maximum_marks", this.state.hssc_chemistry_maximum_marks],
        ["hssc_maths_marks", this.state.hssc_maths_marks],
        ["hssc_maths_maximum_marks", this.state.hssc_maths_maximum_marks],
        ["hssc_biology_marks", this.state.hssc_biology_marks],
        ["hssc_biology_maximum_marks", this.state.hssc_biology_maximum_marks],
        ["hssc_others_marks", this.state.hssc_others_marks],
        ["hssc_others_maximum_marks", this.state.hssc_others_maximum_marks],
        ["applying_for_bvsc_ah", this.state.applying_for_bvsc_ah],
        ["is_applying_for_neet", this.state.is_applying_for_neet],
      ];

      console.log(keyArray);

      var formdata = new FormData();

      /* This is done because empty 12-marks fields gave error "... must be a number" while submitting*/
      for (let i = 0; i < keyArray.length; i++) {
        if (keyArray[i][1] !== "") {
          formdata.append(keyArray[i][0], keyArray[i][1]);
        }
      }

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/admission/application/step2`,
        requestOptions
      )
        .then((response) => {
          this.setState({
            showErrorBox: true,
          });
          if (response.status >= 200 && response.status < 300) {
            this.setState({
              step_two_finished: true,
              submitted_successfully: true,
            });
          } else if (response.status === 401) {
            localStorage.setItem("isLoggedInToGcet", "false");

            this.setState({
              isAuthenticated: false,
            });
            const authProps = {
              isAuth: this.state.isAuthenticated,
            };
            this.props.checkLoginStatus(authProps);
          } else {
            this.setState({
              submitted_successfully: false,
            });
          }
          return response.text();
        })
        .then((result) => {
          if (JSON.parse(result).error === true) {
            let errorArray = JSON.parse(result).message.split(".");
            let message = errorArray.map((el) => `${el} <br />`);

            this.setState({
              showErrorBox: true,
              backendError: message,
              loading_button: false,
            });
          } else {
            this.setState({
              backendError: JSON.parse(result).message,
              loading_button: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showErrorBox: true,
            backendError: `${error} <br />REFRESH THE PAGE<br />Check your internet connection`,
            loading_button: false,
          });
        });
    } else {
      let errArr = Object.entries(this.state.formErrors);

      let error = errArr
        .filter((el) => el[1].length > 0)
        .map((el) => `${el[0]}: ${el[1]} <br />`);
      this.setState({
        backendError: `There are errors in the form. <br /> <span>${error}</span>`,
        loading_button: false,
      });
    }
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    /* uncomment when you want to use formerrors*/
    // let formErrors = this.state.formErrors;

    this.setState({
      [name]: value,
    });

    if (name === "bvsc_ah_course") {
      if (this.bvscYesRef.current.checked) {
        this.setState({
          applying_for_bvsc_ah: true,
        });
      } else {
        this.setState({
          applying_for_bvsc_ah: false,
        });
      }
    }
    if (name === "neet_course") {
      if (this.neetYesRef.current.checked) {
        this.setState({
          is_applying_for_neet: true,
        });
      } else {
        this.setState({
          is_applying_for_neet: false,
        });
      }
    }
    setTimeout(() => {
      this.checkValidation();
    }, 100);
  };

  addBackendError = () => {
    return { __html: this.state.backendError };
  };

  componentDidMount() {
    let myHeaders = new Headers();
    const cookies = new Cookies();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "token " + cookies.get("token"));


    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/admission/application/`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          localStorage.setItem("isLoggedInToGcet", "false");

          this.setState({
            isAuthenticated: false,
          });

          const authProps = {
            isAuth: this.state.isAuthenticated,
          };
          this.props.checkLoginStatus(authProps);
        }
        return response.text();
      })
      .then((result) => {
        let app = JSON.parse(result).applicationData;

        this.setState({
          step_one_finished: app.step_one_finished,
          step_two_finished: app.step_two_finished,
          step_three_finished: app.step_three_finished,
          step_four_finished: app.step_four_finished,
          final_submission_done: app.is_application_submitted,
          is_gcet_roll_no_verified: app.is_gcet_roll_no_verified,
        });

        if (app.step_two_finished) {
          this.setState({
            step_two_submitted_before: true,
            
            neet_roll_no: app.neet_roll_no===-1 ? "": app.neet_roll_no,
            neet_rank: app.neet_rank,
            neet_percentage: app.neet_percentage,

            hssc_board: app.hssc_board === null ? "" : app.hssc_board,
            hssc_board_year:
              app.hssc_board_year === null ? "" : app.hssc_board_year,
            hssc_board_seat_number: app.hssc_board_seat_number === null ? "" : app.hssc_board_seat_number,

            hssc_total_marks:
              app.hssc_total_marks === null ? "" : app.hssc_total_marks,
            hssc_total_maximum_marks:
              app.hssc_total_maximum_marks === null
                ? ""
                : app.hssc_total_maximum_marks,
            hssc_english_marks:
              app.hssc_english_marks === null ? "" : app.hssc_english_marks,
            hssc_english_maximum_marks:
              app.hssc_english_maximum_marks === null
                ? ""
                : app.hssc_english_maximum_marks,
            hssc_physics_marks:
              app.hssc_physics_marks === null ? "" : app.hssc_physics_marks,
            hssc_physics_maximum_marks:
              app.hssc_physics_maximum_marks === null
                ? ""
                : app.hssc_physics_maximum_marks,
            hssc_maths_marks:
              app.hssc_maths_marks === null ? "" : app.hssc_maths_marks,
            hssc_maths_maximum_marks:
              app.hssc_maths_maximum_marks === null
                ? ""
                : app.hssc_maths_maximum_marks,
            hssc_chemistry_marks:
              app.hssc_chemistry_marks === null ? "" : app.hssc_chemistry_marks,
            hssc_chemistry_maximum_marks:
              app.hssc_chemistry_maximum_marks === null
                ? ""
                : app.hssc_chemistry_maximum_marks,
            hssc_biology_marks:
              app.hssc_biology_marks === null ? "" : app.hssc_biology_marks,
            hssc_biology_maximum_marks:
              app.hssc_biology_maximum_marks === null
                ? ""
                : app.hssc_biology_maximum_marks,
            hssc_others_marks:
              app.hssc_others_marks === null ? "" : app.hssc_others_marks,
            hssc_others_maximum_marks:
              app.hssc_others_maximum_marks === null
                ? ""
                : app.hssc_others_maximum_marks,

            applying_for_bvsc_ah: app.applying_for_bvsc_ah,
            is_applying_for_neet:app.is_applying_for_neet,
          });

          /*if (this.state.applying_for_bvsc_ah) {
            this.bvscYesRef.current.checked = true;
          } else {
            this.bvscNoRef.current.checked = true;
          }*/
          if (this.state.is_applying_for_neet) {
            this.neetYesRef.current.checked = true;
            
          } else {
            this.neetNoRef.current.checked = true;
            
            
          }
          console.log('YYY')
          console.log(this.neetYesRef.current.checked )
          console.log(this.neetNoRef.current.checked )
          console.log('YYY')
          
          localStorage.setItem("neet_step_one_finished", app.step_one_finished);
          localStorage.setItem("neet_step_two_finished", app.step_two_finished);
          localStorage.setItem(
            "neet_step_three_finished",
            app.step_three_finished
          );
          localStorage.setItem(
            "neet_step_four_finished",
            app.step_four_finished
          );
          localStorage.setItem(
            "neet_final_submission_done",
            app.is_application_submitted
          );
        }
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    console.log(this.state.is_applying_for_neet)
    if (
      this.state.isAuthenticated &&
      this.state.submitted_successfully &&
      !this.state.step_two_submitted_before
    ) {
      return <Redirect to="/form-n/category" />;
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true &&
      this.state.step_one_finished
    ) {
      return (
        <div className="application-form content-wrapper">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell large-12">
                <h1 className="application-form__title">
                  Admission Form <span>* Mandatory Fields</span>
                </h1>

                <NStepper
                  step={{
                    step1: this.state.step_one_finished,
                    step2: this.state.step_two_finished,
                    step3: this.state.step_three_finished,
                    step4: this.state.final_submission_done,
                    is_gcet_roll_no_verified:
                      this.state.is_gcet_roll_no_verified,
                  }}
                />
              </div>

              <div className="cell large-12" id="form-container">
                <form
                  onSubmit={this.submit_application}
                  className="application-form__form"
                  autoComplete="off"
                >
                  <hr className="horizontal-rule" />
                  
                    <p className="sub-heading" style={{ marginBottom: "15px" }}>
                      I  wish to apply for NEET based courses
                    </p>
                    <div
                      className="subject__container"
                      style={{ marginTop: "0" }}
                    >
                      <p className="error_message" id="subject-error">
                        {this.state.formErrors.exam_selection}
                      </p>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <SubjectCheckbox
                          label="YES"
                          name="neet_course"
                          id="neet_course_yes"
                          onChange={this.handleChange}
                          required
                          ref={this.neetYesRef}
                         
                        />   
                        <SubjectCheckbox
                          label="NO"
                          name="neet_course"
                          id="neet_course_no"
                          
                          onChange={this.handleChange}
                          required
                          ref={this.neetNoRef}
                        />

                      </div>
                    </div>



                  <div>
                    <p className="sub-heading">For NEET-based courses fill the NEET 2023 Roll number below</p>
                    <div className="three-div">
                      <Input
                        label="NEET 2023 Roll Number"
                        type="number"
                        value={this.state.neet_roll_no}
                        name="neet_roll_no"
                        id="neet_roll_no"
                        onChange={this.handleChange}
                        required={this.state.is_applying_for_neet}
                      />
                      
                      
                    </div>
                  </div>

                  <hr className="horizontal-rule" />
                  <div>
                    <p className="sub-heading">Details of H.S.S.C (Std XII)</p>

                    <div className="three-div">
                      <Input
                        label="Board"
                        type="text"
                        value={this.state.hssc_board}
                        name="hssc_board"
                        id="hssc_board"
                        placeholder="Example: Goa Board"
                        onChange={this.handleChange}
                        required
                      />

                      <Input
                        label="Month &amp; Year of passing"
                        type="text"
                        value={this.state.hssc_board_year}
                        name="hssc_board_year"
                        id="hssc_board_year"
                        onChange={this.handleChange}
                        placeholder="Example: March 2023"
                        required
                      />

                      <Input
                        label="Seat Number"
                        type="text"
                        value={this.state.hssc_board_seat_number}
                        name="hssc_board_seat_number"
                        id="hssc_board_seat_number"
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="two-div">
                      <div className="marks__container">
                        <div className="marks__row">
                          <div className="marks__data heading">HSSC Marks</div>
                          <div className="marks__data heading">
                            Marks Scored
                          </div>
                          <div className="marks__data heading">Max Marks</div>
                        </div>
                        <div className="marks__row">
                          <div className="marks__data">Total</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_total_marks}
                              name="hssc_total_marks"
                              id="hssc_total_marks"
                              placeholder="Ex. 480"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_total_maximum_marks}
                              name="hssc_total_maximum_marks"
                              id="hssc_total_maximum_marks"
                              placeholder="Ex. 600"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="marks__row">
                          <div className="marks__data">English</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_english_marks}
                              name="hssc_english_marks"
                              id="hssc_english_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_english_maximum_marks}
                              name="hssc_english_maximum_marks"
                              id="hssc_english_maximum_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="marks__row">
                          <div className="marks__data">Physics</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_physics_marks}
                              name="hssc_physics_marks"
                              id="hssc_physics_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_physics_maximum_marks}
                              name="hssc_physics_maximum_marks"
                              id="hssc_physics_maximum_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>
                        { <div className="marks__row">
                          <div className="marks__data">Maths</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_maths_marks}
                              name="hssc_maths_marks"
                              id="hssc_maths_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_maths_maximum_marks}
                              name="hssc_maths_maximum_marks"
                              id="hssc_maths_maximum_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div> }
                      
                        
                        <div className="marks__row">
                          <div className="marks__data">Chemistry</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_chemistry_marks}
                              name="hssc_chemistry_marks"
                              id="hssc_chemistry_marks"
                              //placeholder="Ex. 70"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_chemistry_maximum_marks}
                              name="hssc_chemistry_maximum_marks"
                              id="hssc_chemistry_maximum_marks"
                              //placeholder="Ex. 100"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="marks__row">
                          <div className="marks__data">Biology</div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_biology_marks}
                              name="hssc_biology_marks"
                              id="hssc_biology_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_biology_maximum_marks}
                              name="hssc_biology_maximum_marks"
                              id="hssc_biology_maximum_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>
                        { <div className="marks__row">
                          <div className="marks__data">
                            CS / BT / IP / VOC / Others
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_others_marks}
                              name="hssc_others_marks"
                              id="hssc_others_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                          <div className="marks__data">
                            <input
                              type="number"
                              value={this.state.hssc_others_maximum_marks}
                              name="hssc_others_maximum_marks"
                              id="hssc_others_maximum_marks"
                              onChange={this.handleChange}
                              autoComplete="new-off"
                              min="0"
                            />
                          </div>
                        </div>}
                      </div>
                    </div>

                    <hr className="horizontal-rule" />

                    <p className="sub-heading" style={{ marginBottom: "15px" }}>
                    Applicants interested in admission to <b>B.V.Sc. </b>
                    and <b>A.H.</b> courses need to <b> submit/upload 15
                    years</b> Residence Certificate and register online on MAFSU website and submit Acknowledgement copy to Directorate of Animal Husbandry and Veterinary Services(AHVS), Govt. of Goa, Patto, Panaji.
                    </p>
                    
                    { /*<div
                      className="subject__container"
                      style={{ marginTop: "0" }}
                    >
                      <p className="error_message" id="subject-error">
                        {this.state.formErrors.exam_selection}
                      </p>
                       <div style={{ display: "flex", flexDirection: "row" }}>
                        <SubjectCheckbox
                          label="YES"
                          name="bvsc_ah_course"
                          id="bvsc_ah_course_yes"
                          onChange={this.handleChange}
                          required
                          ref={this.bvscYesRef}
                        />

                        <div style={{width: '20px'}}></div>

                        <SubjectCheckbox
                          label="NO"
                          name="bvsc_ah_course"
                          id="bvsc_ah_course_no"
                          onChange={this.handleChange}
                          required
                          ref={this.bvscNoRef}
                        />
                      </div> 
                        </div> 
                    <p>
                      (If yes, submit <b>15 years</b> residence certificate.)
                        </p> */}
                  </div>

                  {/* Submit section */}

                  <hr className="horizontal-rule" />

                  <div className="pre-footer">
                    <p
                      id="backend-error"
                      className={`${
                        this.state.submitted_successfully
                          ? "success"
                          : "failure"
                      } ${this.state.showErrorBox ? "show" : ""}`}
                      dangerouslySetInnerHTML={this.addBackendError()}
                    ></p>
                    <div className="button-grid">
                      <div className="pre-footer__navigator">
                        <Link
                          to="/form-n/basic-details"
                          className="button button--grey prev"
                          style={{ marginRight: "30px" }}
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M19.017 22.777C19.623 22.443 20 21.791 20 21.074L20 2.92604C20 2.20904 19.623 1.55704 19.017 1.22304C18.439 0.905038 17.76 0.927038 17.202 1.28104L2.887 10.355C2.331 10.706 2 11.321 2 12C2 12.679 2.331 13.294 2.886 13.646L17.201 22.72C17.76 23.073 18.439 23.095 19.017 22.777Z" />
                          </svg>
                          PREVIOUS
                        </Link>
                        <Link
                          to="/form-n/category"
                          className="button button--grey next"
                          style={{
                            display: `${
                              this.state.step_two_finished
                                ? "inline-block"
                                : "none"
                            }`,
                          }}
                        >
                          NEXT 
                          <svg viewBox="0 0 24 24">
                            <path d="M4.983 1.22296C4.377 1.55696 4 2.20896 4 2.92596L4 21.074C4 21.791 4.377 22.443 4.983 22.777C5.561 23.095 6.24 23.073 6.798 22.719L21.113 13.645C21.669 13.294 22 12.679 22 12C22 11.321 21.669 10.706 21.114 10.354L6.799 1.27996C6.24 0.926963 5.561 0.904961 4.983 1.22296Z" />
                          </svg>
                        </Link>
                      </div>
                      <button
                        type="submit"
                        className={`button ${
                          this.state.loading_button ? "loading" : ""
                        }`}
                        id="submit-button"
                      >
                        SAVE AND PROCEED
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ScrollToTop />
        </div>
      );
    } else if (
      this.state.isAuthenticated &&
      this.state.final_submission_done !== true
    ) {
      return <Redirect to="/form-n/basic-details" />;
    } else if (this.state.isAuthenticated && this.state.final_submission_done) {
      return <Redirect to="/form-n/profile" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}
